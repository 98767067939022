export const timeFilters = [
  {
    value: 3,
    label: 'Last 3 months'
  },
  {
    value: 6,
    label: 'Last 6 months'
  },
  {
    value: 12,
    label: 'Last year'
  }
];

export const timeFiltersWithAllTime = [
  {
    value: 3,
    label: 'Last 3 months'
  },
  {
    value: 6,
    label: 'Last 6 months'
  },
  {
    value: 12,
    label: 'Last year'
  },
  {
    value: 0,
    label: 'All time'
  }
];
