import React from 'react';
import PropTypes from 'prop-types';

import {Stack, Typography} from '@mui/material';

import FinancialUnlockBox from './FinancialUnlockBox';
import PDFComponents from './PDFComponents';

const FinancialUnlocks = ({data, pdfData}) => {
  const title = data.title ?? '';
  const description = data.description ?? '';
  const rows = data.rows ?? [];

  const rowBlocks = rows.map((e, index) => {
    return <FinancialUnlockBox key={`${e.title}${index}`} data={e} />;
  });

  return (
    <Stack direction="column" spacing={1}>
      <Stack direction="row" columnGap={2} justifyContent={'space-between'} alignItems={'flex-start'}>
        <Stack direction="column" rowGap={1} sx={{width: '60%'}}>
          <Typography variant="h5" fontWeight={'fontWeightMedium'}>
            {title}
          </Typography>
          <Typography variant="subtitle1" fontWeight={'fontWeightRegular'} color={'text.subtitle'}>
            {description}
          </Typography>
        </Stack>
        <PDFComponents data={pdfData} />
      </Stack>
      <Stack direction="row" sx={{width: '100%', paddingTop: 1}} alignItems="center" spacing={2}>
        {rowBlocks}
      </Stack>
    </Stack>
  );
};

FinancialUnlocks.propTypes = {
  data: PropTypes.object,
  pdfData: PropTypes.object
};

export default FinancialUnlocks;
