import React, {useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import PropTypes from 'prop-types';

import {Box, Stack, TablePagination, Typography} from '@mui/material';

import ReviewRow from './ReviewRow';
import {getIsStakeAdmin} from '../../../../../app/store/reducers/session';
import {StyledMarginWidthDividerSm} from '../../../@extended/Divider';

const ReviewsTable = ({reviews}) => {
  const isStakeAdmin = useSelector(getIsStakeAdmin);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);

  let tableComponent;

  const handleChangePage = (_e, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filtered = useMemo(() => (isStakeAdmin ? [...reviews] : [...reviews].filter((r) => r.visible_to_client)), [reviews, isStakeAdmin]);

  useEffect(() => {
    setPage(0);
  }, [filtered]);

  const pagination = (
    <TablePagination
      component="div"
      count={[...filtered].length}
      page={page}
      onPageChange={handleChangePage}
      rowsPerPage={rowsPerPage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      rowsPerPageOptions={[5, 10, 50, 100]}
      sx={{overflow: 'hidden'}}
    />
  );

  if (filtered.length > 0) {
    tableComponent = (
      <Stack direction="column" rowGap={2} sx={{width: '100%', height: '100%'}} divider={<StyledMarginWidthDividerSm />}>
        {filtered.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((c) => (
          <ReviewRow key={`feedback-row-${c.id}`} row={c} />
        ))}
        {pagination}
      </Stack>
    );
  } else {
    tableComponent = (
      <Box display="flex" justifyContent="center" alignItems="center" sx={{height: 100}}>
        <Typography variant="subtitle1" fontWeight={'fontWeightRegular'} color={'text.subtitle'}>
          Currently deploying StakeSay to residents. Reviews will be posted here when your Stakers respond.
        </Typography>
      </Box>
    );
  }

  return tableComponent;
};

ReviewsTable.propTypes = {
  reviews: PropTypes.array.isRequired
};

export default ReviewsTable;
