import React, {useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';

import {Box, Paper, Stack, Typography} from '@mui/material';

import {getDashboardAccount, getDashboardCommunity} from '../../../../../app/store/reducers/dashboard';
import {useGetDelinquencyByPortfolioQuery} from '../../../../../features/coreApi/metricsSlice';
import {getIsStakeAdmin} from '../../../../../app/store/reducers/session';
import {getAccounts, getCampaigns, getCommunities} from '../../../../../app/store/reducers/entities';
import {getOpenItem} from '../../../../../app/store/reducers/menu';
import {StyledMarginWidthDividerSm} from '../../../@extended/Divider';
import {getRandomValue} from '../../../../constants/tableColumns';
import {DelinquencyDetailGroup} from './DelinquencyInfo';
import {MetricsHeader} from './MetricsInfo';

import LoadingBox, {ComplexLoadingBox} from '../../../@extended/LoadingBox';
import ErrorBox from '../../../@extended/ErrorBox';
import VisibilitySwitch from '../../../@extended/VisibilitySwitch';

const Delinquency = () => {
  const isStakeAdmin = useSelector(getIsStakeAdmin);
  const dashboardAccount = useSelector(getDashboardAccount);
  const dashboardCommunity = useSelector(getDashboardCommunity);
  const accounts = useSelector(getAccounts);
  const communities = useSelector(getCommunities);
  const campaigns = useSelector(getCampaigns);
  const openItem = useSelector(getOpenItem);

  const account = useMemo(() => accounts.find((a) => a.sfid === dashboardAccount.sfid), [accounts, dashboardAccount.sfid]);
  const community = useMemo(
    () => dashboardCommunity.sfid && dashboardCommunity.sfid != 'all' && communities.find((c) => c.sfid === dashboardCommunity.sfid),
    [communities, dashboardCommunity.sfid]
  );

  const [visible, setVisible] = useState();
  const [timeFilter, setTimeFilter] = useState(12);

  const isStakePlusAccount = useMemo(
    () => campaigns.some((c) => ['Leasing', 'Get Current', 'Stay Current', 'Renewals'].includes(c.subtype)),
    [campaigns]
  );

  const {data, isLoading, isFetching, isError, isSuccess} = useGetDelinquencyByPortfolioQuery(
    {sfid: dashboardAccount?.sfid, community: dashboardCommunity?.sfid, timeFilter, isStakeAdmin: isStakeAdmin},
    {skip: !dashboardAccount?.sfid || !dashboardCommunity?.sfid || !timeFilter || !isStakePlusAccount}
  );

  useEffect(() => {
    if (community && openItem == 'communities') {
      if (community?.hidden_loyalty_cloud_features__c) {
        setVisible(!community?.hidden_loyalty_cloud_features__c?.includes('delinquency_by_portfolio'));
      } else {
        setVisible(true);
      }
    } else if (account && openItem == 'portfolio') {
      if (account?.hidden_loyalty_cloud_features__c) {
        setVisible(!account?.hidden_loyalty_cloud_features__c.includes('delinquency_by_portfolio'));
      } else {
        setVisible(true);
      }
    }
  }, [community, account, openItem, timeFilter]);

  const mainComponent = useMemo(() => {
    if (isLoading || isFetching) {
      return (
        <Paper sx={{p: 2, borderRadius: 2, boxShadow: 'none'}}>
          <ComplexLoadingBox isLoading={isLoading || isFetching} />
        </Paper>
      );
    } else if (isError) {
      return (
        <Paper sx={{p: 2, borderRadius: 2, boxShadow: 'none'}}>
          <ErrorBox />
        </Paper>
      );
    } else if (isSuccess && data?.is_supported && (visible || isStakeAdmin)) {
      const dataType = openItem === 'portfolio' ? 'account' : 'community';
      const sfid = openItem === 'portfolio' ? dashboardAccount.sfid : dashboardCommunity.sfid;
      const randomId = getRandomValue();

      return (
        <Paper sx={{p: 2, borderRadius: 2, boxShadow: 'none'}}>
          <Stack direction="column" rowGap={2} divider={<StyledMarginWidthDividerSm />}>
            <MetricsHeader
              title={data?.title}
              description={data?.integtration_description}
              timeFilter={timeFilter}
              setTimeFilter={setTimeFilter}
            />
            <DelinquencyDetailGroup key={`${randomId}-group-data`} data={data.data} />
            {isStakeAdmin && (
              <VisibilitySwitch
                id={sfid}
                accountName={dashboardAccount?.name}
                setVisible={setVisible}
                isVisible={visible}
                modelType={dataType}
                featureType="delinquency_by_portfolio"
              />
            )}
          </Stack>
        </Paper>
      );
    } else if ((isSuccess && !data?.is_supported) || !isStakePlusAccount) {
      return (
        <Paper sx={{p: 2, borderRadius: 2, boxShadow: 'none'}}>
          <Box display="flex" justifyContent="center" alignItems="center" sx={{height: '140px'}}>
            <Typography variant="subtitle2" color={'text.subtitle'} fontWeight={'fontWeightRegular'}>
              Delinquency graph data only available for integrated properties
            </Typography>
          </Box>
        </Paper>
      );
    }
  }, [
    isLoading,
    isSuccess,
    isFetching,
    isError,
    timeFilter,
    visible,
    isStakeAdmin,
    data,
    dashboardAccount,
    dashboardCommunity,
    openItem,
    isStakePlusAccount
  ]);

  return mainComponent;
};

export default Delinquency;
