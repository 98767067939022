import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {Page, Text, View, Document, StyleSheet, Svg, Rect, Image, Font, Path, Line, Link} from '@react-pdf/renderer';

import Poppins from '../../../../assets/fonts/Poppins/Poppins-Regular.ttf';
import PoppinsLight from '../../../../assets/fonts/Poppins/Poppins-Light.ttf';

Font.registerHyphenationCallback((word) => [word]);
Font.register({
  family: 'Poppins',
  format: 'truetype',
  src: Poppins
});
Font.register({
  family: 'Poppins-light',
  format: 'truetype',
  src: PoppinsLight
});
// Create styles
const styles = StyleSheet.create({
  fontMedium: {
    fontFamily: 'Poppins'
  },
  fontLight: {
    fontFamily: 'Poppins-light'
  },
  textXSmall: {
    fontSize: 10
  },
  textSmall: {
    fontSize: 12
  },
  textMedium: {
    fontSize: 14
  },
  textLarge: {
    fontSize: 18
  },
  textXLarge: {
    fontSize: 24
  },
  paddingMain: {
    padding: 24
  },
  paddingTop: {
    paddingTop: 24
  },
  greySection: {
    backgroundColor: '#FAFAFA',
    height: 250,
    marginTop: 24,
    marginLeft: -24,
    marginRight: -24
  },
  unlockcSection: {
    display: 'flex',
    flexDirection: 'row'
  },
  unlocksBox: {
    width: '50%',
    border: '1 solid rgba(0, 203, 82, 1)',
    borderRadius: 12,
    height: 91,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  unlocksNoBox: {
    width: '50%',
    height: 91,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  unlocksBoxLeft: {
    marginRight: 12
  },
  unlocksBoxRight: {
    marginLeft: 12
  },
  centerText: {
    textAlign: 'center'
  },
  greenText: {
    color: 'rgba(0, 203, 82, 1)'
  },
  greyText: {
    color: 'rgba(120, 120, 120, 1)'
  },
  inisghtBox: {
    width: '33%',
    backgroundColor: 'rgba(252, 252, 252, 1)',
    border: '1 solid #E3E3E3',
    borderRadius: 12,
    height: 240,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  inisghtNoBox: {
    width: '33%',
    borderRadius: 12,
    height: 240,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  insoghtBoxLeft: {
    marginRight: 6
  },
  insoghtBoxRight: {
    marginLeft: 6
  },
  marginSmall: {
    margin: 12
  },
  metricsBox: {
    width: '50%',
    maxWidth: '50%',
    backgroundColor: 'rgba(252, 252, 252, 1)',
    border: '1 solid #E3E3E3',
    borderRadius: 12,
    height: 300,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  topMetricBox: {
    heigth: 120,
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: 12
  }
});

const EsgReportPdf = ({pdfData}) => {
  let pdfComponent;
  let stakerComponent;
  let metricsComponent1;
  let metricsComponent2;
  let metricsComponent3;
  let metricsComponent4;

  if (pdfData.name.length > 0) {
    if (pdfData.staker_insights.length > 0) {
      stakerComponent = pdfData.staker_insights.map((insight, idx) => {
        const styledBox =
          idx == 0
            ? [styles.insoghtBoxLeft, styles.inisghtBox, {paddingTop: 12}]
            : idx == 1
              ? [styles.insoghtBoxRight, styles.insoghtBoxLeft, styles.inisghtBox, {paddingTop: 12}]
              : idx == 2
                ? [styles.insoghtBoxRight, styles.inisghtBox, {paddingTop: 12}]
                : null;

        return (
          <View key={`block-for-${insight.title}`} style={styledBox}>
            <View
              style={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'flex-start',
                height: 160
              }}
            >
              <Svg height="35px" viewBox="0 0 512 512">
                <Path stroke={insight.fillColor} d={insight.icon} style={{fill: insight.fillColor}} />
              </Svg>
              <Text style={[styles.textMedium, styles.centerText, styles.fontMedium, {marginTop: 4}]}>{insight.title}</Text>
              <Text
                style={[
                  styles.textSmall,
                  styles.centerText,
                  styles.fontMedium,
                  styles.greyText,
                  styles.marginSmall,
                  {marginTop: 4, marginBottom: 12}
                ]}
              >
                {insight.description}
              </Text>
            </View>
            <Svg height="10">
              <Line stroke="rgba(227, 227, 227, 1)" strokeWidth={1} x1="0" y1="0" x2="500" y2="0" />
            </Svg>
            <View style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: 40, padding: '0 4'}}>
              <Text style={[styles.centerText, styles.fontMedium, {color: 'rgba(120, 120, 120, 1)', fontSize: 9}]}>Your portfolio is</Text>
              <View
                style={{
                  borderRadius: 12,
                  backgroundColor: 'rgba(160, 217, 159, 0.5)',
                  padding: '4 12'
                }}
              >
                <Text style={[styles.textSmall, styles.centerText, styles.fontMedium, styles.greenText, {fontSize: 9}]}>
                  {insight.value}
                </Text>
              </View>
            </View>
          </View>
        );
      });
    }

    const findMetricInsideComponent = (metric, idx) => {
      const styledBox = idx % 2 == 0 ? styles.unlocksBoxLeft : styles.unlocksBoxRight;
      return (
        <View key={`metric-block-for-${metric.title}`} style={[styles.metricsBox, styledBox, {paddingTop: 24}]}>
          <View style={styles.topMetricBox}>
            <Svg height="35px" viewBox="0 0 512 512">
              <Path stroke="rgba(20, 156, 224, 1)" d={metric.icon} style={{fill: 'rgba(20, 156, 224, 1)'}} />
            </Svg>
            <Text style={[styles.textMedium, styles.centerText, styles.fontMedium, {marginTop: 4}]}>{metric.value}</Text>
            <Text
              style={[
                styles.textSmall,
                styles.centerText,
                styles.fontMedium,
                styles.greyText,
                styles.marginSmall,
                {marginTop: 0, marginBottom: 12}
              ]}
            >
              {metric.title}
            </Text>
          </View>
          <Svg height="5">
            <Line stroke="rgba(227, 227, 227, 1)" strokeWidth={1} x1="0" y1="0" x2="500" y2="0" />
          </Svg>
          <View style={[styles.topMetricBox, {height: 175, justifyContent: 'flex-start'}]}>
            <View style={{width: '100%', display: 'flex', justifyContent: 'flex-start', padding: 0, alignItems: 'center'}}>
              <Text
                style={[
                  styles.textSmall,
                  styles.fontMedium,
                  styles.greyText,
                  {textAlign: 'left', marginBottom: 12, padding: 0, width: '100%'}
                ]}
              >
                {metric.description1}
              </Text>
            </View>
            <View style={{width: '100%', display: 'flex', justifyContent: 'flex-start', padding: 0, alignItems: 'center'}}>
              <Text
                style={[
                  styles.textSmall,
                  styles.fontMedium,
                  styles.greyText,
                  {paddingTop: 0, textAlign: 'left', padding: 0, width: '100%'}
                ]}
              >
                {metric.description2}
              </Text>
            </View>
          </View>
        </View>
      );
    };

    if (pdfData.metrics1.length > 0) {
      metricsComponent1 = (
        <View style={[styles.unlockcSection, styles.paddingTop]}>
          {pdfData.metrics1.map((metric, idx) => findMetricInsideComponent(metric, idx))}
          {pdfData.metrics1.length === 1 && (
            <View key={'metric-block-for-no-metric'} style={[styles.unlocksNoBox, styles.unlocksBoxRight, {paddingTop: 12}]}></View>
          )}
        </View>
      );
    }
    if (pdfData.metrics2.length > 0) {
      metricsComponent2 = (
        <View style={[styles.unlockcSection, {paddingTop: 24}]}>
          {pdfData.metrics2.map((metric, idx) => findMetricInsideComponent(metric, idx))}
          {pdfData.metrics2.length === 1 && (
            <View key={'metric-block-for-no-metric'} style={[styles.unlocksNoBox, styles.unlocksBoxRight, {paddingTop: 12}]}></View>
          )}
        </View>
      );
    }
    if (pdfData.metrics3.length > 0) {
      metricsComponent3 = (
        <View style={[styles.unlockcSection, {paddingTop: 24}]}>
          {pdfData.metrics3.map((metric, idx) => findMetricInsideComponent(metric, idx))}
          {pdfData.metrics3.length === 1 && (
            <View key={'metric-block-for-no-metric'} style={[styles.unlocksNoBox, styles.unlocksBoxRight, {paddingTop: 12}]}></View>
          )}
        </View>
      );
    }
    if (pdfData.metrics4.length > 0) {
      metricsComponent4 = (
        <View style={[styles.unlockcSection, {paddingTop: 24}]}>
          {pdfData.metrics4.map((metric, idx) => findMetricInsideComponent(metric, idx))}
          {pdfData.metrics4.length === 1 && (
            <View key={'metric-block-for-no-metric'} style={[styles.unlocksNoBox, styles.unlocksBoxRight, {paddingTop: 12}]}></View>
          )}
        </View>
      );
    }

    pdfComponent = (
      <Page wrap={false}>
        <View>
          <Svg style={{height: 175, width: '100%'}}>
            <Rect x="0" y="0" width="600" height="175" fill="rgba(84, 255, 153, 1)" />
          </Svg>
          <View style={{position: 'absolute', right: 0, top: 0, overflow: 'hidden', height: 175}}>
            <Image style={{height: '100%'}} src="https://stake-platform-s3.s3.amazonaws.com/blog-photos/ESG-Header-Image.png" />
          </View>
          <View style={{position: 'absolute', top: 16, right: 24, height: 100}}>
            <Image style={{height: '20%'}} src="https://stake-platform-s3.s3.amazonaws.com/blog-photos/stake-logotype-charcoal.png" />
          </View>
        </View>
        <View style={{position: 'absolute', top: 20, left: 24}}>
          <Text style={[styles.fontLight, styles.textXSmall]}>ESG Report - {pdfData.name}</Text>
          <View style={{top: 12}}>
            <Text style={[styles.fontMedium, styles.textLarge]}>Stake is a incentivize optimization platform</Text>
            <Text style={[styles.fontMedium, styles.textLarge]}>for the multifamily industry. We improve</Text>
            <Text style={[styles.fontMedium, styles.textLarge]}>property performance, drive efficiencies</Text>
            <Text style={[styles.fontMedium, styles.textLarge]}>and accelerate renter wealth.</Text>
          </View>
        </View>
        <View style={styles.paddingMain}>
          <View>
            <Text style={[styles.fontMedium, styles.textXLarge]}>About Stake</Text>
            <Text style={[styles.fontMedium, styles.textMedium]}>
              Our mission is to empower renters by building wealth. The average renter
            </Text>
            <Text style={[styles.fontMedium, styles.textMedium]}>
              has less than $400 in savings. Stake renters are able to significantly improve
            </Text>
            <Text style={[styles.fontMedium, styles.textMedium]}>their savings while their communities outperform the market.</Text>
          </View>
          <View style={styles.paddingTop}>
            <Text style={[styles.fontMedium, styles.textXLarge]}>ESG matters</Text>
            <Text style={[styles.fontMedium, styles.textMedium]}>
              Integrating ESG&#39;s social element adds value by strengthening resident
            </Text>
            <Text style={[styles.fontMedium, styles.textMedium]}>
              relationships, fostering community well-being, and driving demand for
            </Text>
            <Text style={[styles.fontMedium, styles.textMedium]}>
              responsible investments. It enhances satisfaction, tackles inequalities, and
            </Text>
            <Text style={[styles.fontMedium, styles.textMedium]}>empowers renters financially.</Text>
          </View>
          <View style={styles.paddingTop}>
            <Text style={[styles.fontMedium, styles.textMedium]}>
              With Stake, your portfolio outperforms while your residents thrive. The result:
            </Text>
            <Text style={[styles.fontMedium, styles.textMedium]}>
              Stakers are more likely to pay their rent on time, renew their lease, refer
            </Text>
            <Text style={[styles.fontMedium, styles.textMedium]}>friends, and much more.</Text>
          </View>
          <View style={styles.paddingTop}>
            <Text style={[styles.fontMedium, styles.textMedium]}>
              The stats below demonstrate how your partnership with Stake has enhanced your residents&#39; financial well-being.
            </Text>
          </View>
          <View style={styles.greySection}>
            <View style={styles.paddingMain}>
              <View style={styles.unlockcSection}>
                <View style={[styles.unlocksBox, styles.unlocksBoxLeft]}>
                  <Text style={[styles.greenText, styles.textLarge, styles.centerText, styles.fontMedium]}>{pdfData.totalUnlocks}</Text>
                  <Text style={[styles.textMedium, styles.centerText, styles.fontMedium]}>{pdfData.totalUnlocksTitle}</Text>
                </View>
                <View style={[styles.unlocksBox, styles.unlocksBoxRight]}>
                  <Text style={[styles.greenText, styles.textLarge, styles.centerText, styles.fontMedium]}>{pdfData.residentsUnlocks}</Text>
                  <Text style={[styles.textMedium, styles.centerText, styles.fontMedium]}>{pdfData.residentsUnlocksTitle}</Text>
                </View>
              </View>
              <View style={styles.paddingTop}>
                <Text style={[styles.fontMedium, styles.textXLarge]}>What are Financial Unlocks?</Text>
                <Text style={[styles.fontMedium, styles.textMedium]}>
                  We use ‘Unlocks’ to track your impact on resident financial empowerment.
                </Text>
                <Text style={[styles.fontMedium, styles.textMedium]}>
                  Unlocks include activating Credit Builder, Stake Visa Debit, Express Paycheck,
                </Text>
                <Text style={[styles.fontMedium, styles.textMedium]}>Recurring Deposits, and more.</Text>
              </View>
            </View>
          </View>
          {pdfData.staker_insights.length > 0 && (
            <View style={styles.paddingTop}>
              <Text style={[styles.fontMedium, styles.textXLarge]}>Staker Insights</Text>
              <Text style={[styles.fontMedium, styles.textMedium, styles.greyText]}>
                Detailed metrics of how Stake is financially empowering your residents
              </Text>
              <View style={[styles.unlockcSection, styles.paddingTop]}>
                {stakerComponent}
                {pdfData.staker_insights.length == 1 && (
                  <Fragment>
                    <View
                      key={'block-for-no-insight-1'}
                      style={[styles.insoghtBoxRight, styles.insoghtBoxLeft, styles.inisghtNoBox]}
                    ></View>
                    <View key={'block-for-no-insight-2'} style={[styles.insoghtBoxLeft, styles.inisghtNoBox]}></View>
                  </Fragment>
                )}
              </View>
            </View>
          )}
          {pdfData.metrics1.length > 0 && (
            <View style={styles.paddingTop}>
              <Text style={[styles.fontMedium, styles.textXLarge]}>ESG metrics</Text>
              <Text style={[styles.fontMedium, styles.textMedium, styles.greyText]}>{pdfData.esg_metrics_description}</Text>
              {metricsComponent1}
              {metricsComponent2}
              {metricsComponent3}
              {metricsComponent4}
            </View>
          )}
          <View style={styles.paddingTop}>
            <Text style={[styles.fontMedium, styles.textXLarge]}>More resources</Text>
            <Link
              src="https://www.multifamilydive.com/news/the-7-principles-critical-to-multifamily-esg-investing-social-impact/648151/"
              style={[styles.fontMedium, styles.textMedium, {color: '#000000'}]}
            >
              7 principles critical to multifamily ESG investing
            </Link>
            <Link
              src="https://www.multifamilyinsiders.com/multifamily-blogs/esg-in-action-for-2023-what-it-means-for-multifamily-owners-operators-and-investors"
              style={[styles.fontMedium, styles.textMedium, {color: '#000000'}]}
            >
              ESG in Action for 2023
            </Link>
          </View>
        </View>
        <View style={{overflow: 'hidden'}}>
          <Svg style={{height: 175, width: '100%'}}>
            <Rect x="0" y="0" width="600" height="200" fill="rgba(84, 255, 153, 1)" />
          </Svg>
          <View style={{position: 'absolute', right: 0, bottom: 0, height: 200}}>
            <Image style={{height: '100%'}} src="https://stake-platform-s3.s3.amazonaws.com/blog-photos/ESG-Footer-Image.png" />
          </View>
          <View style={{position: 'absolute', left: 24, top: 24, height: 200}}>
            <Text style={[styles.fontMedium, styles.textMedium, {marginBottom: 4}]}>Learn More</Text>
            <Link
              src="https://www.multifamilyinsiders.com/multifamily-blogs/esg-in-action-for-2023-what-it-means-for-multifamily-owners-operators-and-investors"
              style={[
                styles.textXLarge,
                styles.fontMedium,
                {color: '#000000', textDecoration: 'none', marginBottom: 0, paddingBottom: 0, lineHeight: 0}
              ]}
            >
              www.stake.rent
            </Link>
            <Text style={[styles.textXLarge, styles.fontMedium, {marginTop: 0, lineHeight: 0}]}>hello@stake.rent</Text>
          </View>
          <View style={{position: 'absolute', left: 24, top: 140, height: 200}}>
            <Text style={[{fontSize: '8px'}, styles.fontLight]}>Generated by Stake Network, Inc in partnership with </Text>
            <Text style={[{fontSize: '8px'}, styles.fontLight]}>{pdfData.name}</Text>
          </View>
          <View style={{position: 'absolute', right: 12, top: 150, height: 200}}>
            <Text style={[{fontSize: '8px'}, styles.fontLight]}>{pdfData.formattedDate}</Text>
          </View>
        </View>
      </Page>
    );
  }

  return <Document title={`ESG_REPORT_${pdfData.name}.pdf`}>{pdfComponent}</Document>;
};

EsgReportPdf.propTypes = {
  pdfData: PropTypes.object
};

export default EsgReportPdf;
