import React, {useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';

import {Paper, Stack} from '@mui/material';

import {StyledMarginWidthDividerSm} from '../../../@extended/Divider';
import {DaysOnMarketCard, DaysOnMarketHeader, DaysOnMarketSummary, DaysOnMarketTable} from './DaysOnMarketInfo';
import {useSelector} from 'react-redux';
import {getIsStakeAdmin} from '../../../../../app/store/reducers/session';
import {getDashboardAccount, getDashboardCommunity} from '../../../../../app/store/reducers/dashboard';
import {getCommunities} from '../../../../../app/store/reducers/entities';
import {useGetDaysOnMarketByCommunitySfidQuery} from '../../../../../features/coreApi/metricsSlice';
import {getOpenItem} from '../../../../../app/store/reducers/menu';

import VisibilitySwitch from '../../../@extended/VisibilitySwitch';
import ErrorBox from '../../../@extended/ErrorBox';
import {ComplexLoadingBox} from '../../../@extended/LoadingBox';
import {MetricsGraph} from '../../../@extended/MetricsGraph';
import {useParams} from 'react-router-dom';

const DaysOnMarket = () => {
  const params = useParams();
  const openItem = useSelector(getOpenItem);
  const communities = useSelector(getCommunities);
  const isStakeAdmin = useSelector(getIsStakeAdmin);

  const dashboardAccount = useSelector(getDashboardAccount);
  const dashboardCommunity = useSelector(getDashboardCommunity);

  const community = communities.find((c) => c.sfid === dashboardCommunity.sfid);

  const {data, isLoading, isSuccess, isFetching, isError} = useGetDaysOnMarketByCommunitySfidQuery(params?.community_sfid, {
    skip: !params?.community_sfid
  });
  const [isVisible, setVisible] = useState(false);

  useEffect(() => {
    if (openItem === 'communities' && community) {
      setVisible(!community?.hidden_loyalty_cloud_features__c?.includes('days_on_market'));
    }
  }, [community, openItem]);

  const daysOnMarketData = useMemo(() => {
    const defaultResponse = {
      is_visible: false,
      is_loading: false
    };

    if (!isVisible && !isStakeAdmin) {
      return defaultResponse;
    }

    if (isFetching || isLoading) {
      return {
        is_loading: true
      };
    }

    if (isSuccess && (isVisible || isStakeAdmin)) {
      if (data?.is_supported) {
        return {...data, is_visible: true, is_loading: false};
      } else {
        return defaultResponse;
      }
    }

    return defaultResponse;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible, isStakeAdmin, isSuccess, isLoading, isFetching, JSON.stringify(data), dashboardCommunity, openItem]);

  const mainComponent = useMemo(() => {
    if (daysOnMarketData?.is_loading) {
      return (
        <Paper sx={{p: 2, width: '100%', height: '100%', boxShadow: 'none', borderRadius: 2}}>
          <ComplexLoadingBox isLoading={daysOnMarketData.is_loading} />
        </Paper>
      );
    } else if (daysOnMarketData?.is_visible) {
      const {header, comps, summary, table_data, graph_data} = data;

      return (
        <Paper sx={{p: 2, width: '100%', height: '100%', boxShadow: 'none', borderRadius: 2}}>
          <Stack direction="column" rowGap={2} divider={<StyledMarginWidthDividerSm />}>
            <DaysOnMarketHeader data={header} />
            <DaysOnMarketCard data={comps} />
            <DaysOnMarketSummary data={summary} />
            <MetricsGraph graphData={graph_data} name="Days on Market" />
            <DaysOnMarketTable data={table_data} />
            {isStakeAdmin && (
              <VisibilitySwitch
                id={dashboardCommunity?.sfid}
                accountName={dashboardAccount?.name}
                setVisible={setVisible}
                isVisible={isVisible}
                modelType="campaign"
                featureType="days_on_market"
              />
            )}
          </Stack>
        </Paper>
      );
    } else if (isError) {
      return (
        <Paper sx={{p: 2, width: '100%', height: '100%', boxShadow: 'none', borderRadius: 2}}>
          <ErrorBox />
        </Paper>
      );
    } else {
      return <></>;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [daysOnMarketData, isVisible, isError, dashboardAccount, dashboardCommunity, JSON.stringify(data), isStakeAdmin, params]);

  return mainComponent;
};

DaysOnMarket.propTypes = {
  data: PropTypes.object
};

export default DaysOnMarket;
