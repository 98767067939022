import React, {useEffect, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';

import {Box, Button, Chip, IconButton, Menu, MenuItem, Stack, TextField, Tooltip, Typography, useTheme} from '@mui/material';

import {validZipCodes} from '../../../../constants/validZipCodes';
import {MoneyFormatCustom, PositiveNumberFormat} from '../../../@extended/FormatDisplay';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {fontAwesomeIcons} from '../../../../utils/icons';

export const ConcessionsHeader = ({data}) => {
  const {title, description} = data;

  return (
    <Stack direction="column">
      <Typography variant="h5" fontWeight="fontWeightMedium">
        {title}
      </Typography>
      <Typography variant="subtitle2" color="text.subtitle" fontWeight={'fontWeightRegular'}>
        {description}
      </Typography>
      <Typography variant="subtitle2" color="text.subtitle" fontWeight={'fontWeightRegular'}>
        This data is sourced from publicly available online listings.
      </Typography>
    </Stack>
  );
};

ConcessionsHeader.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string
  })
};

const EditableChip = ({label, nonEditablePart, format, setEditedValues, editedValues}) => {
  const theme = useTheme();
  const [editablePart, setEditablePart] = useState(label);
  const [validationError, setValidationError] = useState();
  const inputRef = useRef(null);

  const zipCodes = {};
  for (const zip of validZipCodes) {
    zipCodes[zip] = zip;
  }

  const handleChange = (event) => {
    setValidationError(null);
    setEditablePart(event.target.value);
  };

  const handleBlur = (e) => {
    const currentValue = e.target.value;
    if (format == 'zip_code') {
      const valid = currentValue in zipCodes;

      if (!valid) {
        setValidationError('Invalid zip code');
      } else {
        setEditedValues({...editedValues, [format]: currentValue});
      }
    } else {
      const parsedValue = parseInt(currentValue.replace('$', '').replace(',', ''));
      setEditedValues({...editedValues, [format]: parsedValue});
    }
  };

  return (
    <Stack direction="column" rowGap={0.5} alignItems="center">
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          backgroundColor: theme.palette.grey[200],
          borderRadius: '16px',
          padding: '0px 12px',
          fontFamily: 'Poppins, sans-serif',
          fontSize: '0.8125rem',
          width: 'fit-content',
          maxWidth: '100%',
          height: '24px'
        }}
      >
        <Box component="span" sx={{mr: 1}}>
          {nonEditablePart}
        </Box>
        <TextField
          value={editablePart}
          onChange={handleChange}
          onBlur={handleBlur}
          inputRef={inputRef}
          InputProps={{
            style: {fontSize: '0.8125rem', width: format == 'market_rent' ? '66px' : '36px'},
            inputComponent: format == 'market_rent' ? MoneyFormatCustom : PositiveNumberFormat,
            classes: {
              input: {padding: '0 !important'}
            }
          }}
          sx={{
            '& .MuiOutlinedInput-notchedOutline': {
              borderWidth: '0 !important',
              p: 0
            },
            '& .MuiInputBase-input': {
              borderRadius: 0,
              minWidth: '40px',
              fontSize: '0.8125rem',
              fontWeight: 400,
              fontFamily: 'Poppins, sans-serif',
              p: 0
            },
            '&.MuiInputBase-inputSizeSmall': {
              padding: '0 !important'
            }
          }}
        />
        <IconButton size="small" onClick={() => inputRef.current.focus()} disableRipple sx={{pr: 1, ml: 2}}>
          <FontAwesomeIcon icon={fontAwesomeIcons['faPenToSquare']} fontSize="small" style={{marginTop: -2}} />
        </IconButton>
      </Box>
      {typeof validationError == 'string' && (
        <Typography sx={{color: theme.palette.error.main, fontSize: '0.725rem'}}>{validationError}</Typography>
      )}
    </Stack>
  );
};

EditableChip.propTypes = {
  label: PropTypes.string,
  nonEditablePart: PropTypes.string,
  format: PropTypes.string,
  setEditedValues: PropTypes.func,
  editedValues: PropTypes.object
};

const EditableChipDropdown = ({label, setEditedValues, format, editedValues, options}) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleSelect = (e) => {
    setAssetClass(e.target.textContent);
    setEditedValues({...editedValues, [format]: e.target.textContent});
    handleClose();
  };

  const [assetClass, setAssetClass] = useState(label);

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={{
          backgroundColor: theme.palette.grey[200],
          borderRadius: '16px',
          padding: '0px 12px',
          fontFamily: 'Poppins, sans-serif',
          fontSize: '0.8125rem',
          width: 'fit-content',
          maxWidth: '100%',
          height: '24px',
          fontWeight: 400
        }}
      >
        {assetClass}
        <IconButton size="small" disableRipple sx={{pr: 1, ml: 2}}>
          <FontAwesomeIcon icon={fontAwesomeIcons['faPenToSquare']} fontSize="small" style={{marginTop: -2}} />
        </IconButton>
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
      >
        {options.map((option) => (
          <MenuItem key={option} onClick={handleSelect}>
            {option}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

EditableChipDropdown.propTypes = {
  label: PropTypes.any,
  setEditedValues: PropTypes.func,
  format: PropTypes.string,
  editedValues: PropTypes.object,
  options: PropTypes.array
};

export const ConcessionsInfo = ({data, editedValues, setEditedValues, handleApply}) => {
  const {filters, title, market_rent, asset_class, asset_classes_dropdown, zip_code} = data;
  const [isButtonActivated, setIsButtonActivated] = useState(false);

  useEffect(() => {
    if (Object.values(editedValues).length > 0) {
      setIsButtonActivated(true);
    }
  }, [editedValues]);

  const editChips = useMemo(() => {
    const marketChip = (
      <EditableChip
        label={market_rent}
        nonEditablePart={'Market rent:'}
        format="market_rent"
        setEditedValues={setEditedValues}
        editedValues={editedValues}
      />
    );
    const fullZP = zip_code.split(':');
    const zipChip = (
      <EditableChip
        label={fullZP[1]}
        nonEditablePart={fullZP[0]}
        format="zip_code"
        setEditedValues={setEditedValues}
        editedValues={editedValues}
      />
    );
    const assetClassChip = (
      <EditableChipDropdown
        label={asset_class}
        options={asset_classes_dropdown}
        setEditedValues={setEditedValues}
        format="asset_class"
        editedValues={editedValues}
      />
    );
    return (
      <>
        {marketChip}
        {zipChip}
        {assetClassChip}
      </>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, editedValues, asset_class, asset_classes_dropdown, market_rent, zip_code]);

  const chips = useMemo(() => {
    return filters.map((criteria) => {
      return (
        <Chip
          key={criteria.toLowerCase().split(' ').join('_')}
          label={criteria}
          size="small"
          sx={{
            '& .MuiChip-label': {padding: '14px 12px'}
          }}
        />
      );
    });
  }, [filters]);

  return (
    <Stack direction="column" rowGap={1} sx={{mb: '-8px'}}>
      <Typography variant="subtitle1" fontWeight={'fontWeightRegular'}>
        {title}
      </Typography>
      <Stack direction="row" columnGap={0.5} flexWrap="wrap">
        {chips}
        {editChips}
        <Button
          disabled={!isButtonActivated}
          disableElevation
          variant="contained"
          color="secondary"
          size="small"
          sx={{padding: '0px 24px', fontSize: '12px', m: '0 8px 16px 8px'}}
          onClick={handleApply}
        >
          Apply
        </Button>
      </Stack>
    </Stack>
  );
};

ConcessionsInfo.propTypes = {
  data: PropTypes.shape({
    filters: PropTypes.array,
    title: PropTypes.string,
    market_rent: PropTypes.number,
    asset_class: PropTypes.string,
    asset_classes_dropdown: PropTypes.array,
    zip_code: PropTypes.string
  }),
  editedValues: PropTypes.object,
  setEditedValues: PropTypes.func,
  handleApply: PropTypes.func
};

export const Savings = ({data, mainInfo}) => {
  const {title, description} = mainInfo;

  const theme = useTheme();
  const blocks = data.map((block) => {
    const {name, value, description, tooltip, color} = block;
    return (
      <Box
        key={name.split(' ').join('_')}
        sx={{
          width: '100%',
          height: '100%',
          border: `1px solid ${theme.palette.grey[300]}`,
          borderRadius: '10px',
          p: 2
        }}
      >
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Stack direction="column">
            <Stack direction="row" columnGap={0.5} alignItems="center">
              <Typography variant="h6" fontWeight={'fontWeightRegular'}>
                {name}
              </Typography>
              <Tooltip
                title={tooltip}
                componentsProps={{
                  tooltip: {
                    sx: {
                      fontSize: '12px',
                      fontWeight: 400
                    }
                  }
                }}
                arrow
              >
                <IconButton size="small" disableRipple>
                  <FontAwesomeIcon size="xs" icon={fontAwesomeIcons['faCircleQuestionLight']} />
                </IconButton>
              </Tooltip>
            </Stack>
            <Typography variant="body2" color={'text.subtitle'}>
              {description}
            </Typography>
          </Stack>
          <Chip
            label={value}
            size="small"
            sx={{
              backgroundColor: color,
              color: theme.palette.grey[900],
              '& .MuiChip-label': {
                padding: '14px 12px',
                fontSize: '12px',
                color: color == '#FEE3A0' ? theme.palette.grey[900] : theme.palette.grey[50]
              }
            }}
          />
        </Stack>
      </Box>
    );
  });
  return (
    <Stack direction="column" rowGap={2}>
      <Stack direction={'column'}>
        <Typography variant="h6" fontWeight={'fontWeightRegular'}>
          {title}
        </Typography>
        <Typography variant="subtitle2" color="text.subtitle" fontWeight={'fontWeightRegular'}>
          {description}
        </Typography>
      </Stack>
      <Stack direction="row" columnGap={1}>
        {blocks}
      </Stack>
    </Stack>
  );
};

Savings.propTypes = {
  data: PropTypes.array,
  mainInfo: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string
  })
};
