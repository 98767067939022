import React from 'react';
import PropTypes from 'prop-types';

import {Paper, Stack, Typography, useTheme} from '@mui/material';
import {StyledMarginWidthDividerSm, StyledMarginWidthDividerXs} from './Divider';
import {Cell, Pie, PieChart, ResponsiveContainer} from 'recharts';

export const GraphTooltip = ({active, payload, chartName}) => {
  const isDefault = ['Days on Market', 'Renewals'].includes(chartName);
  const theme = useTheme();
  let payloadData = [];
  let dateString;

  if (active && payload && payload.length) {
    payload.forEach((pld) => {
      const {color, value, name, payload, dataKey} = pld;
      dateString = payload.fullDate;
      let valueStr = isDefault ? value.toFixed(2) : value.toFixed(2) + '%';
      valueStr = ['Average Cash Back offer'].includes(dataKey) ? valueStr + '%' : valueStr;

      const component = (
        <Stack key={`tooltip-for-${name}`} direction="row" alignItems="center" columnGap={0.5}>
          <div
            style={{
              width: '10px',
              height: '10px',
              backgroundColor: color
            }}
          />
          <Typography sx={{fontSize: '0.75rem'}}>{name}:</Typography>
          <Typography sx={{fontSize: '0.75rem', fontWeight: 600}}>{valueStr}</Typography>
        </Stack>
      );
      payloadData.push(component);
    });
  }

  return (
    <Paper
      sx={{
        padding: 1,
        pt: 1,
        minWidth: '300px',
        borderRadius: '6px',
        border: `0.5px solid ${theme.palette.grey[300]}`
      }}
    >
      <Stack direction="column" rowGap={1} sx={{width: '100%'}} divider={<StyledMarginWidthDividerXs />}>
        <Typography sx={{width: '100%', fontSize: '0.75rem', textAlign: 'center'}}>{dateString}</Typography>
        <Stack direction="column">{payloadData.map((ele) => ele)}</Stack>
      </Stack>
    </Paper>
  );
};

GraphTooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.array,
  chartName: PropTypes.string
};

export const CustomTooltipComponent = ({active, payload}) => {
  const theme = useTheme();
  let payloadData = [];
  let dateString;
  let payloadValues = {};

  if (active && payload && payload.length) {
    payload.forEach((pld) => {
      const {color, value, name, payload, dataKey} = pld;
      const tooltipKey = `tooltip_name:${dataKey}`;
      let amountFormat = `amount_format:${dataKey}`;
      amountFormat = payload[amountFormat] || 'default';
      const tooltipName = payload[tooltipKey] || name;

      dateString = payload.fullDate;

      let valueStr;
      if (['Average Cash Back offer'].includes(dataKey)) {
        valueStr = moneyFormatter.format(value);
      } else if (dataKey.toLowerCase().includes('percentage') || amountFormat == 'percentage') {
        valueStr = `${value.toFixed(2)}%`;
      } else {
        valueStr = value.toFixed(2);
      }

      const component = (
        <Stack key={`tooltip-for-${name}`} direction="row" columnGap={0.5} alignItems="center">
          <div
            style={{
              width: '10px',
              height: '10px',
              backgroundColor: color == '#FFFFFF' ? '#575757' : color
            }}
          />
          <Typography sx={{fontSize: '0.75rem'}}>{tooltipName}:</Typography>
          <Typography sx={{fontSize: '0.75rem', fontWeight: 600}}>{valueStr}</Typography>
        </Stack>
      );
      payloadData.push(component);
      if (dataKey != 'Average Cash Back offer') {
        payloadValues[dataKey] = {value: value, color: color};
      }
    });
  }

  return (
    <Paper
      sx={{
        padding: 1,
        pt: 0.5,
        minWidth: '300px',
        borderRadius: '6px',
        border: `0.5px solid ${theme.palette.grey[300]}`,
        backgroundColor: theme.palette.grey[50]
      }}
    >
      <Stack direction="column" rowGap={0.5} sx={{width: '100%'}} divider={<StyledMarginWidthDividerSm />}>
        <Typography sx={{width: '100%', fontSize: '0.75rem', textAlign: 'center'}}>{dateString}</Typography>
        <Stack direction="column" rowGap={1}>
          <Stack direction="column" rowGap={0.5}>
            {payloadData.map((ele) => ele)}
          </Stack>
        </Stack>
      </Stack>
    </Paper>
  );
};

CustomTooltipComponent.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.array
};

export const CustomConversionsTooltipComponent = ({active, payload}) => {
  const theme = useTheme();

  const whiteColors = {
    'Renewal percentage': '#9D4BD0',
    'Expired leases': '#575757'
  };

  let payloadData = [];
  let dateString;
  let payloadValues = {};
  let pieData = {};

  if (active && payload && payload.length) {
    payload.forEach((pld) => {
      const {color, value, name, payload, dataKey} = pld;
      dateString = payload.fullDate;
      let valueStr;
      if (dataKey.includes('percentage')) {
        valueStr = `${value.toFixed(2)}%`;
      } else {
        valueStr = value.toFixed(2);
      }

      const component = (
        <Stack key={`tooltip-for-${name}`} direction="row" columnGap={0.5} alignItems="center">
          <div
            style={{
              width: '10px',
              height: '10px',
              backgroundColor: color == '#FFFFFF' ? whiteColors[name] : color
            }}
          />
          <Typography sx={{fontSize: '0.75rem'}}>{name}:</Typography>
          <Typography sx={{fontSize: '0.75rem', fontWeight: 600}}>{valueStr}</Typography>
        </Stack>
      );

      if (name !== 'Renewal percentage') {
        pieData[name] = {value: value, color: color, name: name};
      }

      payloadData.push(component);
      payloadValues[dataKey] = {value: value, color: color};
    });

    let total = 0;

    for (const key in pieData) {
      if (key !== 'Expired leases') {
        total += pieData[key].value;
      }
    }

    const leftExpired = pieData['Expired leases']?.value - total;
    pieData['Expired leases'] = {value: leftExpired, color: '#575757', name: 'Expired leases'};
  }

  return (
    <Paper
      sx={{
        padding: 1,
        pt: 0.5,
        minWidth: '300px',
        borderRadius: '6px',
        border: `0.5px solid ${theme.palette.grey[250]}`,
        backgroundColor: theme.palette.grey[50]
      }}
    >
      <Stack direction="column" rowGap={0.5} sx={{width: '100%'}} divider={<StyledMarginWidthDividerSm />}>
        <Typography sx={{width: '100%', fontSize: '0.75rem', textAlign: 'center'}}>{dateString}</Typography>
        <ResponsiveContainer width="100%" height={80}>
          <PieChart>
            <Pie data={Object.values(pieData)} dataKey="value" nameKey="name" cx="50%" cy="50%" outerRadius={40} fill="#8884d8">
              {Object.values(pieData).map((entry, index) => (
                <Cell key={`cell-${index}`} fill={entry.color} stroke="0" />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
        <Stack direction="column" rowGap={1}>
          <Stack direction="column" rowGap={0.5}>
            {payloadData.map((ele) => ele)}
          </Stack>
        </Stack>
      </Stack>
    </Paper>
  );
};

CustomConversionsTooltipComponent.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.array
};
