import React from 'react';

import {Stack} from '@mui/material';

import DaysOnMarket from './Metrics/DaysOnMarket';
import ConcessionComps from './Metrics/ConcessionComps';
import Delinquency from './Metrics/Delinquency';

const CommunityPerformance = () => {
  return (
    <Stack direction="column" rowGap={2} sx={{width: '100%'}}>
      <ConcessionComps />
      <DaysOnMarket />
      <Delinquency />
    </Stack>
  );
};

export default CommunityPerformance;
