const isEmpty = (value) => {
  if (Array.isArray(value)) {
    return value.length === 0;
  } else if (typeof value === 'object' && value !== null) {
    return Object.keys(value).length === 0;
  } else if (!value) {
    return false;
  }
  return true;
};

export const isAllEmpty = (data) => {
  const {homeCreditBuilder, homeFinancialUnlocks, homeInsights, homeSVD, homeStakePay} = data;
  return isEmpty(homeCreditBuilder) && isEmpty(homeFinancialUnlocks) && isEmpty(homeInsights) && isEmpty(homeSVD) && isEmpty(homeStakePay);
};

export const defaultEngState = {
  homeCreditBuilder: {},
  homeFinancialUnlocks: {},
  homeInsights: [],
  homeSVD: {},
  homeStakePay: {}
};
