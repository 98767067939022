import React, {useRef, useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import Chart from 'react-apexcharts';
import {
  Box,
  Button,
  ButtonGroup,
  Collapse,
  Menu,
  MenuItem,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  tooltipClasses,
  Typography,
  useTheme
} from '@mui/material';
import {findCategoriesData, findGraphData, findSpendingChartData} from '../../../../constants/spendingConstants';
import {findLastTwelveMonthsWithYY, findLastTwelveMonthsWithYYYY} from '../../../../constants/dateConstants';
import {generateColor} from '../../../../constants/colorConstants';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {fontAwesomeIcons} from '../../../../utils/icons';
import {MoneyFormatCustomDisplay} from '../../../@extended/FormatDisplay';
import ReactApexChart from 'react-apexcharts';

const CustomWidthTooltip = styled(({...props}) => <Tooltip {...props} />)({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 200,
    textAlign: 'center'
  }
});

const SubCategoriesButton = (props) => {
  const {legend, setSubLegend, name, buttonRef, spendingDataByTime} = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const [dropDownData, setDropDownData] = useState([]);
  const [buttonInnerText, setButtonInnerText] = useState('');
  const [warningText] = useState('Please filter by one category before you filter by subcategory.');
  const open = Boolean(anchorEl);
  const [isDisabled, setDisabled] = useState(true);

  useEffect(() => {
    setDisabled(legend === 'All Categories' || spendingDataByTime.length === 0);
  }, [buttonRef, spendingDataByTime, legend]);

  useEffect(() => {
    if (spendingDataByTime.length === 0) {
      setButtonInnerText('No Subcategories');
    } else {
      setButtonInnerText(name);
    }
  }, [name, spendingDataByTime]);

  useEffect(() => {
    if (legend) {
      let filtered = spendingDataByTime.filter((ele) => ele.category_1 === legend);
      let filteredByAmount = {};
      filtered.map((ele) => {
        const {category_2, amount} = ele;
        if (!filteredByAmount[category_2]) {
          filteredByAmount[category_2] = {
            name: category_2,
            amount: Math.round(amount)
          };
        } else {
          filteredByAmount[category_2].amount += Math.round(amount);
        }
      });
      const sorted = Object.values(filteredByAmount).sort((a, b) => b.amount - a.amount);
      setDropDownData(sorted.slice(0, 10));
    }
  }, [legend]);

  const handleClick = (event) => {
    if (!anchorEl) {
      setAnchorEl(event.currentTarget);
    } else {
      setAnchorEl(null);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuClick = (value) => {
    setSubLegend(value);
    setAnchorEl(null);
  };

  return (
    <div style={{padding: '0 5px'}}>
      <CustomWidthTooltip sx={{width: 'fit-content'}} title={buttonRef?.current?.innerText === 'All Categories' ? warningText : ''} arrow>
        <Button
          variant="outlined"
          disabled={isDisabled}
          id="community-spending-chart-subcategories-tooltip"
          size="small"
          aria-controls="basic-menu"
          aria-haspopup="true"
          aria-expanded={open ? open : undefined}
          onClick={handleClick}
          endIcon={<FontAwesomeIcon icon={fontAwesomeIcons.faArrowDownArrowUp} style={{width: '14px'}} />}
        >
          {buttonInnerText}
        </Button>
      </CustomWidthTooltip>
      <Menu
        id="community-spending-chart-subcategories-btn"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
        PaperProps={{
          style: {
            minWidth: '200px'
          }
        }}
      >
        <MenuItem
          key="all-subCategories-key"
          onClick={() => handleMenuClick('All Subcategories')}
          id="subcategories"
          sx={{textAlign: 'center', width: '100%'}}
        >
          All Subcategories
        </MenuItem>
        {dropDownData.map((category, i) => (
          <MenuItem key={`all-subCategories-key-${i}`} onClick={() => handleMenuClick(category.name)} id="subcategories">
            {category.name}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

SubCategoriesButton.propTypes = {
  buttonRef: PropTypes.shape({
    current: PropTypes.shape({
      innerText: PropTypes.string
    })
  }),
  legend: PropTypes.string,
  setSubLegend: PropTypes.func,
  name: PropTypes.string,
  spendingDataByTime: PropTypes.array
};

const CategoriesButton = (props) => {
  const {setLegend, name, buttonRef, categoryOne, subLegend} = props;

  const [buttonInnerText, setButtonInnerText] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [isDisabled, setDisabled] = useState(true);

  useEffect(() => {
    setDisabled(categoryOne.length === 0 || subLegend !== 'All Subcategories');
  }, [categoryOne, subLegend]);

  useEffect(() => {
    if (categoryOne.length === 0) {
      setButtonInnerText('No Categories');
    } else {
      setButtonInnerText(name);
    }
  }, [name, categoryOne]);

  const handleClick = (event) => {
    if (!anchorEl) {
      setAnchorEl(event.currentTarget);
    } else {
      setAnchorEl(null);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuClick = (value) => {
    setLegend(value);
    setAnchorEl(null);
  };

  return (
    <div style={{padding: '0 5px 5px 5px'}}>
      <Button
        p={1}
        variant="outlined"
        ref={buttonRef}
        id="community-spending-chart-categories-btn"
        size="small"
        aria-controls="basic-menu"
        aria-haspopup="true"
        aria-expanded={open ? open : undefined}
        onClick={handleClick}
        disabled={isDisabled}
        endIcon={<FontAwesomeIcon icon={fontAwesomeIcons.faArrowDownArrowUp} style={{width: '14px'}} />}
      >
        {buttonInnerText}
      </Button>
      <Menu
        id="community-spending-chart-categories"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
      >
        <MenuItem key="all-categories-key" onClick={() => handleMenuClick('All Categories')} id="categories">
          All Categories
        </MenuItem>
        {categoryOne.map((category, i) => (
          <MenuItem key={`all-categories-key-${i}`} onClick={() => handleMenuClick(category.name)} id="categories">
            {category.name}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

CategoriesButton.propTypes = {
  subLegend: PropTypes.string,
  legend: PropTypes.string,
  categoryOne: PropTypes.array,
  setLegend: PropTypes.func,
  name: PropTypes.string,
  buttonRef: PropTypes.any
};

export const SpendingsHeader = ({legend, subLegend, setLegend, categoryOneData, setSubLegend, spendingsFilteredByMonths}) => {
  const buttonRef = useRef();
  const theme = useTheme();

  const [width, setWidth] = useState(window.innerWidth);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{width: '100%'}}>
      <Typography variant="h5" fontWeight={'fontWeightMedium'}>
        Resident Spending
      </Typography>
      <Stack direction="row">
        <CategoriesButton
          sublegend={legend}
          setLegend={setLegend}
          buttonRef={buttonRef}
          name={legend}
          categoryOne={categoryOneData}
          subLegend={subLegend}
        />
        <SubCategoriesButton
          setSubLegend={setSubLegend}
          legend={legend}
          buttonRef={buttonRef}
          name={subLegend}
          spendingDataByTime={spendingsFilteredByMonths}
        />
      </Stack>
    </Stack>
  );
};

SpendingsHeader.propTypes = {
  subLegend: PropTypes.string,
  legend: PropTypes.string,
  setLegend: PropTypes.func,
  setSubLegend: PropTypes.func,
  spendingsFilteredByMonths: PropTypes.array,
  categoryOneData: PropTypes.array
};

export const CommunitySpendingGraph = ({legend, subLegend, filteredData}) => {
  const theme = useTheme();
  const chartRef = useRef();

  const primary = theme.palette.text.primary;
  const grey500 = theme.palette.grey[500];
  const colors = theme.palette.chart;
  const MMMsYYYYs = findLastTwelveMonthsWithYYYY();
  const MMMandYYs = findLastTwelveMonthsWithYY();

  const [, setDisabled] = useState(true);
  const [spendingsGraphData, setSpendingsGraphData] = useState([]);
  const [chartData, setChartData] = useState({
    height: 480,
    type: 'bar',
    options: {},
    series: []
  });

  const toggleLegends = (chart, idx, config) => {
    const currentSeries = config.globals.series;
    const newSeries = [];
    const filteredSeries = currentSeries.filter((_, i) => i !== idx);
    const checked = filteredSeries.every((e) => {
      return e.every((ele) => ele === 0);
    });

    if (checked) {
      chart.updateSeries(spendingsGraphData);
      setDisabled(true);
    } else {
      spendingsGraphData.forEach((ser, i) => {
        if (i !== idx) {
          const newData = spendingsGraphData[i].data.map(() => {
            return 0;
          });
          newSeries[i] = {name: ser.name, data: newData};
        } else {
          newSeries[i] = {name: ser.name, data: ser.data};
        }
      });
      chart.updateSeries(newSeries);
      setDisabled(false);
    }
  };

  useEffect(() => {
    const newOptions = {
      ...chartData,
      options: {
        chart: {
          id: 'bar-chart',
          stacked: 'true',
          events: {
            legendClick: function (chartContext, seriesIndex, config) {
              return toggleLegends(chartContext, seriesIndex, config);
            }
          }
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '50%'
          }
        },
        xaxis: {
          categories: MMMandYYs
        },
        yaxis: {
          labels: {
            style: {
              colors: [primary]
            },
            formatter: function (value) {
              return '$' + value.toLocaleString();
            }
          }
        },
        tooltip: {
          x: {
            show: false
          },
          custom: function ({seriesIndex, dataPointIndex, w}) {
            const seriesData = w.globals.initialSeries;
            const amount = seriesData[seriesIndex].data[dataPointIndex];
            const totalSum = seriesData.map((ele) => ele.data[dataPointIndex]).reduce((a, b) => a + b);
            const percentage = `${Math.round((amount / totalSum) * 100 * 100) / 100}%`;
            return (
              '<div style="list-style: none; border-radius: 5px; padding: 10px; font-size: .8rem;">' +
              '<li><strong>Amount:</strong> ' +
              `$${amount.toLocaleString()}` +
              '</li>' +
              '<li><strong>Percent:</strong> ' +
              percentage +
              '</li>' +
              '</div>'
            );
          }
        },
        legend: {
          show: true,
          showForSingleSeries: true,
          fontSize: '14px',
          fontFamily: `'Roboto', sans-serif`,
          position: 'bottom',
          offsetX: 20,
          labels: {
            useSeriesColors: false,
            colors: grey500
          },
          markers: {
            width: 16,
            height: 16,
            radius: 5
          },
          itemMargin: {
            horizontal: 15,
            vertical: 8
          },
          onItemClick: {
            toggleDataSeries: false
          },
          onItemHover: {
            highlightDataSeries: true
          }
        },
        dataLabels: {
          enabled: false
        },
        colors: colors ? Object.values(colors) : []
      },
      series: spendingsGraphData
    };

    setChartData(newOptions);
  }, [spendingsGraphData]);

  useEffect(() => {
    let filter = 'category_1';
    let category = legend;

    if (legend !== 'All Categories') {
      filter = 'category_2';
    }

    if (subLegend !== 'All Subcategories') {
      filter = 'category_3';
      category = subLegend;
    }

    const {categoryGraphData} = findGraphData(category, filteredData, MMMsYYYYs, filter);
    setSpendingsGraphData(categoryGraphData);
  }, [legend, subLegend, filteredData]);

  return (
    <Box sx={{width: '100%', p: 1, border: `1px solid ${theme.palette.grey[300]}`, borderRadius: '8px'}}>
      <Chart ref={chartRef} {...chartData} />
    </Box>
  );
};

CommunitySpendingGraph.propTypes = {
  subLegend: PropTypes.string,
  legend: PropTypes.string,
  filteredData: PropTypes.array
};

const RootCategoryRow = (props) => {
  const {category, i, colors, data, totalSum, subData, clickable} = props;
  const {name, amount} = category;
  const subCat = data === 'category_2' ? 'category_3' : 'merchant_name';
  const rowNum = i + 1;

  const [open, setOpen] = useState(false);
  const [percent, setPercent] = useState(0);
  const [gradient, setGradient] = useState([]);
  const [categoriesData, setCategoriesData] = useState([]);
  const [subCatData, setSubCatData] = useState([]);

  let iconComponent;

  useEffect(() => {
    let arr = generateColor(colors[i], '#ffffff', 13);
    setGradient(arr);
  }, []);

  useEffect(() => {
    if (category) {
      let percentNum = (amount / totalSum) * 100;
      setPercent(Math.round(percentNum * 100) / 100);
    }
  }, [data]);

  useEffect(() => {
    const subs = findCategoriesData(subData, data, subCat);

    const arrayOfSubs = Object.values(subs.obj)
      .sort((a, b) => b.amount - a.amount)
      .slice(0, 10);

    setSubCatData(subs.byCategory);
    setCategoriesData(arrayOfSubs);
  }, [category]);

  if (clickable) {
    if (open) {
      iconComponent = <FontAwesomeIcon icon={fontAwesomeIcons['faChevronDown']} />;
    } else {
      iconComponent = <FontAwesomeIcon icon={fontAwesomeIcons['faChevronUp']} />;
    }
  }

  const handleClick = () => {
    if (clickable) {
      setOpen(!open);
    }
  };

  return (
    <React.Fragment>
      <TableRow
        key={`community-name-${i}`}
        sx={{'&:last-child td, &:last-child th': {border: 0}, width: '100%', cursor: 'pointer'}}
        onClick={handleClick}
      >
        <TableCell
          sx={{
            width: '20px',
            textAlign: 'right'
          }}
        >
          <Typography>{rowNum}</Typography>
        </TableCell>
        <TableCell
          sx={{
            width: '20px',
            textAlign: 'left',
            paddingLeft: '0'
          }}
        >
          <Box
            sx={{
              width: 10,
              height: 10,
              bgcolor: colors[i]
            }}
          />
        </TableCell>
        <TableCell sx={{width: '350px'}}>{name}</TableCell>
        <TableCell>{`${percent}%`}</TableCell>
        <TableCell>
          <MoneyFormatCustomDisplay value={amount} />
        </TableCell>
        <TableCell sx={{width: '50px'}}>{iconComponent}</TableCell>
      </TableRow>
      <TableRow sx={{background: '#f4f4f4'}}>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            paddingRight: 0,
            paddingLeft: '3rem'
          }}
          colSpan={12}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Table sx={{alignItems: 'flex-end'}}>
              <TableBody sx={{width: '100%'}}>
                {categoriesData.map((cat, i) => {
                  return (
                    <RootCategoryRow
                      category={cat}
                      i={i}
                      key={`root-category-row-${i}`}
                      colors={gradient}
                      data="merchant_name"
                      totalSum={amount}
                      subData={subCatData[cat?.name]}
                      clickable={subCat !== 'merchant_name'}
                    />
                  );
                })}
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

RootCategoryRow.propTypes = {
  category: PropTypes.shape({
    name: PropTypes.string,
    amount: PropTypes.number,
    subCategories: PropTypes.object
  }),
  i: PropTypes.number,
  colors: PropTypes.any,
  data: PropTypes.string,
  totalSum: PropTypes.number,
  subData: PropTypes.array,
  clickable: PropTypes.bool
};

export const SpendingCategoriesTable = ({filteredData}) => {
  const theme = useTheme();
  const MMMsYYYYs = findLastTwelveMonthsWithYYYY();
  const colors = theme.palette.chart;

  const [categoryOneData, setCategoryOneData] = useState([]);
  const [subData, setSubData] = useState([]);
  const [total, setTotal] = useState();

  useEffect(() => {
    const spendingData = filteredData.filter((spending) => MMMsYYYYs.includes(`${spending.month_str} ${spending.year_str}`));
    const categoriesData = findCategoriesData(spendingData, 'category_1', 'category_2');
    setCategoryOneData(Object.values(categoriesData.obj).sort((a, b) => b.amount - a.amount) || []);
    setSubData(categoriesData.byCategory);
    setTotal(categoriesData.total);
  }, [filteredData]);

  return (
    <Box sx={{width: '100%', p: 1, border: `1px solid ${theme.palette.grey[300]}`, borderRadius: '8px'}}>
      <TableContainer>
        <Table aria-labelledby="tableTitle">
          <TableHead>
            <TableRow>
              <TableCell colSpan={2}></TableCell>
              <TableCell>Category</TableCell>
              <TableCell>Percent</TableCell>
              <TableCell>Gross</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {categoryOneData.map((category, i) => {
              return (
                <RootCategoryRow
                  category={category}
                  i={i}
                  key={`root-categories-row-${i}`}
                  colors={colors}
                  data="category_2"
                  totalSum={total}
                  subData={subData[category?.name]}
                  clickable={true}
                />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

SpendingCategoriesTable.propTypes = {
  filteredData: PropTypes.array
};

export const SpendingVolumeTable = ({filteredData}) => {
  const theme = useTheme();

  const grey300 = theme.palette.grey[300];
  const MMMandYYs = findLastTwelveMonthsWithYY();

  const [chartSeries, setChartSeries] = useState([]);
  const [lineData, setLineData] = useState({
    type: 'line',
    height: 350,
    series: [],
    options: {
      chart: {
        zoom: {
          enabled: false
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        width: [5, 7, 5],
        curve: 'smooth',
        dashArray: [0, 8, 5]
      },
      title: {
        text: 'Total spending volume',
        align: 'left'
      },
      legend: {
        tooltipHoverFormatter: function (val, opts) {
          return val + ' - ' + opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] + '';
        }
      },
      markers: {
        size: 0,
        hover: {
          sizeOffset: 6
        }
      },
      xaxis: {
        categories: []
      },
      tooltip: {
        y: [
          {
            title: {
              formatter: function () {
                return 'Total -';
              }
            }
          }
        ]
      },
      grid: {
        borderColor: '#e3e3e3'
      }
    }
  });

  useEffect(() => {
    const chartData = findSpendingChartData(filteredData, MMMandYYs);
    setChartSeries(chartData);
  }, [filteredData]);

  useEffect(() => {
    const newChartData = {
      ...lineData,
      options: {
        ...lineData.options,
        colors: [grey300],
        xaxis: {
          ...lineData.options.xaxis,
          categories: MMMandYYs
        },
        yaxis: {
          lines: {
            show: false
          },
          labels: {
            formatter: function (value) {
              return '$' + value.toLocaleString();
            }
          }
        }
      },
      series: [
        {
          name: 'Amount',
          data: chartSeries
        }
      ]
    };

    setLineData(newChartData);
  }, [chartSeries]);

  return (
    <Box sx={{width: '100%', p: 1, border: `1px solid ${theme.palette.grey[300]}`, borderRadius: '8px'}}>
      <ReactApexChart {...lineData} />
    </Box>
  );
};

SpendingVolumeTable.propTypes = {
  filteredData: PropTypes.array
};
