import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';

import {Box, Stack, Typography} from '@mui/material';

import ESGMetrics from './EsgMetrics';
import FinancialUnlocks from './FinancialUnlocks';
import StakerInsights from './StakerInsights';
import LoadingBox from '../../@extended/LoadingBox';
import {getIsStakeAdmin} from '../../../../app/store/reducers/session';

const EsgComponent = ({data, isLoading, isSuccess}) => {
  const isStakeAdmin = useSelector(getIsStakeAdmin);
  const [firstBlock, setFirstBlock] = useState(null);
  const [secondBlock, setSecondBlock] = useState(null);
  const [thirdBlock, setThirdBlock] = useState(null);

  useEffect(() => {
    if (data && data.first_block && data.first_block.rows && data.first_block.rows.length > 0) {
      setFirstBlock(data.first_block);
    } else {
      setFirstBlock(null);
    }
    if (data && data.second_block && data.second_block.rows && data.second_block.rows.length > 0) {
      setSecondBlock(data.second_block);
    } else {
      setSecondBlock(null);
    }
    if (data && data.third_block && data.third_block.rows && data.third_block.rows.length > 0) {
      setThirdBlock(data.third_block);
    } else {
      setThirdBlock(null);
    }

    return () => {
      setFirstBlock({});
      setSecondBlock({});
      setThirdBlock({});
    };
  }, [isLoading, data]);

  let financialUnlocksView = null;
  if (firstBlock) {
    financialUnlocksView = <FinancialUnlocks key={firstBlock.title} data={firstBlock} pdfData={data} />;
  }

  let stakerInsightsView = null;
  if (secondBlock) {
    stakerInsightsView = <StakerInsights key={secondBlock.title} data={secondBlock} />;
  }

  let esgMetrics = null;
  if (thirdBlock) {
    esgMetrics = <ESGMetrics key={thirdBlock.title} data={thirdBlock} />;
  }

  let paperContent;

  if (isLoading) {
    paperContent = <LoadingBox />;
  } else if (isSuccess && (data || isStakeAdmin)) {
    paperContent = (
      <Box sx={{width: '100%', overflow: 'hidden'}}>
        <Stack direction="column" rowGap={4}>
          {financialUnlocksView}
          {stakerInsightsView}
          {esgMetrics}
        </Stack>
      </Box>
    );
  } else {
    paperContent = (
      <Box sx={{width: '100%', height: 500, p: 2, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <Typography variant="subtitle1" fontWeight={'fontWeightRegular'} color={'text.subtitle'} textAlign="center">
          No ESG data available
        </Typography>
      </Box>
    );
  }

  return <>{paperContent}</>;
};

EsgComponent.propTypes = {
  data: PropTypes.object,
  isLoading: PropTypes.bool,
  isSuccess: PropTypes.bool,
  isError: PropTypes.bool
};

export default EsgComponent;
