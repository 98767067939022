import React, {useMemo, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {ComposedChart, Legend, ResponsiveContainer, XAxis, YAxis, Line, Area, Tooltip} from 'recharts';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Typography, Stack, Grid, useTheme, Paper, Box} from '@mui/material';

import {CustomizedXAxisTick} from '../../@extended/Recharts';
import {CustomFinancialChartTooltip, renderCustomLegendText} from '../../@extended/CustomTooltip';
import {fontAwesomeIcons} from '../../../utils/icons';

const FiancialUnlockTable = (entry) => {
  return (
    <Stack direction="column" sx={{width: '100%'}}>
      <Typography variant="h5" fontWeight={'fontWeightRegular'}>
        {entry.metric}
      </Typography>

      <Typography variant="subtitle1" fontWeight={'fontWeightRegular'} color={'text.subtitle'}>
        {entry.label}
      </Typography>
    </Stack>
  );
};

const EngagementFinancialUnlocks = ({engagementFinancials}) => {
  const theme = useTheme();

  const [ready, setReady] = useState(false);

  const [items, setItems] = useState([]);
  const [lineData, setLineData] = useState([]);

  const title = useMemo(() => {
    return engagementFinancials.title ?? '';
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(engagementFinancials)]);

  useEffect(() => {
    if (engagementFinancials.data) {
      setLineData(engagementFinancials.data);
    }

    if (engagementFinancials.items) {
      setItems(engagementFinancials.items);
    }

    return () => {
      setLineData([]);
      setItems([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(engagementFinancials ?? {})]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setReady(true);
    }, 1000);

    return () => clearTimeout(timeout);
  }, [JSON.stringify(lineData)]);

  const [lineValue, lineTicks] = useMemo(() => {
    const keysArray = Object.keys(lineData);
    const valuesArray = Object.values(lineData).map((value) => Number(value.toFixed(2)));

    const graphData = keysArray.map((entry, index) => {
      const entry_year = parseInt(entry.split('-')[0]);
      const entry_month = parseInt(entry.split('-')[1]);
      const date = new Date(entry_year, entry_month - 1, 1);
      const options = {year: '2-digit', month: 'short'};

      if (valuesArray[index]) {
        return {
          name: date.toLocaleDateString('en-US', options).replace(' ', "'"),
          unlocks: valuesArray[index]
        };
      }

      return null;
    });

    if (keysArray == 0) return [0, 0];
    const maxValue = Math.max(...valuesArray);
    const roundedMax = Math.ceil(maxValue / 100) * 100;
    const extendedMax = Math.ceil((roundedMax * 1.1) / 100) * 100;
    const tickInterval = extendedMax / (6 - 1);
    const ticks = Array.from({length: 6}, (_, index) => index * tickInterval);

    return [graphData.filter((v) => v), ticks];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(lineData)]);

  const widthSize = useMemo(() => {
    if (items.length == 0) return '0%';
    return `${100 / items.length}%`;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(items)]);

  if (items.length == 0 && lineData.length == 0) {
    return <></>;
  }

  let graphView;
  if (!ready) {
    graphView = <></>;
  } else if (lineValue && lineValue.length > 0) {
    graphView = (
      <ResponsiveContainer width={'100%'} height={250}>
        <ComposedChart width={500} height={300} data={lineValue} margin={{top: 5, right: 30, left: 20, bottom: 5}}>
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#00CB52" stopOpacity={0.3} />
              <stop offset="90%" stopColor="#FFFFFF" stopOpacity={0.1} />
            </linearGradient>
          </defs>
          <XAxis dataKey="name" tickSize={0} dy={10} stroke="rgba(227, 227, 227, 255)" tick={<CustomizedXAxisTick />} />
          <YAxis
            dx={-30}
            tickSize={0}
            axisLine={false}
            opacity={0.4}
            domain={[lineTicks[0], lineTicks[lineTicks.length - 1]]}
            ticks={lineTicks}
            interval="preserveStartEnd"
          />
          <Tooltip wrapperStyle={{outline: 'none'}} cursor={false} content={<CustomFinancialChartTooltip />} />
          <Legend
            iconType="square"
            wrapperStyle={{paddingTop: 15}}
            formatter={(_, __, index) => {
              if (index == 1) {
                return;
              }
              return renderCustomLegendText('Financial Tools Unlocked', theme);
            }}
          />
          <Line
            dataKey="unlocks"
            stroke="#00CB52"
            fill={{
              type: 'linearGradient',
              colors: ['#00CB52', '#FFFFFF']
            }}
            dot={{stroke: '#00CB52', fill: '#00CB52', strokeWidth: 1, r: 3}}
          />
          <Area dataKey="unlocks" stroke="transparent" strokeWidth={2} fillOpacity={1} fill="url(#colorUv)" legendType="none" />
        </ComposedChart>
      </ResponsiveContainer>
    );
  } else {
    graphView = (
      <Grid container direction="column" justifyContent="center" alignItems="center" sx={{width: '100%', height: '200px'}}>
        <Grid item>
          <FontAwesomeIcon icon={fontAwesomeIcons['faChartNetwork']} size="2x" color="#149CE0" />
        </Grid>
        <Grid item sx={{marginTop: '10px'}}>
          <Typography variant="subtitle1" fontWeight={'fontWeightRegular'} color={'text.subtitle'}>
            Data collection in process.
          </Typography>
        </Grid>
      </Grid>
    );
  }

  return (
    <Paper sx={{p: 2, borderRadius: 2, boxShadow: 'none'}}>
      <Stack direction="column" rowGap={2} sx={{width: '100%'}}>
        <Stack direction={'column'}>
          <Typography variant="h5" fontWeight={'fontWeightMedium'}>
            {title ?? 'Financial Unlocks'}
          </Typography>
          <Typography variant="subtitle1" fontWeight={'fontWeightRegular'} color={'text.subtitle'}>
            Track resident engagement with Stake features.
          </Typography>
        </Stack>

        <Stack
          direction="row"
          sx={{
            flex: '1 1 0',
            width: '100%'
          }}
          columnGap={1.5}
        >
          {items.map((entry, index) => (
            <Paper
              key={index}
              style={{
                width: widthSize,
                borderRadius: '8px',
                boxShadow: 'none',
                padding: '16px'
              }}
            >
              {FiancialUnlockTable(entry)}
            </Paper>
          ))}
        </Stack>
        <Box width={'100%'} sx={{border: `1px solid ${theme.palette.grey[300]}`, borderRadius: '8px', padding: '16px', paddingTop: '24px'}}>
          {graphView}
        </Box>
      </Stack>
    </Paper>
  );
};

EngagementFinancialUnlocks.propTypes = {
  engagementFinancials: PropTypes.object
};

export default EngagementFinancialUnlocks;
