import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';

import {Stack} from '@mui/material';

import {getRandomValue, GlanceDaysOnMarketColumns, valueGetter} from '../../../../../constants/tableColumns';
import {ExportToolBarWithSearch} from '../../../../@extended/DataGridTables';
import {getCommunities} from '../../../../../../app/store/reducers/entities';
import {StyledTable} from '../../../../@extended/CustomTables';

import TableBodyCell from '../../../../@extended/TableBodyCell';

const DaysOnMarketGlance = ({data}) => {
  const communities = useSelector(getCommunities);

  const columns = useMemo(
    () =>
      GlanceDaysOnMarketColumns.map((column) => ({
        ...column,
        field: column.id,
        headerName: column.label,
        renderCell: (params) => <TableBodyCell column={column} params={params} />,
        renderEditCell: (params) => <TableBodyCell column={column} params={params} />,
        valueFormatter: (params) => valueGetter(params, column)
      })),
    []
  );

  const performers = useMemo(() => {
    const obj = [];

    for (const d of data) {
      const performerId = getRandomValue();
      const prop_comp = `${Math.round(d.days_on_market)}`;
      const comps = `${Math.round(d.days_on_market_comps)}`;

      const community = communities.find((c) => c.sfid === d.property_sfid);
      let usState = community?.full_address__statecode__s;

      if (!usState && community?.address__c) {
        usState = community?.address__c.split(' ');
        usState = usState[usState.length - 2];
      }

      const o = {
        ...d,
        id: performerId,
        days_on_market: prop_comp,
        days_on_market_comps: comps,
        us_state: usState
      };

      obj.push(o);
    }

    return obj;
  }, [data, communities]);

  const topComponent = useMemo(() => {
    if (performers?.length > 0 && columns.length > 0) {
      return (
        <Stack direction={'column'} rowGap={1}>
          <StyledTable
            rows={performers || []}
            columns={columns}
            pagination
            pageSizeOptions={[10, 20, 50, 100]}
            slots={{toolbar: ExportToolBarWithSearch}}
            initialState={{
              pagination: {paginationModel: {pageSize: 10}},
              sorting: {
                sortModel: [{field: 'property_name', sort: 'asc'}]
              }
            }}
            autoHeight
            density="compact"
            hideFooter={performers.length < 10}
            rowHeight={40}
            disableRowSelectionOnClick
            getRowId={(row) => row.id}
          />
        </Stack>
      );
    } else {
      return null;
    }
  }, [performers, columns]);

  return <Stack direction={'column'}>{topComponent}</Stack>;
};

DaysOnMarketGlance.propTypes = {
  data: PropTypes.array
};

export default DaysOnMarketGlance;
