import React, {useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';

import DashboardComponent from './dashboardComponent';

import {endComponent, getOpenItem} from '../../../app/store/reducers/menu';
import {getDashboardCampaign, getDashboardCampaigns} from '../../../app/store/reducers/dashboard';
import {getCampaignLoadingStatus} from '../../../app/store/reducers/loading';
import {useParams} from 'react-router-dom';

const CampaignDashboard = ({createHandleMenuClick}) => {
  const params = useParams();
  const type = 'campaigns';
  const dispatch = useDispatch();
  const campaignLoadingStatus = useSelector(getCampaignLoadingStatus);

  const openItem = useSelector(getOpenItem);
  const dashboardCampaign = useSelector(getDashboardCampaign);
  const dashboardCampaigns = useSelector(getDashboardCampaigns);

  const formattedCampaigns = useMemo(() => {
    const fCampaigns = [...dashboardCampaigns];
    return [{sfid: 'all', name: `All campaigns (${fCampaigns.length || 0})`}, ...fCampaigns];
  }, [dashboardCampaigns]);

  const isActive = openItem === type.toLowerCase();

  useEffect(() => {
    dispatch(endComponent(type.toLowerCase()));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  return (
    <DashboardComponent
      type={type}
      dashboardObj={dashboardCampaign}
      isLoading={campaignLoadingStatus.isLoading}
      isSuccess={campaignLoadingStatus.isSuccess}
      data={formattedCampaigns}
      createHandleMenuClick={createHandleMenuClick}
      isActive={isActive}
    />
  );
};

CampaignDashboard.propTypes = {
  createHandleMenuClick: PropTypes.func
};

export default CampaignDashboard;
