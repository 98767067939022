import React, {useEffect, useMemo} from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {useSelector} from 'react-redux';
import {FormProvider, useForm} from 'react-hook-form';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import {Button, Dialog, DialogContent, Typography, Stack} from '@mui/material';

import {DialogTitleWithCloseIcon} from '../../commons/components/@extended/CustomDialogs';
import {FormAmount, FormInput, FormRangePicker, FormTextArea, SimpleFormSelectWithLabel} from '../../commons/components/@extended/Forms';
import {getCommunities} from '../../app/store/reducers/entities';
import validateEmail from '../../commons/utils/emailValidation';
import {useUpdateResidentInfoMutation} from '../../features/coreApi/residentsSlice';
import {getEmail} from '../../app/store/reducers/session';

dayjs.extend(utc);

const residentSchema = Yup.object().shape({
  firstname: Yup.string().required().min(1),
  lastname: Yup.string().required().min(1),
  email: Yup.string()
    .required('Email is required')
    .test('is-valid-email', 'Email is invalid', (value) => validateEmail(value?.replace(/\s/g, ''))),
  phone: Yup.string()
    .required('Phone number is required')
    .matches(/^(\+?\d{1,3}[- ]?)?\d{10}$/, 'Please enter a valid phone number'),
  dates: Yup.array().of(Yup.date().required()).min(2),
  apartment_or_unit__c: Yup.string().notRequired(),
  rent_amount__c: Yup.number().min(1).notRequired(),
  property__c: Yup.string().required(),
  notes_from_lc__c: Yup.string().notRequired()
});

const EditResidentPage = ({isOpen, setOpen, resident}) => {
  const communities = useSelector(getCommunities);
  const email = useSelector(getEmail);
  const [updateResident, {isSuccess}] = useUpdateResidentInfoMutation();
  const formattedCommunities = useMemo(() => {
    return communities.map((c) => ({
      id: c.sfid,
      name: c.name
    }));
  }, [communities]);

  const defaultValues = useMemo(() => {
    const startDate = resident.lease_start_date__c ? dayjs.utc(resident.lease_start_date__c) : null;
    const endDate = resident.lease_end_date__c ? dayjs.utc(resident.lease_end_date__c) : null;

    return {
      firstname: resident.firstname || '',
      lastname: resident.lastname || '',
      email: resident.email || '',
      phone: resident.phone || '',
      dates: [startDate, endDate],
      apartment_or_unit__c: resident.apartment_or_unit__c || '',
      rent_amount__c: resident.rent_amount__c || 0,
      property__c: resident.property__c,
      notes_from_lc__c: resident.notes_from_lc__c || ''
    };
  }, [resident]);

  const methods = useForm({
    mode: 'onBlur',
    defaultValues,
    resolver: yupResolver(residentSchema)
  });

  const {
    handleSubmit,
    formState: {isValid, isDirty},
    reset
  } = methods;

  const onSubmit = (data) => {
    const updateObj = new FormData();

    for (const key in data) {
      if (key == 'dates') {
        const startDateValue = dayjs.utc(data[key][0]).format('YYYY-MM-DD');
        const endDateValue = dayjs.utc(data[key][1]).format('YYYY-MM-DD');

        if (startDateValue !== resident.lease_start_date__c) {
          updateObj.append('lease_start_date__c', startDateValue);
        }

        if (endDateValue !== resident.lease_end_date__c) {
          updateObj.append('lease_end_date__c', endDateValue);
        }
      } else if (data[key] && data[key] !== resident[key]) {
        updateObj.append(key, data[key]);
      }
    }

    updateResident({dataForm: updateObj, id: resident.sfid, ls_user_name: email});
  };

  useEffect(() => {
    reset(defaultValues);
  }, [isOpen]);

  useEffect(() => {
    if (isSuccess) {
      setOpen(false);
    }
  }, [isSuccess]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack direction="column" rowGap={2}>
          <Stack direction="column" rowGap={2}>
            <Typography variant="h6" color={'text.subtitle'}>
              Primary Resident Information
            </Typography>
            <Stack direction="column">
              <Stack direction="row" columnGap={2}>
                <FormInput name="firstname" label="First Name" />
                <FormInput name="lastname" label="Last Name" />
              </Stack>
              <Stack direction="row" columnGap={2}>
                <FormInput name="email" label="Email" />
                <FormInput name="phone" label="Phone number" />
              </Stack>
            </Stack>
          </Stack>
          <Stack direction="column" rowGap={2}>
            <Typography variant="h6" color={'text.subtitle'}>
              Rental Community
            </Typography>
            <Stack direction="column">
              <Stack direction="row" columnGap={1} sx={{width: '100%'}}>
                <FormRangePicker name="dates" start="Lease Starts" end="Lease Ends" />
              </Stack>
              <Stack direction="row" columnGap={2}>
                <FormInput name="apartment_or_unit__c" label="Apartment, Unit, etc" />
                <FormAmount name="rent_amount__c" label="Rent Amount" />
              </Stack>
            </Stack>
          </Stack>
          <Stack direction="column" rowGap={2}>
            <Typography variant="h6" color={'text.subtitle'}>
              Note
            </Typography>
            <FormTextArea name="notes_from_lc__c" label="Additional info" />
          </Stack>
          <Stack direction="row" justifyContent={'flex-end'} rowGap={2}>
            <Button type="submit" variant="contained" color="secondary" disabled={!isValid || !isDirty}>
              Save changes
            </Button>
          </Stack>
        </Stack>
      </form>
    </FormProvider>
  );
};

EditResidentPage.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  resident: PropTypes.object.isRequired
};

export default EditResidentPage;
