import React from 'react';

import {Typography} from '@mui/material';

import TableBodyCell from '../../@extended/TableBodyCell';
import {ResidentsColumns, valueGetter} from '../../../constants/tableColumns';
import {findActiveOffersChipValueFromOffers} from '../Today/Campaign/utils';

export const findCommunity = (communities, id) => {
  return communities.find((com) => com.sfid === id);
};

export const findCampaign = (campaigns, id) => {
  return campaigns.find((c) => c.id === id);
};

export const findTMMaxHeight = (row) => {
  const {model} = row;
  const {permissions} = model;
  if (permissions.length <= 1) return 60;

  const height = permissions.length * 40;
  return height;
};

export const filterResidents = (residents, openItem, dashboardCommunity, dashboardCampaign, dashboardAccount) => {
  switch (openItem) {
    case 'communities':
      return dashboardCommunity.sfid && dashboardCommunity.sfid !== 'all'
        ? residents.filter((r) => r.property__c === dashboardCommunity.sfid)
        : [];
    case 'campaigns':
      return dashboardCampaign.sfid && dashboardCampaign.sfid !== 'all'
        ? residents.filter((r) => r.renter_campaign__c === dashboardCampaign.sfid)
        : [];
    case 'portfolio':
      return dashboardAccount.sfid ? residents : [];
    default:
      return [];
  }
};

export const prepareRows = (residents, communities, campaigns) => {
  return residents.map((res) => {
    const obj = {id: res.sfid};
    let offers = [];

    if (res?.offers?.some((offer) => offer.reward_amount > 0)) {
      offers = findActiveOffersChipValueFromOffers(res.offers);
    }

    ResidentsColumns.forEach((column) => {
      const {id} = column;
      obj[id] = res[id];

      if (id === 'community') {
        const community = findCommunity(communities, res.property__c);
        obj[id] = community?.name || '';
        obj.communityData = community || {};
      } else if (id === 'campaign') {
        const camp = findCampaign(campaigns, res.renter_campaign__c);
        obj[id] = camp?.name || '';
        obj.campaignData = camp || {};
      } else if (id === 'offers') {
        obj[id] = offers;
      }
    });

    return obj;
  });
};

export const offersComparator = (v1, v2) => v1.length - v2.length;

export const getColumns = (openItem) => {
  let filteredColumns = [...ResidentsColumns];

  if (openItem === 'communities') {
    filteredColumns = filteredColumns.filter((c) => c.id !== 'community');
  } else if (openItem === 'campaigns') {
    filteredColumns = filteredColumns.filter((c) => c.id !== 'campaign' && c.id !== 'community');
  }

  return filteredColumns.map((column) => {
    const baseColumn = {
      ...column,
      field: column.id,
      headerName: column.label,
      renderCell: (params) => <TableBodyCell column={column} params={params} />,
      valueFormatter: (params) => valueGetter(params, column),
      renderEditCell: (params) => <TableBodyCell column={column} params={params} />,
      renderHeader: (params) => (
        <Typography variant="h6" {...params} style={{whiteSpace: 'normal', lineHeight: 'normal'}}>
          {params.colDef.headerName}
        </Typography>
      )
    };

    // Add special handling for the 'offers' column
    if (column.id === 'offers') {
      return {
        ...baseColumn,
        sortComparator: offersComparator
      };
    }

    return baseColumn;
  });
};
