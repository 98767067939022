import React, {useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';

import {ComposedChart, Legend, ResponsiveContainer, XAxis, YAxis, Bar, ReferenceLine, Tooltip, Line} from 'recharts';
import {Box, Stack, Typography, useTheme} from '@mui/material';
import {findBarRadius, findBarSize, findTickTickArray} from '../../constants/graphConstants';
import {renderLegend} from './GraphLegend';
import {CustomConversionsTooltipComponent, CustomTooltipComponent, GraphTooltip} from './GraphTooltip';

const CustomBar = (props) => {
  const {fill, x, y, width, height, value, dataKey, payload, graphName} = props;

  if (value === undefined || value === null) return null;

  const minHeight = value >= 0 ? 2 : 0;
  // const actualHeight = Math.max(Math.abs(height), minHeight);
  let borderRadius = 3;

  let actualHeight, yPosition;
  if (value >= 0) {
    actualHeight = Math.max(Math.abs(height), minHeight);
    yPosition = y + (Math.abs(height) - actualHeight);
  } else {
    actualHeight = Math.max(Math.abs(height), minHeight);
    yPosition = y;
  }

  // Adjust borderRadius if the height is less than 2 * borderRadius
  borderRadius = Math.min(borderRadius, actualHeight / 2);

  // Filter out bars that have no value for the current month
  const activeBars = Object.keys(payload).filter((key) => key !== 'label' && key !== 'fullDate' && payload[key] >= 0);

  // if (activeBars.length == 4 || graphName != 'Delinquency rate') {
  return (
    <path
      d={`
        M ${x},${yPosition + actualHeight}
        L ${x},${yPosition + borderRadius}
        Q ${x},${yPosition} ${x + borderRadius},${yPosition}
        L ${x + width - borderRadius},${yPosition}
        Q ${x + width},${yPosition} ${x + width},${yPosition + borderRadius}
        L ${x + width},${yPosition + actualHeight}
        Z
        `}
      fill={fill}
    />
  );
  // }

  // const index = activeBars.indexOf(dataKey);

  // if (index === -1) return null; // Skip rendering if this bar isn't active
  // const totalBars = activeBars.length;
  // const totalWidth = totalBars * width * 0.1
  // const addWidth = totalBars == 3 ? width * 0.3 : totalBars < 3 ? width : 0

  // // Adjust startX to center active bars within the available space
  // const startX = x + addWidth + ((width - totalWidth) / 2) + index * width * 0.1

  // return (
  //   <path
  //     d={`
  //       M ${startX},${yPosition + actualHeight}
  //       L ${startX},${yPosition + borderRadius}
  //       Q ${startX},${yPosition} ${startX + borderRadius},${yPosition}
  //       L ${startX + width - borderRadius},${yPosition}
  //       Q ${startX + width},${yPosition} ${startX + width},${yPosition + borderRadius}
  //       L ${startX + width},${yPosition + actualHeight}
  //       Z
  //     `}
  //     fill={fill}
  //   />
  // );
};

export const MetricsGraph = ({graphData, name}) => {
  const ref = useRef(null);
  const theme = useTheme();
  const [tooltipData, setTooltipData] = useState({
    data: null,
    color: null
  });

  const flatValues = useMemo(
    () =>
      [...graphData.data].flatMap((element) => {
        const copyEle = {...element};
        delete copyEle.label;
        delete copyEle.fullDate;
        return Object.values(copyEle).length > 0 ? Object.values(copyEle).map((v) => Math.ceil(v)) : 0;
      }),
    [graphData]
  );

  const minDomain = useMemo(() => Math.floor(Math.min(...flatValues) / 10) * 10, [flatValues]);
  const maxDomain = useMemo(() => Math.ceil(Math.max(...flatValues) / 10) * 10, [flatValues]);
  const domain = useMemo(() => {
    return [minDomain >= 0 ? 0 : minDomain, maxDomain];
  }, [minDomain, maxDomain]);
  const tickArray = useMemo(() => findTickTickArray(domain), [domain]);

  let graphComponent;

  const customTooltipData = (color, data) => {
    if (tooltipData.color !== color) {
      setTooltipData({data: data, color: color});
    }
  };

  const width = ref.current?.offsetWidth || 0;
  const barSize = findBarSize(graphData.data.length, width);

  graphComponent = (
    <ResponsiveContainer width="99%" height={260}>
      <ComposedChart
        key={`chart-for-${name.split(' ').join('-').toLowerCase()}`}
        width={width}
        height={260}
        data={[...graphData.data]}
        margin={{
          top: 5,
          right: 5,
          left: -5,
          bottom: 5
        }}
      >
        <XAxis
          dataKey="label"
          tickSize={0}
          tickLine={false}
          stroke="rgba(227, 227, 227, 0)"
          dy={10}
          height={40}
          tick={{fill: theme.palette.grey[600]}}
        />
        <YAxis
          yAxisId="left"
          dx={-10}
          tickSize={0}
          tickFormatter={(d) => (name == 'Days on Market' ? d : `${d}%`)}
          opacity={0.4}
          axisLine={false}
          allowDataOverflow={true}
          domain={[tickArray[0], tickArray[tickArray.length - 1]]}
          ticks={tickArray}
          tick={{fill: theme.palette.grey[600]}}
          minTickGap={0}
        />
        <Tooltip
          wrapperStyle={{outline: 'none'}}
          cursor={false}
          formatter={(d) => `${d.toFixed(2)}%`}
          content={<GraphTooltip chartName={name} />}
        />
        <Legend content={renderLegend} />
        <ReferenceLine yAxisId={'left'} y={0} label="" stroke="rgba(227, 227, 227, 1)" />
        {Object.keys(graphData.colors).map((key, idx) => {
          const barRadius = findBarRadius(false, idx, Object.keys(graphData.colors));

          return (
            <Bar
              key={`chart-bar-for-${key}`}
              yAxisId="left"
              dataKey={key}
              fill={graphData.colors[key]}
              barSize={barSize}
              radius={barRadius}
              stackOffset="sign"
              onMouseOver={(e) => customTooltipData(graphData.colors[key], e)}
              shape={<CustomBar graphName={name} />}
            />
          );
        })}
      </ComposedChart>
    </ResponsiveContainer>
  );

  return (
    <Box ref={ref} sx={{width: '100%', pt: 2, borderRadius: '8px', border: `1px solid ${theme.palette.grey[300]}`}}>
      <Stack direction="column" rowGap={1} justifyContent="center" alignItems="center">
        <Typography sx={{width: '99%', opacity: 0.4, fontWeight: 400}} textAlign="center" variant="subtitle1">
          {name}
        </Typography>
        {graphComponent}
      </Stack>
    </Box>
  );
};

MetricsGraph.propTypes = {
  graphData: PropTypes.shape({
    data: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        fullDate: PropTypes.string,
        staker: PropTypes.number,
        non_staker: PropTypes.number,
        reduction: PropTypes.number
      })
    ),
    colors: PropTypes.shape({
      staker: PropTypes.string,
      non_staker: PropTypes.string,
      reduction: PropTypes.string
    })
  }),
  name: PropTypes.string
};

export const GraphComponent = ({metricsData, name, seconfGraphInfo}) => {
  const isDefault = ['Renewals', 'Lease-To-Close'].includes(name);
  const isStacked = ['signed', 'Lease-To-Close'].includes(name);

  const ref = useRef(null);
  const theme = useTheme();
  const [tooltipData, setTooltipData] = useState({
    data: null,
    color: null
  });

  const flatValues = useMemo(
    () =>
      [...metricsData.data].flatMap((element) => {
        const copyEle = {...element};
        delete copyEle.label;
        delete copyEle.fullDate;
        const del_keys = Object.keys(copyEle).filter((key) => key.includes('amount_format') || key.includes('tooltip_name'));
        del_keys.forEach((key) => delete copyEle[key]);

        if (name.includes('trade')) {
          delete copyEle['Avg Weighted Percentage Improvement'];
        }
        if (name.includes('conversions')) {
          delete copyEle['Expired leases'];
          delete copyEle['Renewal percentage'];
        }

        return Object.values(copyEle).length > 0 ? Object.values(copyEle).map((v) => Math.round(v)) : 0;
      }),
    [metricsData, name]
  );

  const minDomain = useMemo(() => Math.floor(Math.min(...flatValues) / 10) * 10, [flatValues]);
  const maxDomain = useMemo(() => {
    if (isStacked) {
      const values = [...metricsData.data].map((element) => {
        const copyEle = {...element};
        delete copyEle.label;
        delete copyEle.fullDate;
        const del_keys = Object.keys(copyEle).filter((key) => key.includes('amount_format') || key.includes('tooltip_name'));
        del_keys.forEach((key) => delete copyEle[key]);

        const vs = Object.values(copyEle).length > 0 ? Object.values(copyEle).map((v) => Math.round(v)) : 0;
        return Math.max(...vs);
      });

      return Math.ceil(Math.max(...values) / 10) * 10;
    } else {
      return Math.ceil(Math.max(...flatValues) / 10) * 10;
    }
  }, [flatValues, isStacked, metricsData]);

  const domain = [minDomain >= 0 ? 0 : minDomain, maxDomain];
  const tickArray = useMemo(() => findTickTickArray(domain), [domain]);
  let barColors = [];
  const lineColors = [];

  for (const key in metricsData.colors) {
    if (metricsData.colors[key].graph === 'bar') {
      barColors.push(key);
    } else if (metricsData.colors[key].graph === 'line') {
      lineColors.push(key);
    }
  }
  const stakeColors = barColors.filter((color) => color.toLowerCase().includes('stake+'));
  const otherColors = barColors.filter((color) => !color.toLowerCase().includes('stake+'));
  barColors = stakeColors.concat(otherColors);

  let graphComponent;

  const customTooltipData = (color, data) => {
    if (tooltipData.color !== color) {
      setTooltipData({data: data, color: color});
    }
  };

  const width = ref.current?.offsetWidth || 0;
  const barSize = findBarSize(metricsData.data.length, width);

  let customTooltip = <CustomTooltipComponent />;

  if (name.includes('conversions')) {
    customTooltip = <CustomConversionsTooltipComponent />;
  }

  graphComponent = (
    <ResponsiveContainer width="99%" height={300}>
      <ComposedChart
        key={`chart-for-${name.split(' ').join('-').toLowerCase()}`}
        width={width}
        height={300}
        data={[...metricsData.data]}
        margin={{
          top: 5,
          right: 5,
          left: -5,
          bottom: 5
        }}
      >
        <XAxis
          dataKey="label"
          tickSize={0}
          tickLine={false}
          stroke="rgba(227, 227, 227, 0)"
          dy={10}
          height={40}
          tick={{fill: theme.palette.grey[600]}}
        />
        <YAxis
          yAxisId="left"
          dx={-10}
          tickSize={0}
          tickFormatter={(d) => (!isDefault ? d : `${d}%`)}
          opacity={0.4}
          axisLine={false}
          allowDataOverflow={true}
          domain={domain}
          ticks={tickArray}
        />
        <YAxis
          hide={!seconfGraphInfo?.isVisible}
          yAxisId="right"
          orientation="right"
          dx={10}
          tickSize={0}
          tickFormatter={seconfGraphInfo?.formatter}
          opacity={0.4}
          axisLine={false}
          allowDataOverflow={true}
          domain={[tickArray[0], tickArray[tickArray.length - 1]]}
          ticks={tickArray}
        />
        <Tooltip wrapperStyle={{outline: 'none'}} cursor={false} formatter={(d) => `${d.toFixed(2)}%`} content={customTooltip} />
        <Legend content={renderLegend} />
        <ReferenceLine yAxisId={'left'} y={0} label="" stroke="rgba(227, 227, 227, 1)" />
        {lineColors.map((key) => {
          const value = metricsData.colors[key];
          return (
            <Line
              key={`chart-line-for-${key.split(' ').join('-').toLowerCase()}`}
              dot={false}
              hide={!seconfGraphInfo?.isVisible}
              yAxisId="right"
              dataKey={key}
              stroke={value.color}
            />
          );
        })}
        {barColors.map((key, idx) => {
          const value = metricsData.colors[key];
          const barRadius = findBarRadius(isStacked, idx, barColors);
          return (
            <Bar
              key={`chart-bar-for-${key.split(' ').join('-').toLowerCase()}`}
              yAxisId="left"
              stackId={isStacked ? 'stack' : null}
              dataKey={key}
              fill={value.color}
              radius={barRadius}
              barSize={value.color == '#FFFFFF' ? 0 : barSize}
              minPointSize={isStacked ? 0 : 1}
              onMouseOver={(e) => customTooltipData(value.color, e)}
            />
          );
        })}
      </ComposedChart>
    </ResponsiveContainer>
  );

  return (
    <Box ref={ref} sx={{width: '100%', pt: 2, borderRadius: '8px', border: `1px solid ${theme.palette.grey[300]}`}}>
      <Stack direction="column" rowGap={1} justifyContent="center" alignItems="center">
        <Typography sx={{width: '99%', opacity: 0.4, fontWeight: 400}} textAlign="center" variant="subtitle1">
          {name}
        </Typography>
        {graphComponent}
      </Stack>
    </Box>
  );
};

GraphComponent.propTypes = {
  metricsData: PropTypes.shape({
    colors: PropTypes.object,
    data: PropTypes.array
  }),
  name: PropTypes.string,
  seconfGraphInfo: PropTypes.shape({
    isVisible: PropTypes.bool,
    formatter: PropTypes.func
  })
};
