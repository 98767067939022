import React, {useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {Divider, Paper, Stack, Typography, useTheme} from '@mui/material';
import {CustomNoRowsOverlay, ExportToolBarWithSearch} from '../../@extended/DataGridTables';

import {getCampaigns, getCommunities, getResidents} from '../../../../app/store/reducers/entities';
import {activeModal, getOpenItem} from '../../../../app/store/reducers/menu';
import {getDashboardAccount, getDashboardCampaign, getDashboardCommunity} from '../../../../app/store/reducers/dashboard';
import {getResidentLoadingStatus} from '../../../../app/store/reducers/loading';
import {filterResidents, getColumns, prepareRows} from './utils';
import {StyledTable} from '../../@extended/CustomTables';

const ResidentsTable = () => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const {isLoading} = useSelector(getResidentLoadingStatus);
  const residents = useSelector(getResidents);
  const communities = useSelector(getCommunities);
  const campaigns = useSelector(getCampaigns);

  const {dashboardAccount, dashboardCommunity, dashboardCampaign, openItem} = useSelector((state) => ({
    openItem: getOpenItem(state),
    dashboardAccount: getDashboardAccount(state),
    dashboardCommunity: getDashboardCommunity(state),
    dashboardCampaign: getDashboardCampaign(state)
  }));

  const filteredResidents = useMemo(
    () => filterResidents(residents, openItem, dashboardCommunity, dashboardCampaign, dashboardAccount),
    [residents, openItem, dashboardCommunity, dashboardCampaign, dashboardAccount]
  );

  const rows = useMemo(() => prepareRows(filteredResidents, communities, campaigns), [filteredResidents, communities, campaigns]);
  const columns = useMemo(() => getColumns(openItem), [openItem]);

  const handleRowClick = (params) => {
    const {row} = params;
    dispatch(activeModal({type: 'resident_info', sfid: row.id}));
  };

  return (
    <Paper sx={{borderRadius: 2, boxShadow: 'none'}}>
      <Stack direction="column" sx={{width: '100%', height: '100%'}} divider={<Divider />}>
        <Typography variant="h5" fontWeight={'fontWeightMedium'} sx={{p: '16px 24px'}}>
          Residents
        </Typography>
        <StyledTable
          rows={rows}
          columns={columns}
          loading={isLoading}
          slots={{toolbar: ExportToolBarWithSearch, noRowsOverlay: CustomNoRowsOverlay}}
          pagination
          pageSizeOptions={[10, 20, 50, 100]}
          initialState={{pagination: {paginationModel: {pageSize: 10}}}}
          autoHeight
          rowHeight={60}
          disableRowSelectionOnClick
          getRowId={(row) => row.id}
          onRowClick={handleRowClick}
          sx={{
            fontSize: '1rem',
            '& .MuiDataGrid-columnHeaderTitleContainer': {fontSize: '0.875rem'},
            '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus': {outline: 'none'},
            '--DataGrid-rowBorderColor': theme.palette.grey[300],
            '.MuiTablePagination-toolbar': {overflow: 'hidden'},
            '.MuiDataGrid-footerContainer': {borderTop: `1px solid ${theme.palette.grey[300]}`},
            cursor: 'pointer'
          }}
        />
      </Stack>
    </Paper>
  );
};

export default ResidentsTable;
