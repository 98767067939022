import React, {useEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import PropTypes from 'prop-types';

import {Box, Button, IconButton, Stack, useTheme} from '@mui/material';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {fontAwesomeIcons} from '../../../../utils/icons';
import {getOpenItem} from '../../../../../app/store/reducers/menu';
import {useEditAlertsLikeMutation} from '../../../../../features/coreApi/metricsSlice';
import {useNavigate, useParams} from 'react-router-dom';

const AlertComponent = ({item}) => {
  const theme = useTheme();
  const params = useParams();
  const navigate = useNavigate();
  const [updateLike, {data, isSuccess}] = useEditAlertsLikeMutation();
  const openItem = useSelector(getOpenItem);

  const [itemData, setItemData] = useState(item);
  const [isThumbUp, setIsThumbUp] = useState(item.thumbs_up);
  const [isThumbDown, setIsThumbDown] = useState(item.thumbs_down);

  useEffect(() => {
    if (isSuccess) {
      const newData = {
        ...itemData,
        thumbs_up: data.thumbs_up,
        thumbs_down: data.thumbs_down
      };
      setItemData(newData);
    }
  }, [isSuccess]);

  const handleIconClick = (id, likeType, isClicked) => {
    updateLike({id, likeType, isClicked});

    if (likeType === 'like') {
      setIsThumbUp(isClicked);
      if (isClicked) {
        setIsThumbDown(false);
      }
    } else {
      setIsThumbDown(isClicked);
      if (isClicked) {
        setIsThumbUp(false);
      }
    }
  };

  const rec = item.recommendation.split('**').map((text, index) => {
    if (index % 2 === 0) {
      return <span key={index}>{text}</span>;
    } else {
      return (
        <span key={index} style={{color: theme.palette.secondary.main, fontWeight: 500}}>
          {text}
        </span>
      );
    }
  });

  const handlePropertyLink = (sfid, name) => {
    const path = `/account/${params.account_sfid}/community/${sfid}`;
    navigate(path);
  };

  return (
    <Box
      sx={{
        width: '400px',
        height: '100%',
        flexShrink: 0,
        mr: 2,
        border: `1px solid ${theme.palette.secondary.main}`,
        borderRadius: 2,
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      {/* <Stack direction={'row'} columnGap={1} justifyContent={'flex-end'} sx={{padding: 1}}>
        <IconButton
          sx={{borderRadius: '100%', bgcolor: theme.palette.grey[100], width: '25px', height: '25px'}}
          onClick={() => handleIconClick(item.property_sfid, 'like', !isThumbUp)}
        >
          <FontAwesomeIcon
            icon={fontAwesomeIcons.faThumbsUp}
            style={{width: '15px', height: '15px'}}
            color={isThumbUp ? theme.palette.secondary.main : 'default'}
          />
        </IconButton>
        <IconButton
          sx={{borderRadius: '100%', width: '25px', height: '25px', bgcolor: theme.palette.grey[100]}}
          onClick={() => handleIconClick(item.property_sfid, 'dislike', !isThumbDown)}
        >
          <FontAwesomeIcon
            icon={fontAwesomeIcons.faThumbsDown}
            style={{width: '15px', height: '15px'}}
            color={isThumbDown ? theme.palette.secondary.main : 'default'}
          />
        </IconButton>
      </Stack> */}
      <Box sx={{flex: 1, overflow: 'auto', textAlign: 'center', p: 2, pt: 4, pb: 4}}>{rec}</Box>
      {openItem == 'portfolio' && (
        <Button
          variant="contained"
          color="secondary"
          sx={{width: '100%', borderRadius: 1.5, borderTopLeftRadius: 0, borderTopRightRadius: 0}}
          onClick={() => handlePropertyLink(item.property_sfid, item.property_name)}
        >
          Go to property
        </Button>
      )}
    </Box>
  );
};

AlertComponent.propTypes = {
  item: PropTypes.object.isRequired
};

const HorizontalList = ({items}) => {
  const scrollRef = useRef(null);
  const [canScroll, setCanScroll] = useState(false);

  useEffect(() => {
    const checkScroll = () => {
      if (scrollRef.current) {
        const {scrollWidth, clientWidth} = scrollRef.current;
        setCanScroll(scrollWidth > clientWidth);
      }
    };

    checkScroll();
    window.addEventListener('resize', checkScroll);
    return () => window.removeEventListener('resize', checkScroll);
  }, []);

  const scroll = (scrollOffset) => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({left: scrollOffset, behavior: 'smooth'});
    }
  };

  return (
    <Box columnGap={1} sx={{display: 'flex', alignItems: 'center', overflow: 'hidden', width: '100%', height: '100%'}}>
      {canScroll && (
        <IconButton onClick={() => scroll(-288)} sx={{borderRadius: '100%', width: '30px', height: '30px', padding: 0}}>
          <FontAwesomeIcon icon={fontAwesomeIcons.faAngleLeft} />
        </IconButton>
      )}
      <Box
        ref={scrollRef}
        sx={{
          display: 'flex',
          flexWrap: 'nowrap',
          overflowX: 'auto',
          overflowY: 'hidden',
          height: '100%',
          scrollbarWidth: 'none', // For Firefox
          '&::-webkit-scrollbar': {
            display: 'none' // For Chrome, Safari, and Opera
          }
        }}
      >
        {[...items].map((item) => (
          <AlertComponent key={item.id} item={item} />
        ))}
      </Box>
      {canScroll && (
        <IconButton onClick={() => scroll(288)} sx={{borderRadius: '100%', width: '30px', height: '30px', padding: 0}}>
          <FontAwesomeIcon icon={fontAwesomeIcons.faAngleRight} />
        </IconButton>
      )}
    </Box>
  );
};

HorizontalList.propTypes = {
  items: PropTypes.array.isRequired
};

export default HorizontalList;
