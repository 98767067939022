import React from 'react';
import PropTypes from 'prop-types';

import {Divider, Paper, Stack, Tooltip, tooltipClasses, Typography, useTheme} from '@mui/material';

import {monthsByIdxFull, monthsByName} from '../../constants/dateConstants';
import {styled} from '@mui/system';

export const CustomFinancialChartTooltip = ({active, payload}) => {
  const theme = useTheme();
  let value = 0;
  let name = '';

  if (active && payload?.length) {
    value = Math.round(payload[0].value).toLocaleString('en-US');
    const strDate = payload[0].payload.name;
    const fullStrMonthIdx = monthsByName[strDate.split("'")[0]];
    const fullStrMonth = monthsByIdxFull[fullStrMonthIdx];
    const fullStrYear = '20' + strDate.split("'")[1];
    name = `${fullStrMonth} ${fullStrYear}`;
  }

  return (
    <Paper
      sx={{
        padding: 1,
        pt: 1,
        minWidth: '250px',
        borderRadius: '6px',
        border: `0.5px solid ${theme.palette.grey[250]}`
      }}
    >
      <Stack direction="column" rowGap={1} sx={{width: '100%'}} divider={<Divider />}>
        <Typography sx={{width: '100%', fontSize: '0.75rem', textAlign: 'center'}}>{name}</Typography>
        <Stack direction="row" columnGap={1}>
          <Typography sx={{fontSize: '0.75rem'}}>Financial unlocks:</Typography>
          <Typography sx={{fontSize: '0.75rem', fontWeight: 600}}>{value}</Typography>
        </Stack>
      </Stack>
    </Paper>
  );
};

CustomFinancialChartTooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.array
};

export const renderCustomLegendText = (value, theme) => {
  return (
    <span className="text-color-class" style={{fontFamily: 'Poppins, sans-serif', color: theme.palette.grey[600]}}>
      {value}
    </span>
  );
};

export const StyledCustomTooltip = styled(({className, ...props}) => <Tooltip {...props} classes={{popper: className}} />)(({theme}) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.blue[100],
    border: `1px solid ${theme.palette.blue[100]}`,
    color: theme.palette.text.primary,
    padding: 24,
    borderRadius: 8,
    width: '100%',
    maxWidth: 1000,
    minWidth: 100
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.blue[100]
  }
}));
