import React, {useMemo, Fragment, lazy} from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

import {formatOtherOffers} from '../HomeTabs/Today/Campaign/utils';
import {activeOffersColors, pastOffersColors, upcomingOffersColors} from '../../constants/offersConstants';

import Loadable from '../Loadable';

const OfferTimeline = Loadable(lazy(() => import('./OffersTimeline')));
const OfferPlanner = Loadable(lazy(() => import('./OffersPlanner')));

const OffersMainComponent = ({campaign}) => {
  const offers = useMemo(() => {
    if (campaign) {
      const formattedOtherOffers = [];
      const newActions = [...campaign.all_actions].filter((ele) => !ele.id.includes('legacy-action'));

      for (const ele of newActions) {
        const isLegacy = !('goal_id' in ele);
        const offers = [...ele.offers]
          .filter((e) => e.reward_amount)
          .map((e) => ({...e, action_name: ele.name, isLegacy, campaign_status: campaign.status}));
        const actionOffers = [...offers].map((e) => formatOtherOffers(e)).filter((e) => e);
        formattedOtherOffers.push(...actionOffers);
      }

      return [...formattedOtherOffers];
    }
    return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify({...campaign})]);

  const offersObj = useMemo(() => {
    const pastOffers = [];
    const currentOffers = [];
    const futureOffers = [];

    for (const offer of offers) {
      const startDate = dayjs(offer.startDate).startOf('day');
      const endDate = dayjs(offer.endDate).endOf('day');
      const today = dayjs();

      if (startDate && endDate) {
        if (today.isSameOrAfter(startDate) && today.isSameOrBefore(endDate)) {
          currentOffers.push(offer);
        } else if (today.isBefore(startDate)) {
          futureOffers.push(offer);
        } else {
          pastOffers.push(offer);
        }
      } else if (startDate) {
        if (today.isSameOrAfter(startDate)) {
          currentOffers.push(offer);
        } else if (today.isBefore(startDate)) {
          futureOffers.push(offer);
        }
      }
    }

    const sortedPastOffers = [...pastOffers].sort((a, b) => b.startDate - a.startDate).map((ele) => ({...ele, color: pastOffersColors[0]}));
    const sortedCurrentOffers = [...currentOffers]
      .sort((a, b) => b.startDate - a.startDate)
      .map((ele, idx) => ({...ele, color: activeOffersColors[idx % activeOffersColors.length]}));
    const sortedFutureOffers = [...futureOffers]
      .sort((a, b) => a.startDate - b.startDate)
      .map((ele) => ({...ele, color: upcomingOffersColors[0]}));

    return {
      pastOffers: sortedPastOffers,
      currentOffers: sortedCurrentOffers,
      futureOffers: sortedFutureOffers
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(offers)]);

  if (offersObj.pastOffers || offersObj.currentOffers || offersObj.futureOffers) {
    return (
      <Fragment>
        <OfferTimeline offersObj={offersObj} campaign={campaign} />
        <OfferPlanner offersObj={offersObj} campaign={campaign} />
      </Fragment>
    );
  } else {
    return <div />;
  }
};

OffersMainComponent.propTypes = {
  campaign: PropTypes.object
};

export default OffersMainComponent;
