import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';

import {Stack} from '@mui/material';

import {getRandomValue, GlanceConcessionColumns, valueGetter} from '../../../../../constants/tableColumns';
import {getCommunityLoadingStatus} from '../../../../../../app/store/reducers/loading';
import {ExportToolBarWithSearch} from '../../../../@extended/DataGridTables';
import {getCommunities} from '../../../../../../app/store/reducers/entities';
import {StyledTable} from '../../../../@extended/CustomTables';

import TableBodyCell from '../../../../@extended/TableBodyCell';

const ConcessionGlance = ({data}) => {
  const communities = useSelector(getCommunities);
  const communitiesLoadingStatus = useSelector(getCommunityLoadingStatus);

  const columns = useMemo(
    () =>
      GlanceConcessionColumns.map((column) => ({
        ...column,
        field: column.id,
        headerName: column.label,
        renderCell: (params) => <TableBodyCell column={column} params={params} />,
        renderEditCell: (params) => <TableBodyCell column={column} params={params} />,
        valueFormatter: (params) => valueGetter(params, column)
      })),
    []
  );

  const performers = useMemo(() => {
    const obj = [];

    for (const d of data) {
      const performerId = getRandomValue();
      const concession = `${d.current_average_market_concession}% of rent`;
      const offer = `${d.average_existing_stake_plus_offer}% of rent`;

      const community = communities.find((c) => c.sfid === d.property_sfid);
      let usState = community?.full_address__statecode__s;

      if (!usState && community?.address__c) {
        usState = community?.address__c.split(' ');
        usState = usState[usState.length - 2];
      }

      const o = {
        ...d,
        id: performerId,
        property_name: community?.name || '',
        current_average_market_concession: concession,
        current_average_stake_plus_offer: offer,
        us_state: usState,
        total_units: d.total_units || community?.total_units__c || 0
      };
      obj.push(o);
    }

    return obj;
  }, [data, communities]);

  const rowsComponent = useMemo(() => {
    if (performers?.length > 0) {
      return (
        <Stack direction={'column'} rowGap={1}>
          <StyledTable
            rows={performers || []}
            columns={columns}
            loading={communitiesLoadingStatus.isLoading}
            pagination
            pageSizeOptions={[10, 20, 50, 100]}
            slots={{toolbar: ExportToolBarWithSearch}}
            initialState={{
              pagination: {paginationModel: {pageSize: 10}},
              sorting: {
                sortModel: [{field: 'property_name', sort: 'asc'}]
              }
            }}
            autoHeight
            density="compact"
            hideFooter={performers.length < 10}
            rowHeight={40}
            disableRowSelectionOnClick
            getRowId={(row) => row.id}
          />
        </Stack>
      );
    } else {
      return null;
    }
  }, [performers]);

  return <Stack direction={'column'}>{rowsComponent}</Stack>;
};

ConcessionGlance.propTypes = {
  data: PropTypes.array.isRequired
};

export default ConcessionGlance;
