import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {PDFDownloadLink} from '@react-pdf/renderer';

import {Button, Stack} from '@mui/material';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import EsgReportPdf from './EsgReportPdf';
import {fontAwesomeIcons} from '../../../utils/icons';

const EsgDownloadButton = ({pdfData}) => {
  const fileName = `Stake_Esg_Report_${pdfData.name.replace(' ', '_')}.pdf`;
  return (
    <Fragment>
      <Button variant="contained" color="secondary" sx={{padding: '8px 24px'}}>
        <Stack direction="row" spacing={1} alignItems="center">
          <PDFDownloadLink
            document={<EsgReportPdf pdfData={pdfData} />}
            style={{color: 'white', textDecoration: 'none'}}
            fileName={fileName}
          >
            {({loading}) => (loading ? 'Loading pdf...' : 'Download')}
          </PDFDownloadLink>
          <FontAwesomeIcon icon={fontAwesomeIcons['faCircleArrowDown']} />
        </Stack>
      </Button>
      {/* debug pdf file */}
      {/* <PDFViewer>
                <EsgReportPdf pdfData={pdfData} />
            </PDFViewer> */}
    </Fragment>
  );
};

EsgDownloadButton.propTypes = {
  pdfData: PropTypes.object
};

export default EsgDownloadButton;
