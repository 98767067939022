import React from 'react';
import PropTypes from 'prop-types';

import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarQuickFilter
} from '@mui/x-data-grid-pro';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {fontAwesomeIcons} from '../../utils/icons';
import {Box, Button, InputAdornment, Stack, Typography, useTheme} from '@mui/material';
import {StyledGridOverlay} from './CustomTables';

export const ExportToolBarWithSearch = ({handleAdd, isAddOn, ...other}) => {
  const theme = useTheme();

  const searchProps = {
    variant: 'outlined',
    size: 'small',
    sx: {
      width: '300px',
      color: theme.palette.grey[600]
    }
  };

  return (
    <GridToolbarContainer
      sx={{
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
        justifyContent: 'space-between',
        alignItems: 'center',
        p: '16px 24px',
        '& button': {height: '35px', pt: 0, pb: 0}
      }}
    >
      <Stack direction={'row'} spacing={2}>
        <GridToolbarColumnsButton slotProps={{button: {variant: 'outlined', color: 'primary'}}} />
        <GridToolbarFilterButton slotProps={{button: {variant: 'outlined', color: 'primary'}}} />
        <GridToolbarQuickFilter
          {...searchProps}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" sx={{paddingLeft: '8px'}}>
                <FontAwesomeIcon icon={fontAwesomeIcons['faMagnifyingGlass']} />
              </InputAdornment>
            )
          }}
        />
      </Stack>
      <Stack direction={'row'} spacing={2}>
        {isAddOn && (
          <Button
            variant="contained"
            color="secondary"
            endIcon={<FontAwesomeIcon icon={fontAwesomeIcons.faCirclePlus} style={{width: '15px', height: '15px'}} />}
            onClick={handleAdd}
          >
            Add
          </Button>
        )}
        <GridToolbarExport slotProps={{button: {variant: 'contained', color: 'secondary'}}} />
      </Stack>
    </GridToolbarContainer>
  );
};

export const ExportButton = ({onClick}) => (
  <Button onClick={onClick} variant="contained">
    Export
  </Button>
);

ExportButton.propTypes = {
  onClick: PropTypes.func
};

export const ExportToolbar = () => {
  const theme = useTheme();

  return (
    <GridToolbarContainer
      sx={{
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        p: '8px',
        '& button': {height: '30px', pt: 0, pb: 0, border: '1px solid white'}
      }}
    >
      <GridToolbarExport slotProps={{button: {variant: 'outlined', color: 'primary'}}} />
    </GridToolbarContainer>
  );
};

export const CustomNoRowsOverlay = () => {
  return (
    <StyledGridOverlay>
      <Box sx={{mt: 2}}>
        <Typography variant="h6" color="text.subtitle">
          Processing
        </Typography>
      </Box>
    </StyledGridOverlay>
  );
};
