import React from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {Box, IconButton} from '@mui/material';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {fontAwesomeIcons} from '../../../../commons/utils/icons';
import {getDashboardAccount} from '../../../../app/store/reducers/dashboard';
import {useNavigate} from 'react-router-dom';

const Settings = () => {
  const navigate = useNavigate();
  const dashboardAccount = useSelector(getDashboardAccount);

  const handleToggle = (e) => {
    e.preventDefault();
    e.stopPropagation();
    navigate(`/account/${dashboardAccount.sfid}/settings`);
  };

  return (
    <Box sx={{flexShrink: 0, pt: '6px'}}>
      <IconButton
        disableRipple
        color="secondary"
        sx={{color: 'text.primary', bgcolor: 'transparent'}}
        aria-label="open settings"
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <FontAwesomeIcon icon={fontAwesomeIcons.faGear} size="lg" />
      </IconButton>
    </Box>
  );
};

export default Settings;
