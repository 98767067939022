import React, {useMemo, useState} from 'react';
import PropTypes from 'prop-types';

import {Box, Divider, Paper, Stack, Tab, Typography} from '@mui/material';
import {TabContext, TabList, TabPanel} from '@mui/lab';

import ConcessionGlance from './ConcessionGlance';
import DaysOnMarketGlance from './DaysOnMarketGlance';
import DelinquencyGlance from './DeinquencyGlance';
import {useSelector} from 'react-redux';
import {getIsStakeAdmin, getPropertyPermissions, getTeamMemberRole} from '../../../../../../app/store/reducers/session';
import {getDashboardCommunities} from '../../../../../../app/store/reducers/dashboard';

const Glance = ({data, tabs}) => {
  const [value, setValue] = useState('0');
  const isStakeAdmin = useSelector(getIsStakeAdmin);
  const teamMemberRole = useSelector(getTeamMemberRole);
  const isAdministrator = teamMemberRole === 'Administrator';
  const propertyPermissions = useSelector(getPropertyPermissions);
  const communities = useSelector(getDashboardCommunities);

  const handleChange = (event, newValue) => {
    event.preventDefault();
    event.stopPropagation();

    setValue(newValue);
  };

  const glanceData = useMemo(() => {
    if (isStakeAdmin || isAdministrator) {
      const newData = {};
      const cSfids = communities.map((c) => c.sfid);
      for (const key in data) {
        const valueList = data[key];
        const filteredData = valueList.filter((value) => cSfids.includes(value.property_sfid));
        newData[key] = filteredData;
      }
      return newData;
    } else {
      const newData = {};
      for (const key in data) {
        const valueList = data[key];
        const filteredData = valueList.filter((value) => propertyPermissions.includes(value.property_sfid));
        newData[key] = filteredData;
      }

      return newData;
    }
  }, [data, isStakeAdmin, isAdministrator, propertyPermissions, communities]);

  if (!tabs || !tabs.length) {
    return null;
  }

  return (
    <Paper sx={{borderRadius: 2, boxShadow: 'none'}}>
      <Stack direction={'column'} divider={<Divider />}>
        <Stack direction={'column'} sx={{p: 2}}>
          <Typography variant="h5" fontWeight="fontWeightMedium">
            At a glance
          </Typography>
          <Typography variant="body2" color={'text.subtitle'}>
            For last 3 months
          </Typography>
        </Stack>
        <Box sx={{width: '100%', height: '100%', bgcolor: 'transparent', borderRadius: 2}}>
          <TabContext value={value}>
            <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
              <TabList
                onChange={handleChange}
                aria-label="at a glance tabs"
                indicatorColor="secondary"
                textColor="secondary"
                variant="scrollable"
                scrollButtons="auto"
                sx={{
                  '.MuiTab-root': {
                    fontWeight: 'fontWeightRegular'
                  }
                }}
              >
                {tabs.map((tab, index) => (
                  <Tab key={tab} label={tab} value={index.toString()} />
                ))}
              </TabList>
            </Box>
            {tabs.map((tab, index) => {
              let tabComponent = null;

              switch (tab) {
                case 'Concession Comps':
                  tabComponent = <ConcessionGlance data={glanceData['Concession Comps']} />;
                  break;
                case 'Days on Market':
                  tabComponent = <DaysOnMarketGlance data={glanceData['Days on Market']} />;
                  break;
                case 'Delinquency':
                  tabComponent = <DelinquencyGlance data={glanceData['Delinquency']} />;
                  break;
                default:
                  break;
              }

              if (!tabComponent) {
                return null;
              } else {
                return (
                  <TabPanel key={tab} value={index.toString()} sx={{p: '16px 0'}}>
                    {tabComponent}
                  </TabPanel>
                );
              }
            })}
          </TabContext>
        </Box>
      </Stack>
    </Paper>
  );
};

Glance.propTypes = {
  data: PropTypes.object,
  tabs: PropTypes.array
};

export default Glance;
