import React, {useEffect, useState} from 'react';

import {Box, CircularProgress, Fade, Typography} from '@mui/material';

export const ComplexLoadingBox = ({isLoading = false, loadingDuration = 20000}) => {
  const [loadText, setLoadText] = useState('Loading...');
  const [fadeIn, setFadeIn] = useState(true);

  useEffect(() => {
    if (!isLoading) return; // Stop updates if not loading

    const stageDuration = loadingDuration / 5; // Split loading into 5 stages
    const variant = Math.floor(Math.random() * 3) + 1; // Randomize loading text

    const updateText = (text) => {
      setFadeIn(false); // Trigger fade-out
      setTimeout(() => {
        setLoadText(text); // Update text
        setFadeIn(true); // Trigger fade-in
      }, 300); // Short delay for smooth fading
    };

    let timer1, timer2, timer3, timer4, timer5;

    if (variant === 1) {
      timer1 = setTimeout(() => updateText('Brewing the coffee...'), stageDuration);
      timer2 = setTimeout(() => updateText('Grinding the beans...'), stageDuration * 2);
      timer3 = setTimeout(() => updateText('Pouring the milk...'), stageDuration * 3);
      timer4 = setTimeout(() => updateText('Adding the final touches...'), stageDuration * 4);
      timer5 = setTimeout(() => updateText('Serving it hot and fresh...'), stageDuration * 5);
    } else if (variant === 2) {
      timer1 = setTimeout(() => updateText('Packing the bags...'), stageDuration);
      timer2 = setTimeout(() => updateText('Double-checking the checklist...'), stageDuration * 2);
      timer3 = setTimeout(() => updateText('Loading the car...'), stageDuration * 3);
      timer4 = setTimeout(() => updateText('Grabbing snacks for the road...'), stageDuration * 4);
      timer5 = setTimeout(() => updateText('Hitting the road...'), stageDuration * 5);
    } else {
      timer1 = setTimeout(() => updateText('Turning on the oven...'), stageDuration);
      timer2 = setTimeout(() => updateText('Prepping the ingredients...'), stageDuration * 2);
      timer3 = setTimeout(() => updateText('Mixing everything together...'), stageDuration * 3);
      timer4 = setTimeout(() => updateText('Baking to perfection...'), stageDuration * 4);
      timer5 = setTimeout(() => updateText('Plating it up...'), stageDuration * 5);
    }

    return () => {
      clearTimeout(timer1);
      clearTimeout(timer2);
      clearTimeout(timer3);
      clearTimeout(timer4);
      clearTimeout(timer5);
    };
  }, [isLoading, loadingDuration]);

  return (
    <Box width="100%" height={200} display="flex" flexDirection="column" justifyContent="center" alignItems="center" gap={2}>
      <CircularProgress size={20} thickness={1} color="grey" />
      <Fade in={fadeIn} timeout={500}>
        <Typography variant="body2" color="#bfbfbf" style={{animation: 'fade 1.5s infinite'}}>
          {loadText}
        </Typography>
      </Fade>
    </Box>
  );
};

const LoadingBox = () => {
  return (
    <Box width="100%" height={200} display="flex" justifyContent="center" alignItems="center">
      <CircularProgress size={20} thickness={1} color="grey" />
    </Box>
  );
};

export default LoadingBox;
