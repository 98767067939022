import React, {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';

import {Paper, Stack, Typography} from '@mui/material';

import {useGetAccountAlertsQuery} from '../../../../../features/coreApi/apiSlice';
import {getIsStakeAdmin, getPropertyPermissions, getTeamMemberRole} from '../../../../../app/store/reducers/session';
import HorizontalList from './HorizontalList';
import LoadingBox from '../../../@extended/LoadingBox';
import ErrorBox from '../../../@extended/ErrorBox';

const AlertsComponent = () => {
  const params = useParams();
  const isStakeAdmin = useSelector(getIsStakeAdmin);
  const teamMemberRole = useSelector(getTeamMemberRole);
  const isAdministrator = teamMemberRole === 'Administrator';
  const propertyPermissions = useSelector(getPropertyPermissions);

  const {
    data: accountAlerts,
    isSuccess: isAccSuccess,
    isFetching: isAccFetching,
    isLoading: isAccLoading,
    isError: isAccError
  } = useGetAccountAlertsQuery(params?.account_sfid, {skip: !params?.account_sfid});

  const alertsDataComponent = useMemo(() => {
    if (isAccError) {
      return <ErrorBox />;
    } else if (isAccLoading || isAccFetching) {
      return <LoadingBox />;
    } else if (accountAlerts && accountAlerts.length > 0 && isAccSuccess) {
      const alerts = accountAlerts
        .filter((alert) => alert.recommendation)
        .sort((a, b) => b.recommendation.length - a.recommendation.length);

      if (params?.community_sfid && alerts) {
        const communityAlerts = alerts.filter((alert) => alert.property_sfid === params.community_sfid);
        return <HorizontalList items={communityAlerts || []} />;
      } else {
        if (isStakeAdmin || isAdministrator) {
          return <HorizontalList items={alerts || []} />;
        } else if (alerts) {
          const filteredAlerts = alerts.filter((alert) => propertyPermissions.includes(alert.property_sfid));
          return <HorizontalList items={filteredAlerts || []} />;
        }
      }

      return (
        <Paper sx={{p: 2, borderRadius: 2, boxShadow: 'none'}}>
          <Stack direction="column" rowGap={1} sx={{width: '100%'}}>
            <Typography variant="h5" fontWeight={'fontWeightMedium'} sx={{pb: 2}}>
              Recommendations
            </Typography>
          </Stack>
        </Paper>
      );
    }

    return null;
  }, [params, isAccSuccess, isAccLoading, accountAlerts, isAccError, isAccFetching, isStakeAdmin, isAdministrator, propertyPermissions]);

  return alertsDataComponent;
};

export default AlertsComponent;
