import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import {Box, linearProgressClasses, Stack, Typography} from '@mui/material';

import {StyledBarRating} from '../../../@extended/StyledRating';

const ReviewsBarRating = ({progress, title}) => {
  return (
    <Stack direction="column" rowGap={1} justifyContent="center" alignItems="center">
      <Typography variant="subtitle1" fontWeight={'fontWeightRegular'} color={'text.subtitle'}>
        {progress}%
      </Typography>
      <StyledBarRating
        variant="determinate"
        value={progress}
        sx={{
          [`& .${linearProgressClasses.bar}`]: {
            transform: `translateY(${-progress}%)!important`
          }
        }}
      />
      <Typography variant="subtitle1" fontWeight={'fontWeightRegular'} color={'text.subtitle'}>
        {title}
      </Typography>
    </Stack>
  );
};

ReviewsBarRating.propTypes = {
  progress: PropTypes.number,
  title: PropTypes.string
};

const ratingBars = [
  {label: '1 star', value: 1, progress: 0},
  {label: '2 stars', value: 2, progress: 0},
  {label: '3 stars', value: 3, progress: 0},
  {label: '4 stars', value: 4, progress: 0},
  {label: '5 stars', value: 5, progress: 0}
];

const ReviewsBars = ({reviewsObj}) => {
  const [bars, setBars] = useState(ratingBars);

  useEffect(() => {
    if (reviewsObj) {
      const newBars = [...ratingBars].map((b) => {
        const {value} = b;
        if (reviewsObj[value] && reviewsObj[0].length > 0) {
          const percent = Math.round((reviewsObj[value].length / reviewsObj[0].length) * 100);
          return {
            ...b,
            progress: percent
          };
        } else {
          return b;
        }
      });

      setBars(newBars);
    }
  }, [reviewsObj]);

  return (
    <Box
      sx={{
        display: 'flex',
        height: 'auto'
      }}
    >
      <Stack direction="row" columnGap={1}>
        {bars.length > 0 && bars.map((b) => <ReviewsBarRating key={`bar-raring-${b.value}`} progress={b.progress} title={b.label} />)}
      </Stack>
    </Box>
  );
};

ReviewsBars.propTypes = {
  reviewsObj: PropTypes.object,
  filterValue: PropTypes.number
};

export default ReviewsBars;
