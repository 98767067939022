import React, {useMemo} from 'react';
import PropTypes from 'prop-types';

import {Typography, Stack, Chip, Paper} from '@mui/material';

const InsightItem = ({entry}) => {
  return (
    <Stack direction="column">
      <Stack direction="row" justifyContent="space-between" columnGap={1}>
        <Stack direction="column" rowGap={1}>
          <Typography variant="h5" fontWeight={'fontWeightRegular'}>
            {entry.title ?? ''}
          </Typography>
          <Typography variant="subtitle1" fontWeight={'fontWeightRegular'} color={'text.subtitle'}>
            {entry.description}
          </Typography>
        </Stack>
        <Chip
          key={`chip-${entry.title}`}
          variant="filled"
          label={`Top ${entry.amount}%`}
          sx={{background: 'rgba(0, 203, 82, 0.1)', color: entry.color}}
        />
      </Stack>
    </Stack>
  );
};

InsightItem.propTypes = {
  entry: PropTypes.object
};

const EngagementInsights = ({engagementInsights}) => {
  const items = useMemo(() => {
    return Array.isArray(engagementInsights) ? engagementInsights : [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(engagementInsights)]);

  if (items.length == 0) return <></>;

  return (
    <Paper sx={{p: 2, borderRadius: 2, boxShadow: 'none'}}>
      <Stack direction="column" sx={{width: '100%'}} rowGap={2}>
        <Stack direction={'column'}>
          <Typography variant="h5" fontWeight={'fontWeightMedium'}>
            {engagementInsights.title ?? 'Insights'}
          </Typography>
          <Typography variant="subtitle1" fontWeight={'fontWeightRegular'} color={'text.subtitle'}>
            {engagementInsights.subtitle ?? 'Compare your residents to the Stake network.'}
          </Typography>
        </Stack>
        <Stack
          direction="row"
          sx={{
            width: items.length > 1 ? '100%' : '50%'
          }}
          columnGap={1.5}
        >
          {items.map((entry, index) => (
            <Paper
              key={index}
              style={{
                width: '100%',
                borderRadius: '8px',
                boxShadow: 'none',
                padding: '16px'
              }}
            >
              <InsightItem entry={entry} />
            </Paper>
          ))}
        </Stack>
      </Stack>
    </Paper>
  );
};

EngagementInsights.propTypes = {
  engagementInsights: PropTypes.array
};

export default EngagementInsights;
