import React, {useEffect, useMemo} from 'react';
import {useSelector} from 'react-redux';

import {Paper, Stack} from '@mui/material';

import EsgComponent from './EsgComponent';
import {getOpenItem} from '../../../../app/store/reducers/menu';
import {getDashboardAccount, getDashboardCommunities, getDashboardCommunity} from '../../../../app/store/reducers/dashboard';
import {useLazyGetAccountESGQuery, useLazyGetCommunityESGQuery} from '../../../../features/coreApi/metricsSlice';
import {getIsStakeAdmin, getTeamMemberRole} from '../../../../app/store/reducers/session';

const EsgTab = () => {
  const openItem = useSelector(getOpenItem);
  const dashboardAccount = useSelector(getDashboardAccount);
  const dashboardCommunity = useSelector(getDashboardCommunity);
  const userRole = useSelector(getTeamMemberRole);
  const isStakeAdmin = useSelector(getIsStakeAdmin);
  const dashboardCommunities = useSelector(getDashboardCommunities);

  const [
    fetchAccountsEsg,
    {data: accountEsg = {}, isLoading: isAccountEsgLoading, isFetching: isAccountEsgFetching, isSuccess: isAccountEsgSuccess}
  ] = useLazyGetAccountESGQuery();

  const [
    fetchCommunityEsg,
    {data: communityEsg = {}, isLoading: isCommunityEsgLoading, isFetching: isCommunityEsgFetching, isSuccess: isCommunityEsgSuccess}
  ] = useLazyGetCommunityESGQuery();

  useEffect(() => {
    if (dashboardAccount.sfid && dashboardAccount.sfid !== 'all') {
      let communitiesAccess = 'all';
      if (userRole != 'Administrator' && !isStakeAdmin) {
        const communitiesSfids = dashboardCommunities.map((community) => community.sfid);
        communitiesAccess = communitiesSfids.join(';');
      }
      fetchAccountsEsg({sfid: dashboardAccount.sfid, communities: communitiesAccess});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardAccount, userRole, isStakeAdmin, dashboardCommunities]);

  useEffect(() => {
    if (dashboardCommunity.sfid && dashboardCommunity.sfid !== 'all') {
      fetchCommunityEsg(dashboardCommunity.sfid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardCommunity]);

  const activeComponent = useMemo(() => {
    if (openItem && openItem == 'portfolio' && dashboardCommunity.sfid) {
      return <EsgComponent data={accountEsg} isLoading={isAccountEsgFetching || isAccountEsgLoading} isSuccess={isAccountEsgSuccess} />;
    } else if (openItem && openItem == 'communities' && dashboardCommunity.sfid && dashboardCommunity.sfid !== 'all') {
      return (
        <EsgComponent data={communityEsg} isLoading={isCommunityEsgFetching || isCommunityEsgLoading} isSuccess={isCommunityEsgSuccess} />
      );
    } else {
      return <EsgComponent data={null} isLoading={false} isSuccess={false} />;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    openItem,
    dashboardAccount,
    dashboardCommunity,
    isAccountEsgFetching,
    isAccountEsgLoading,
    isAccountEsgSuccess,
    isCommunityEsgFetching,
    isCommunityEsgLoading,
    isCommunityEsgSuccess
  ]);

  return (
    <Paper sx={{p: 2, borderRadius: 2, boxShadow: 'none'}}>
      <Stack direction="column" rowGap={2} sx={{width: '100%'}}>
        <div>{activeComponent}</div>
      </Stack>
    </Paper>
  );
};

export default EsgTab;
