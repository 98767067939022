import React, {useMemo, useState} from 'react';
import PropTypes from 'prop-types';

import {Box, ButtonBase, Chip, Collapse, Stack, Typography, useTheme} from '@mui/material';
import {DataGridPro} from '@mui/x-data-grid-pro';
import {renderColumnCell} from '../../../@extended/TableBodyCell';
import {valueGetter} from '../../../../constants/tableColumns';

export const DaysOnMarketHeader = ({data}) => {
  const {title, description} = data;

  return (
    <Stack direction="column">
      <Typography variant="h5" fontWeight="fontWeightMedium">
        {title}
      </Typography>
      <Typography variant="subtitle2" color="text.subtitle" fontWeight={'fontWeightRegular'}>
        {description}
      </Typography>
      <Typography variant="subtitle2" color="text.subtitle" fontWeight={'fontWeightRegular'}>
        This data is sourced from publicly available online listings.
      </Typography>
    </Stack>
  );
};

DaysOnMarketHeader.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string
  })
};

export const DaysOnMarketCard = ({data}) => {
  const theme = useTheme();
  const {description, table_data} = data;
  const [isExpaned, setExpaned] = useState(false);

  const handleExpanse = (e) => {
    e.preventDefault();

    setExpaned(!isExpaned);
  };

  const formattedColumns = useMemo(
    () =>
      table_data.columns.map((col) => ({
        ...col,
        renderCell: (params) => renderColumnCell(params),
        valueFormatter: (params) => valueGetter(params, col)
      })),
    [table_data.columns]
  );

  return (
    <>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="subtitle2" color="text.subtitle" fontWeight={'fontWeightRegular'}>
          {description}
        </Typography>
        <ButtonBase disableRipple onClick={handleExpanse}>
          <Typography variant="subtitle2" color="text.subtitle" fontWeight={'fontWeightRegular'} sx={{textDecoration: 'underline'}}>
            {isExpaned ? 'Hide comps' : 'Show comps'}
          </Typography>
        </ButtonBase>
      </Stack>
      <Collapse in={isExpaned}>
        <DataGridPro
          rows={table_data?.rows}
          columns={formattedColumns}
          initialState={{
            sorting: {
              sortModel: [{field: 'similarity_score', sort: 'desc'}]
            }
          }}
          density="compact"
          autoHeight
          rowHeight={60}
          disableRowSelectionOnClick
          hideFooter
          getRowId={(row) => row.id}
          sx={{
            fontSize: '1rem',
            border: `1px solid ${theme.palette.grey[300]}`,
            '& .MuiDataGrid-columnHeaderTitleContainer': {fontSize: '0.875rem'},
            '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus': {outline: 'none'},
            '--DataGrid-rowBorderColor': theme.palette.grey[300],
            '.MuiTablePagination-toolbar': {overflow: 'hidden'},
            '& .MuiDataGrid-toolbarContainer': {
              borderBottom: `1px solid ${theme.palette.grey[300]}`,
              height: '50px'
            },
            '& .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator': {
              display: 'none'
            },
            '& .theme--header': {
              color: theme.palette.grey[600],
              '& .MuiDataGrid-columnHeaderTitle': {
                fontWeight: 400
              }
            }
          }}
        />
      </Collapse>
    </>
  );
};

DaysOnMarketCard.propTypes = {
  data: PropTypes.shape({
    description: PropTypes.string,
    table_data: PropTypes.shape({
      columns: PropTypes.array,
      rows: PropTypes.array
    })
  })
};

export const DaysOnMarketSummary = ({data}) => {
  const theme = useTheme();

  const dataArray = useMemo(() => {
    return data.map((item) => {
      const {title, backgroundColor, fontColor, value} = item;

      return (
        <Box
          key={title.split(' ').join('-')}
          sx={{width: '100%', borderRadius: '8px', border: `1px solid ${theme.palette.grey[300]}`, p: 2}}
        >
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="h6" fontWeight={'fontWeightRegular'}>
              {title}
            </Typography>
            <Chip
              label={value}
              sx={{
                backgroundColor,
                color: fontColor,
                '& .MuiChip-label': {fontSize: 14, fontWeight: 500, overflow: 'visible'}
              }}
            />
          </Stack>
        </Box>
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <Stack direction="row" columnGap={1}>
      {dataArray.map((item) => item)}
    </Stack>
  );
};

DaysOnMarketSummary.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      backgroundColor: PropTypes.string,
      fontColor: PropTypes.string,
      value: PropTypes.string
    })
  )
};

export const DaysOnMarketTable = ({data}) => {
  const theme = useTheme();
  const formattedColumns = useMemo(
    () =>
      data.columns.map((col) => ({
        ...col,
        renderCell: (params) => renderColumnCell(params),
        valueFormatter: (params) => valueGetter(params, col)
      })),
    [data.columns]
  );

  const rows = useMemo(() => {
    return data.rows.map((row) => row);
  }, [data.rows]);

  return (
    <DataGridPro
      rows={rows}
      columns={formattedColumns}
      autoHeight
      rowHeight={60}
      disableRowSelectionOnClick
      hideFooter
      getRowId={(row) => row.id}
      density="compact"
      sx={{
        border: `1px solid ${theme.palette.grey[300]}`,
        '& .MuiDataGrid-columnHeaderTitleContainer': {fontSize: '0.875rem'},
        '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus': {outline: 'none'},
        '--DataGrid-rowBorderColor': theme.palette.grey[300],
        '.MuiTablePagination-toolbar': {overflow: 'hidden'},
        '& .MuiDataGrid-toolbarContainer': {
          borderBottom: `1px solid ${theme.palette.grey[300]}`,
          height: '50px'
        },
        '& .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator': {
          display: 'none'
        },
        '& .theme--header': {
          color: theme.palette.grey[600],
          '& .MuiDataGrid-columnHeaderTitle': {
            fontWeight: 400
          }
        }
      }}
    />
  );
};

DaysOnMarketTable.propTypes = {
  data: PropTypes.shape({
    columns: PropTypes.array,
    rows: PropTypes.array
  })
};
