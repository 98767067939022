import React from 'react';

import {Stack} from '@mui/material';

import Delinquency from './Metrics/Delinquency';

const AccountPerformance = () => {
  return (
    <Stack direction="column" rowGap={2} sx={{width: '100%'}}>
      <Delinquency />
    </Stack>
  );
};

export default AccountPerformance;
