import React from 'react';
import {useNavigate} from 'react-router-dom';
import {useSelector} from 'react-redux';

// material-ui
import {Box, ButtonBase} from '@mui/material';

// project import
import {getDashboardAccount} from '../../../app/store/reducers/dashboard';
import PositiveBlueLogo from '../../../assets/images/stake-logo-positive-blue-RGB.png';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => {
  const navigate = useNavigate();
  const dashboardAccount = useSelector(getDashboardAccount);

  const handleClick = () => {
    navigate(`/account/${dashboardAccount.sfid}`);
  };

  return (
    <Box
      sx={{
        width: 'fit-content',
        height: '100%',
        position: 'absolute',
        top: '29px',
        left: '40px'
      }}
    >
      <ButtonBase disableRipple onClick={handleClick}>
        <img src={PositiveBlueLogo} alt="stake-logo" width="20" />
      </ButtonBase>
    </Box>
  );
};

export default LogoSection;
