import {Box, styled} from '@mui/material';
import {DataGridPro} from '@mui/x-data-grid-pro';

export const StyledTable = styled(DataGridPro)(({theme}) => ({
  fontSize: '1rem',
  cursor: 'default',
  '--DataGrid-containerBackground': 'transparent',
  '--DataGrid-rowBorderColor': theme.palette.grey[300],
  '--unstable_DataGrid-headWeight': 400,
  '& .MuiDataGrid-cell': {padding: 0, paddingLeft: '24px'},
  '& .MuiDataGrid-columnHeader': {padding: 0, paddingLeft: '24px'},
  '& .MuiDataGrid-columnHeaderTitleContainer': {fontSize: '0.875rem', color: theme.palette.grey[600], padding: 0},
  '& .MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus': {outline: 'none'},
  '& .MuiDataGrid-columnHeader:focus-within': {outline: 'none'},
  '& .MuiDataGrid-cell:focus-within': {outline: 'none'},
  '.MuiTablePagination-toolbar': {overflow: 'hidden'},
  '.MuiDataGrid-footerContainer': {
    borderTop: `1px solid ${theme.palette.grey[300]}`,
    paddingRight: '24px'
  }
}));

export const StyledTableDefaultPadding = styled(DataGridPro)(({theme}) => ({
  fontSize: '1rem',
  cursor: 'default',
  '--DataGrid-containerBackground': 'transparent',
  '--DataGrid-rowBorderColor': theme.palette.grey[300],
  '--unstable_DataGrid-headWeight': 400,
  '& .MuiDataGrid-columnHeaderTitleContainer': {fontSize: '0.875rem', color: theme.palette.grey[600], padding: 0},
  '& .MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus': {outline: 'none'},
  '& .MuiDataGrid-columnHeader:focus-within': {outline: 'none'},
  '& .MuiDataGrid-cell:focus-within': {outline: 'none'},
  '.MuiTablePagination-toolbar': {overflow: 'hidden'},
  '.MuiDataGrid-footerContainer': {
    borderTop: `1px solid ${theme.palette.grey[300]}`,
    paddingRight: '24px'
  }
}));

export const StyledGridOverlay = styled('div')(({theme}) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  '& .no-rows-primary': {
    fill: '#3D4751',
    ...theme.applyStyles('light', {
      fill: '#AEB8C2'
    })
  },
  '& .no-rows-secondary': {
    fill: '#1D2126',
    ...theme.applyStyles('light', {
      fill: '#E8EAED'
    })
  }
}));
