import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

import {Box, Checkbox, Chip, FormControlLabel, IconButton, List, ListItem, Stack, Tooltip, Typography, useTheme} from '@mui/material';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {MoneyFormatCustomDisplay, NumericFormatCustomDisplay, PhoneFormatCustomDisplay} from './FormatDisplay';
import {fontAwesomeIcons} from '../../utils/icons';
import {StyledCustomTooltip} from './CustomTooltip';
import {StyledMarginWidthDividerSm} from './Divider';

const firstColumns = ['days_on_market', 'Delinquency', 'renewals_conversion'];

export const renderColumnCell = (params) => {
  const {field, row} = params;
  const isFormatted = row?.format ? true : false;
  const formatItem = isFormatted ? row.format?.item || row.format : null;
  const value = row[field];
  const fontWeight = row['fontWeight'];
  let innerTextComponent;

  if (!isFormatted || firstColumns.includes(field)) {
    innerTextComponent = (
      <Box display={'flex'} alignItems={'center'} sx={{height: '100%'}}>
        <Typography variant="body1" sx={{fontWeight}}>
          {value}
        </Typography>
      </Box>
    );
  } else {
    if (value == '-') {
      innerTextComponent = (
        <Box display={'flex'} alignItems={'center'} sx={{height: '100%'}}>
          <Typography variant="body1" sx={{fontWeight, pl: 2}}>
            {value}
          </Typography>
        </Box>
      );
    } else if (formatItem == 'chip') {
      innerTextComponent = (
        <Chip
          label={value}
          size="small"
          sx={{
            backgroundColor: row.format?.color || row?.backgroundTextColor,
            color: row.format?.text || row?.textColor,
            '& .MuiChip-label': {fontSize: 12, fontWeight: 400, overflow: 'visible'}
          }}
        />
      );
    } else {
      innerTextComponent = (
        <Box display={'flex'} alignItems={'center'} sx={{height: '100%'}}>
          <Typography variant="body1" sx={{fontWeight}}>
            {value}
          </Typography>
        </Box>
      );
    }
  }

  return <TableTooltip innerTextComponent={innerTextComponent} params={params} />;
};

export const TableTooltip = ({innerTextComponent, params}) => {
  const theme = useTheme();

  if (params.row?.tooltip && params.field in params.row.tooltip) {
    const {icon} = params.row.tooltip[params.field];

    if (icon) {
      return (
        <Stack direction="row" columnGap={2} justifyContent="center">
          {innerTextComponent}
          <Tooltip
            key={`key-for--${params.field}`}
            title={<TitleComponent params={params} />}
            componentsProps={{
              tooltip: {
                style: {
                  backgroundColor: theme.palette.grey[50],
                  color: theme.palette.grey[700],
                  fontWeight: 400,
                  padding: '16px 24px',
                  maxWidth: '100%',
                  borderRadius: '12px'
                }
              }
            }}
          >
            <IconButton disableRipple sx={{p: 0, width: '12px', height: '12px', cursor: 'default'}}>
              <FontAwesomeIcon icon={fontAwesomeIcons['faCircleQuestionLight']} style={{width: '14px', height: '14px'}} />
            </IconButton>
          </Tooltip>
        </Stack>
      );
    } else {
      return (
        <Tooltip
          key={`key-for--${params.field}`}
          title={<TitleComponent params={params} />}
          componentsProps={{
            tooltip: {
              style: {
                backgroundColor: theme.palette.grey[50],
                color: theme.palette.grey[700],
                border: `1px solid ${theme.palette.grey[200]}`,
                fontWeight: 400,
                padding: 16,
                maxWidth: '100%'
              }
            }
          }}
        >
          {innerTextComponent}
        </Tooltip>
      );
    }
  } else {
    return innerTextComponent;
  }
};

TableTooltip.propTypes = {
  innerTextComponent: PropTypes.element,
  params: PropTypes.shape({
    field: PropTypes.string,
    row: PropTypes.shape({
      tooltip: PropTypes.shape({
        icon: PropTypes.bool
      })
    })
  })
};

const TitleComponent = ({params}) => {
  const {field, row} = params;
  let mainComponent;

  if (field in row.tooltip) {
    const tpData = row.tooltip[field];
    const {header, content, summary} = tpData;

    const headerComp = header.map((item) => {
      const {key, value, bold} = item;
      const box = item?.box ?? null;
      return tooltipComponent({key, value, bold, box});
    });

    const contentComp = content.map((item) => {
      const {key, value, bold} = item;
      const box = item?.box ?? null;
      return tooltipComponent({key, value, bold, box});
    });

    const summaryComp = summary.map((item) => {
      const {key, value, bold} = item;
      const box = item?.box ?? null;
      return tooltipComponent({key, value, bold, box});
    });

    mainComponent = (
      <Stack direction="column" divider={<StyledMarginWidthDividerSm />} rowGap={2}>
        <Stack direction="column" alignItems="center" justifyContent="center">
          {headerComp}
        </Stack>
        <Stack direction="column">
          <Stack direction="column">{contentComp}</Stack>
          {summaryComp}
        </Stack>
      </Stack>
    );
  }
  return mainComponent;
};

TitleComponent.propTypes = {
  params: PropTypes.shape({
    field: PropTypes.string,
    row: PropTypes.shape({
      tooltip: PropTypes.shape({
        header: PropTypes.array,
        content: PropTypes.array,
        summary: PropTypes.array
      })
    })
  })
};

const tooltipComponent = (params) => {
  const {key, value, bold, box} = params;

  return (
    <Stack key={`tooltip-line-${key.split(' ').join('-').toLowerCase()}`} direction="row" alignItems="center" columnGap={1}>
      {box && <Box sx={{width: '12px', height: '12px', backgroundColor: box, borderRadius: '4px'}} />}
      {key && (
        <Typography sx={{fontSize: '0.750rem', fontWeight: bold && bold.includes('key') ? 600 : 400}}>
          {key}
          {value ? ':' : ''}
        </Typography>
      )}
      {value && <Typography sx={{fontSize: '0.750rem', fontWeight: bold && bold.includes('value') ? 600 : 400}}>{value}</Typography>}
    </Stack>
  );
};

tooltipComponent.propTypes = {
  params: PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.string,
    bold: PropTypes.string
  })
};

const findChipStyling = (id, value) => {
  let variant = 'outlined';
  let color = 'primary';

  if (id == 'status') {
    variant = 'filled';
    if (value == 'Active') {
      color = 'success';
    }
  }

  return {variant, color};
};

const ChipCell = ({variant, color, cellValue, icon = false}) => {
  let chipLabel = cellValue;

  if (icon) {
    chipLabel = (
      <Stack direction={'row'} alignItems={'center'} columnGap={1}>
        {cellValue}
        <FontAwesomeIcon icon={fontAwesomeIcons.faEye} />
      </Stack>
    );
  }
  return (
    <Chip
      variant={variant}
      color={color}
      label={chipLabel}
      sx={{
        display: 'inline-flex',
        '&>span': {
          textAlign: 'center',
          whiteSpace: 'nowrap',
          overflow: 'none',
          textOverflow: 'ellipsis'
        }
      }}
    />
  );
};

ChipCell.propTypes = {
  variant: PropTypes.string,
  color: PropTypes.string,
  cellValue: PropTypes.any,
  icon: PropTypes.bool
};

const FindTooltipInfo = ({data}) => {
  return (
    <List dense={true}>
      {data.map((ele, index) => {
        return (
          <ListItem key={index} divider={data.length > 1}>
            {ele}
          </ListItem>
        );
      })}
    </List>
  );
};

FindTooltipInfo.propTypes = {
  data: PropTypes.array
};

const TooltipChipValue = ({variant, color, cellValue, data}) => {
  const tooltipInfo = <FindTooltipInfo data={data} />;
  return (
    <Chip
      color={color}
      variant={variant}
      label={
        <StyledCustomTooltip title={tooltipInfo} arrow>
          <Stack direction={'row'} alignItems={'center'} columnGap={1}>
            {cellValue}
            <FontAwesomeIcon icon={fontAwesomeIcons.faEye} />
          </Stack>
        </StyledCustomTooltip>
      }
      sx={{cursor: 'default', width: 'fit-content'}}
    />
  );
};

TooltipChipValue.propTypes = {
  variant: PropTypes.string,
  color: PropTypes.string,
  cellValue: PropTypes.any,
  data: PropTypes.array
};

const GroupChips = ({data, id}) => {
  const isPropertyPermissions = id == 'permissions';
  const isTarget = id == 'target';
  let color = isPropertyPermissions || isTarget ? 'primary' : 'success';
  let variant = isPropertyPermissions || isTarget ? 'outlined' : 'filled';

  if ((isPropertyPermissions || isTarget) && data.length == 0) {
    let emptyMsg = isPropertyPermissions ? 'All properties' : 'All targets';
    return <ChipCell variant={variant} color={color} cellValue={emptyMsg} />;
  }

  if (data.length == 0) {
    return <ChipCell variant={'filled'} color={'primary'} cellValue={'No current offers'} />;
  }

  const isSingle = data.length == 1;
  let chipValueTitle = '';

  if (isPropertyPermissions) {
    chipValueTitle = isSingle ? 'property' : 'properties';
  } else if (isTarget) {
    chipValueTitle = isSingle ? 'target' : 'targets';
  } else {
    chipValueTitle = isSingle ? 'current offer' : 'current offers';
  }
  const chippLabel = `${data.length} ${chipValueTitle}`;

  return <TooltipChipValue variant={variant} color={color} cellValue={chippLabel} data={data} />;
};

GroupChips.propTypes = {
  data: PropTypes.array,
  id: PropTypes.string
};

const TableBodyCell = ({column, params}) => {
  const theme = useTheme();

  const {value, row, field} = params;
  const {dataFormat, fieldType, id} = column;
  let color;

  if (column?.color == 'red/green') {
    color = value < 0 ? theme.palette.error.main : theme.palette.success.main;
  }

  const isNull = value == null || value === 'null';
  let cellValue = row[field];
  let boxValue = isNull ? '' : value;

  if (dataFormat == 'grouping') {
    return <GroupChips data={value} id={id} />;
  }

  if (fieldType == 'chip' && row[field]) {
    const {variant, color} = findChipStyling(id, cellValue);
    return <ChipCell variant={variant} color={color} cellValue={cellValue} />;
  } else if (fieldType == 'checkbox') {
    if (value === undefined || value == 'N/A') {
      return (
        <Box display={'flex'} alignItems={'center'} sx={{height: '100%'}}>
          <Typography variant="subtitle2" color="text.subtitle" fontWeight="fontWeightRegular">
            No data
          </Typography>
        </Box>
      );
    }
    return <FormControlLabel control={<Checkbox id="month-payment" checked={value || false} color="secondary" />} label="" />;
  } else if (fieldType == 'list') {
    return (
      <Box display={'flex'} sx={{alignItems: 'center', height: '100%'}}>
        <Stack direction={'column'} columnGap={0} sx={{heigth: 'fit-content'}} justifyContent={'center'}>
          {value.map((item, index) => {
            return (
              <Typography key={index} sx={{fontSize: '13px'}}>
                {item}
              </Typography>
            );
          })}
        </Stack>
      </Box>
    );
  } else if (fieldType == 'link') {
    if (!value) {
      return null;
    }

    return (
      <Stack direction={'row'} columnGap={1} sx={{width: '100%', height: '100%', alignItems: 'center'}}>
        <Typography variant="body2" sx={{fontSize: '13px'}}>
          {cellValue}
        </Typography>
        <StyledCustomTooltip title={row?.res_detailed_description} arrow sx={{maxWidth: 300}}>
          <IconButton size="small">
            <FontAwesomeIcon icon={fontAwesomeIcons.faEye} />
          </IconButton>
        </StyledCustomTooltip>
      </Stack>
    );
  } else {
    if (value || value === 0) {
      if (dataFormat === 'phone') {
        boxValue = <PhoneFormatCustomDisplay value={value} />;
      } else if (dataFormat === 'numeric') {
        boxValue = <NumericFormatCustomDisplay value={value} />;
      } else if (dataFormat === 'currency') {
        boxValue = <MoneyFormatCustomDisplay value={value} isColored={Boolean(color)} />;
      } else if (dataFormat == 'percentage') {
        boxValue = `${value.toFixed(1)}%`;
      } else if (dataFormat == 'date') {
        boxValue = dayjs(value).format('MMM D, YYYY');
      }
    }

    return (
      <Box display={'flex'} sx={{alignItems: 'center', height: '100%'}}>
        <Typography variant="body2" sx={{fontSize: '13px'}} color={color || 'text.primary'}>
          {boxValue}
        </Typography>
      </Box>
    );
  }
};

TableBodyCell.propTypes = {
  column: PropTypes.object,
  params: PropTypes.object
};

export default TableBodyCell;
