import React, {Fragment, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import PropTypes from 'prop-types';

import {Stack} from '@mui/material';

import EsgDownloadButton from './EsgDownloadButton';
import {getDashboardAccount} from '../../../../app/store/reducers/dashboard';
import {getAccounts} from '../../../../app/store/reducers/entities';
import {findColorFromThePath} from '../../../constants/stylingConstants';

const PDFComponents = ({data}) => {
  const dashboardAccount = useSelector(getDashboardAccount);
  const accounts = useSelector(getAccounts);

  const account = accounts.find((account) => account.sfid === dashboardAccount.sfid);

  const currentDate = new Date();
  const formattedDate = currentDate.toLocaleString('en-US', {day: 'numeric', month: 'long', year: 'numeric'});
  let mainComponent;

  const [pdfData, setPdfData] = useState({
    name: '',
    formattedDate: ''
  });
  const roundFloatsToIntegers = (inputString) => {
    const regex = /[-+]?\d+(\.\d+)/g;
    const roundedString = inputString.replace(regex, (match) => Math.round(parseFloat(match)));
    return roundedString;
  };

  useEffect(() => {
    if (dashboardAccount.sfid && dashboardAccount.sfid != 'all' && data) {
      const {first_block, second_block, third_block} = data;
      let accountPdfData = {};
      accountPdfData.formattedDate = formattedDate;
      accountPdfData.name = account.name;
      accountPdfData.totalUnlocks = first_block.rows[0].value;
      accountPdfData.residentsUnlocks = first_block.rows[1].value;
      accountPdfData.totalUnlocksTitle = first_block.rows[0].title;
      accountPdfData.residentsUnlocksTitle = first_block.rows[1].title;
      accountPdfData.esg_metrics_description = third_block.description;
      let staker_insights = [];
      let metrics1 = [];
      let metrics2 = [];
      let metrics3 = [];
      let metrics4 = [];

      if (second_block.rows.length > 0) {
        second_block.rows.forEach((row) => {
          const dupeRow = {...row};
          const fillColor = findColorFromThePath(dupeRow['icon_element']);
          let rowData = {};
          rowData.value = row.value;
          rowData.title = row.title;
          rowData.description = row.description;
          rowData.icon = roundFloatsToIntegers(row.icon_element.split(['"'])[9]);
          rowData.fillColor = fillColor;
          staker_insights.push(rowData);
        });
      }
      accountPdfData.staker_insights = staker_insights;

      if (third_block.rows.length > 0) {
        third_block.rows.forEach((row, idx) => {
          const dupeRow = {...row};
          const fillColor = findColorFromThePath(dupeRow['icon_element']);
          let rowData = {};
          rowData.value = row.value;
          rowData.title = row.title;
          rowData.description1 = row.description_1;
          rowData.description2 = row.description_2;
          rowData.icon = roundFloatsToIntegers(row.icon_element.split(['"'])[9]);
          rowData.fillColor = fillColor;

          if (idx < 2) {
            metrics1.push(rowData);
          } else if (idx < 4) {
            metrics2.push(rowData);
          } else if (idx < 6) {
            metrics3.push(rowData);
          } else {
            metrics4.push(rowData);
          }
        });
      }
      accountPdfData.metrics1 = metrics1;
      accountPdfData.metrics2 = metrics2;
      accountPdfData.metrics3 = metrics3;
      accountPdfData.metrics4 = metrics4;
      setPdfData(accountPdfData);
    } else {
      setPdfData({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardAccount, data]);

  if (pdfData.name && pdfData.name.length > 0) {
    mainComponent = (
      <Fragment>
        <EsgDownloadButton pdfData={pdfData} />
      </Fragment>
    );
  }

  return (
    <Stack direction="row" columnGap={1}>
      {mainComponent}
    </Stack>
  );
};

PDFComponents.propTypes = {
  data: PropTypes.object.isRequired
};

export default PDFComponents;
