import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';

// material-ui
import {Stack} from '@mui/material';

// project import
import Settings from './Settings';
import LogoSection from '../../../../commons/components/Logo/LogoSection';
import TitleDashboard from '../../../../commons/components/TitleDashboard/TitleDashboard';
import MenuDashboard from '../../../../commons/components/MenuDashboard/MenuDashboard';

import {mainContentPaddingHeight, mainContentPaddingWidth} from '../../../../config';
import {getDashboardAccount} from '../../../../app/store/reducers/dashboard';
import {useLazyGetChildAccountsQuery} from '../../../../features/coreApi/apiSlice';

// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = () => {
  const dashboardAccount = useSelector(getDashboardAccount);
  const [fetchChildAccounts] = useLazyGetChildAccountsQuery();

  useEffect(() => {
    if (dashboardAccount.sfid) {
      fetchChildAccounts(dashboardAccount.sfid);
    }
  }, [dashboardAccount, fetchChildAccounts]);

  return (
    <Stack
      direction="column"
      rowGap={1}
      sx={{width: '100%', height: '100%', p: `${mainContentPaddingHeight}px ${mainContentPaddingWidth}px`}}
    >
      <LogoSection />
      <Stack direction="row" justifyContent="space-between" alignItems="center" columnGap={4} sx={{width: '100%', height: 'fit-content'}}>
        <TitleDashboard />
        <Stack direction="row" justifyContent="flex-end" columnGap={1} alignItems="flex-start" sx={{width: '360px', minWidth: '360px'}}>
          <Settings />
        </Stack>
      </Stack>
      <MenuDashboard />
    </Stack>
  );
};

export default HeaderContent;
