import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export const findSpendingChartData = (data, dateData) => {
  let total = new Array(dateData.length).fill(0);

  data.forEach((spending) => {
    const {amount, month_str, year_str} = spending;
    const d = month_str + "'" + year_str.toString().slice(2);
    const idx = dateData.indexOf(d);
    total[idx] += amount;
  });

  return total;
};

const sortDataByCategory = (category, data, filter) => {
  let categoriesData = {};

  data.forEach((spending) => {
    const {category_1, category_2, merchant_name, amount} = spending;
    const roundAmount = Math.round(amount);

    if (filter === 'category_1') {
      if (!categoriesData[category_1]) {
        categoriesData[category_1] = {
          name: category_1,
          amount: roundAmount,
          subcategories: [category_2],
          cat: filter,
          parentCategory: 'none'
        };
      } else {
        categoriesData[category_1].amount += roundAmount;
        if (!categoriesData[category_1].subcategories.includes(category_2)) {
          categoriesData[category_1].subcategories.push(category_2);
        }
      }
    }
    if (filter === 'category_2' && category_1 === category) {
      if (!categoriesData[category_2]) {
        categoriesData[category_2] = {
          name: category_2,
          amount: roundAmount,
          merchantName: [merchant_name],
          cat: filter
        };
      } else {
        categoriesData[category_2].amount += roundAmount;
        if (!categoriesData[category_2].merchantName.includes(merchant_name)) {
          categoriesData[category_2].merchantName.push(merchant_name);
        }
      }
    } else if (filter === 'category_3' && category_2 === category) {
      if (!categoriesData[merchant_name]) {
        categoriesData[merchant_name] = {
          name: merchant_name,
          amount: roundAmount,
          cat: filter
        };
      } else {
        categoriesData[merchant_name].amount += roundAmount;
      }
    }
  });

  const sortedData = Object.values(categoriesData).sort((a, b) => b.amount - a.amount);
  const slicedData = filter === 'category_1' ? sortedData : sortedData.slice(0, 10);

  return slicedData;
};

export const findCategoriesData = (data, category, subCategory) => {
  const subC = subCategory === 'category_3' ? 'merchant_name' : subCategory;
  const byCategory = {};
  const obj = {};
  let total = 0;
  data &&
    data.forEach((ele) => {
      const c = ele[category];
      if (obj[c]) {
        obj[c].amount += ele.amount;
        obj[c].subCategories.add(ele[subC]);
      } else {
        obj[c] = {
          amount: ele.amount,
          category: category,
          name: ele[category],
          subCategories: new Set([ele[subC]])
        };
      }

      if (byCategory[c]) {
        byCategory[c].push(ele);
      } else {
        byCategory[c] = [ele];
      }
      total += ele.amount;
    });

  return {
    byCategory: byCategory,
    total: total,
    obj: obj
  };
};

export const findGraphData = (category, data, months, filter) => {
  let graphData = {};
  const categoryData = sortDataByCategory(category, data, filter);

  categoryData.map((dataCategory) => {
    const length = months.length;
    const data = new Array(length).fill(0);

    graphData[dataCategory.name] = {
      name: dataCategory.name,
      data: data,
      cat: dataCategory.cat,
      subs: []
    };
  });

  data.forEach((stateCategory) => {
    const {category_1, category_2, amount, authorized_date, merchant_name} = stateCategory;
    const roundedAmount = amount;
    const dateStr = dayjs.utc(authorized_date).format('MMM YYYY');
    const idx = months.indexOf(dateStr);

    if (idx >= 0) {
      if (filter === 'category_1') {
        if (!graphData[category_1].subs.includes(category_2)) {
          graphData[category_1].subs.push(category_2);
        }

        if (graphData[category_1]) {
          graphData[category_1].data[idx] += roundedAmount;
        }
      } else if (filter === 'category_2') {
        if (graphData[category_2] && !graphData[category_2].subs.includes(merchant_name)) {
          graphData[category_2].subs.push(merchant_name);
        }

        if (graphData[category_2]) {
          graphData[category_2].data[idx] += roundedAmount;
        }
      } else if (filter === 'category_3') {
        if (graphData[merchant_name]) {
          graphData[merchant_name].data[idx] += roundedAmount;
        }
      }
    }
  });

  const obj = {
    categoryData: categoryData,
    categoryGraphData: Object.values(graphData)
  };

  if (filter === 'category_1') {
    return {
      ...obj,
      isChildCategory: false
    };
  } else {
    return {
      ...obj,
      isChildCategory: true
    };
  }
};
