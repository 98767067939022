import React from 'react';
import PropTypes from 'prop-types';

export const CustomizedXAxisTick = (props) => {
  const {x, y, payload} = props;

  return (
    <text x={x} y={y} dy={16} fill="#787878" textAnchor="middle">
      {payload.value}
    </text>
  );
};

CustomizedXAxisTick.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  payload: PropTypes.object
};
