import React, {useMemo} from 'react';
import PropTypes from 'prop-types';

import {Paper, Typography, Stack} from '@mui/material';
import {StyledMarginWidthDividerSm} from '../../@extended/Divider';

const EngagementCreditBuilderRow = ({entry, index}) => {
  return (
    <Stack key={entry.label + index} direction="row" alignItems="center" justifyContent="space-between">
      <Typography variant="h6">{entry.label}</Typography>
      <Typography variant="h6">{entry.value}</Typography>
    </Stack>
  );
};

EngagementCreditBuilderRow.propTypes = {
  entry: PropTypes.object,
  index: PropTypes.number
};

const EngagementCreditBuilder = ({creditBuilderInfo}) => {
  const creditBuilderItems = useMemo(() => {
    if (creditBuilderInfo.items) return creditBuilderInfo.items;
    return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(creditBuilderInfo)]);

  if (creditBuilderItems.length == 0) return <></>;

  return (
    <Paper sx={{borderRadius: '8px', padding: '16px', width: '100%', boxShadow: 'none'}}>
      <Stack direction="column" rowGap={1.5} sx={{height: '100%'}} divider={<StyledMarginWidthDividerSm />}>
        <Typography variant="h5" fontWeight={'fontWeightMedium'} alignItems="center" sx={{display: 'flex'}}>
          {creditBuilderInfo.title ?? ''}
        </Typography>
        {creditBuilderItems.map((entry, index) => (
          <EngagementCreditBuilderRow key={entry.label} entry={entry} index={index} />
        ))}
      </Stack>
    </Paper>
  );
};

EngagementCreditBuilder.propTypes = {
  creditBuilderInfo: PropTypes.object
};

export default EngagementCreditBuilder;
