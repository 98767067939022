import dayjs from 'dayjs';

export const monthsByName = {
  Jan: 1,
  Feb: 2,
  Mar: 3,
  Apr: 4,
  May: 5,
  Jun: 6,
  Jul: 7,
  Aug: 8,
  Sep: 9,
  Oct: 10,
  Nov: 11,
  Dec: 12
};

export const monthsByIdxFull = {
  1: 'January',
  2: 'February',
  3: 'March',
  4: 'April',
  5: 'May',
  6: 'June',
  7: 'July',
  8: 'August',
  9: 'September',
  10: 'October',
  11: 'November',
  12: 'December'
};

export const findLastTwelveMonthsWithYYYY = () => {
  const monthsAndYearsByDefault = [];

  let i = 12;
  while (i >= 1) {
    monthsAndYearsByDefault.push(dayjs().subtract(i, 'months').format('MMM YYYY'));
    i--;
  }

  return monthsAndYearsByDefault;
};

export const findLastTwelveMonthsWithYY = () => {
  const monthsAndYearsByDefault = [];

  let i = 12;
  while (i >= 1) {
    monthsAndYearsByDefault.push(dayjs().subtract(i, 'months').format("MMM'YY"));
    i--;
  }

  return monthsAndYearsByDefault;
};

export const formatDateString = (dateString) => {
  const date = new Date(dateString);
  if (isNaN(date.getTime())) {
    return null;
  }
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}/${month}/${day}`;
};
