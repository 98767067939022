import React from 'react';
import {useSelector} from 'react-redux';
import {getCookie} from '../commons/utils/cookies';
import {getAuthorizedStatus} from '../app/store/reducers/session';
import {Navigate} from 'react-router-dom';

const ProtectedRoute = ({children}) => {
  const isAuthorized = useSelector(getAuthorizedStatus);
  const sessionToken = getCookie('session_token');
  const isAuthenticated = Boolean(isAuthorized || sessionToken);

  return isAuthenticated ? children : <Navigate to="/login" replace />;
};

export default ProtectedRoute;
