import React from 'react';
import PropTypes from 'prop-types';

import {Stack, Typography, Grid} from '@mui/material';

import EsgMetricsBox from './EsgMetricsBox';

const ESGMetrics = ({data}) => {
  const title = data.title ?? '';
  const description = data.description ?? '';
  const rows = data.rows ?? [];

  const rowBlocks = rows.map((e, index) => {
    return <EsgMetricsBox key={`${e.title}${index}`} data={e} />;
  });

  return (
    <Stack direction="column" rowGap={1}>
      <Typography variant="h5" fontWeight={'fontWeightMedium'}>
        {title}
      </Typography>
      <Typography variant="subtitle1" fontWeight={'fontWeightRegular'} color={'text.subtitle'}>
        {description}
      </Typography>
      <Grid container spacing={2} sx={{marginTop: 1}}>
        {rowBlocks}
      </Grid>
    </Stack>
  );
};

ESGMetrics.propTypes = {
  data: PropTypes.object
};

export default ESGMetrics;
