import React from 'react';
import {Box, Stack, Typography} from '@mui/material';

const renderCustomLegendText = (value, theme) => {
  return (
    <span className="text-color-class" style={{fontFamily: 'Poppins, sans-serif', color: theme.palette.grey[600]}}>
      {value}
    </span>
  );
};

export default renderCustomLegendText;

export const renderLegend = ({payload}) => {
  return (
    <Stack direction="row" justifyContent="center" alignItems="center" columnGap={1} sx={{padding: 1}}>
      {payload
        .filter((item) => item.color != '#FFFFFF')
        .map((entry) => (
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            key={`item-${entry.dataKey.split(' ').join('-').toLowerCase()}`}
            columnGap={1}
          >
            <Box sx={{width: '12px', height: '12px', backgroundColor: entry.color, borderRadius: '4px'}} />
            <Typography variant="subtitle3_light" sx={{fontFamily: 'Poppins, sans-serif'}}>
              {entry.value}
            </Typography>
          </Stack>
        ))}
    </Stack>
  );
};
