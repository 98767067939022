import React, {useMemo} from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

dayjs.extend(duration);

import {Box, Paper, Typography} from '@mui/material';

import {StyledTable} from '../../commons/components/@extended/CustomTables';
import {getRandomValue, valueGetter} from '../../commons/constants/tableColumns';

import TableBodyCell from '../../commons/components/@extended/TableBodyCell';
import LoadingBox from '../../commons/components/@extended/LoadingBox';

const checkPaidOnTime = (paymentMap, key, valueKey) => {
  if (paymentMap[key] && valueKey in paymentMap[key] && typeof paymentMap[key][valueKey] === 'boolean') {
    return paymentMap[key][valueKey];
  }
  return 'N/A';
};

const ResidentPaymentHistory = ({startDate, data, isSuccess, isLoading, isError, isOptedInCreditReporting}) => {
  const {columns, rows} = useMemo(() => {
    if (!isSuccess || isLoading || isError || !data.length || !startDate) {
      return {columns: [], rows: []};
    }

    const startMoment = dayjs(startDate, 'YYYY-MM-DD').startOf('month');
    const endMoment = dayjs().endOf('month');
    const monthsDiff = endMoment.diff(startMoment, 'months') + 1;

    let monthsArray = Array.from({length: monthsDiff}, (_, i) => {
      const date = dayjs(startMoment).add(i, 'months');
      return {
        year: date.year(),
        month: date.month(),
        key: date.format('YYYY-MM')
      };
    });
    monthsArray = monthsArray.reverse();

    const paymentMap = data.reduce((acc, payment) => {
      const month = payment.month__c < 10 ? `0${payment.month__c}` : payment.month__c;
      acc[`${payment.year__c}-${month}`] = payment;
      return acc;
    }, {});

    const paymentColumns = monthsArray.map(({year, month, key}) => ({
      field: key,
      headerName: `${dayjs(key, 'YYYY-MM').format('MMM YYYY')}`,
      width: 120,
      operator: 'or',
      numeric: false,
      disablePadding: true,
      fieldType: 'checkbox',
      editable: false,
      dataFormat: 'default',
      color: null,
      icon: 'sort',
      disableColumnFilter: false,
      filterable: true,
      disableColumnSorting: false,
      sortable: true,
      flex: 1,
      minWidth: 100
    }));

    const columns = [
      {
        field: 'label',
        headerName: 'Status',
        operator: 'or',
        numeric: false,
        disablePadding: true,
        label: 'Payment month',
        fieldType: 'box',
        editable: false,
        dataFormat: 'default',
        color: null,
        icon: 'sort',
        disableColumnFilter: false,
        filterable: true,
        disableColumnSorting: false,
        sortable: true,
        flex: 1,
        minWidth: 200
      },
      ...paymentColumns
    ].map((column) => ({
      ...column,
      renderCell: (params) => <TableBodyCell column={column} params={params} />,
      valueFormatter: (params) => valueGetter(params, column)
    }));

    const rows = [
      {
        id: getRandomValue(),
        label: 'Paid on time',
        ...monthsArray.reduce(
          (acc, {key}) => ({
            ...acc,
            [key]: checkPaidOnTime(paymentMap, key, 'paid_on_time__c')
          }),
          {}
        )
      },
      {
        id: getRandomValue(),
        label: 'Paid with Stake Checking',
        ...monthsArray.reduce(
          (acc, {key}) => ({
            ...acc,
            [key]: checkPaidOnTime(paymentMap, key, 'paid_via_svd__c')
          }),
          {}
        )
      }
    ];

    if (isOptedInCreditReporting) {
      rows.push({
        id: getRandomValue(),
        label: 'Reported to Credit Bureaus',
        ...monthsArray.reduce(
          (acc, {key}) => ({
            ...acc,
            [key]: checkPaidOnTime(paymentMap, key, 'reported_to_credit_bureaus__c')
          }),
          {}
        )
      });
    }

    return {columns, rows};
  }, [data, isSuccess, isLoading, isError]);

  if (rows.length === 0 && !isLoading) {
    return (
      <Paper sx={{width: '100%', padding: 2, boxShadow: 'none', height: '100px'}}>
        <Box sx={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <Typography variant="subtitle1" color={'text.subtitle'} fontWeight={'fontWeightRegular'} textAlign="center" lineHeight="1.25">
            No resident payment history found.
          </Typography>
        </Box>
      </Paper>
    );
  } else if (!isLoading && rows.length > 0) {
    return (
      <Paper sx={{borderRadius: 2, boxShadow: 'none'}}>
        <Box sx={{overflow: 'auto', width: '100%'}}>
          <Box sx={{minWidth: 'max-content'}}>
            <StyledTable
              density="compact"
              rows={rows}
              columns={[...columns]}
              loading={isLoading}
              autoHeight
              rowHeight={60}
              disableRowSelectionOnClick
              hideFooter
            />
          </Box>
        </Box>
      </Paper>
    );
  } else if (isLoading) {
    return <LoadingBox />;
  }
};

ResidentPaymentHistory.propTypes = {
  data: PropTypes.array,
  isSuccess: PropTypes.bool,
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
  startDate: PropTypes.any
};

export default ResidentPaymentHistory;
