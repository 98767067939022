import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {FormProvider, useForm} from 'react-hook-form';
import {useSelector} from 'react-redux';
import * as Yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';

import {Box, Button, Dialog, DialogContent, Stack, Typography} from '@mui/material';
import {getLcTeamMemberId} from '../../../../app/store/reducers/session';
import {getResidents} from '../../../../app/store/reducers/entities';
import {useCreateReviewMutation} from '../../../../features/loyaltyCloudApi/reviewsSlice';
import {FormTextArea, SimpleFormCheckbox, SimpleFormSelectWithLabel} from '../../@extended/Forms';
import {DialogTitleWithCloseIcon} from '../../@extended/CustomDialogs';
import {FormStarRating} from '../../@extended/StyledRating';

const reviewSchema = Yup.object().shape({
  text: Yup.string().required().min(1),
  mobile_user_id: Yup.string().required().min(1),
  rating: Yup.number().required().min(1).max(5)
});

const AddReviewModal = ({open, setOpen}) => {
  const lcTeamMemberId = useSelector(getLcTeamMemberId);
  const residents = useSelector(getResidents);

  const [createReview, {isSuccess: isAddedSuccessfully}] = useCreateReviewMutation();

  let residentsComponent;

  const methods = useForm({
    defaultValues: {
      mobile_user_id: '',
      rating: 4,
      team_member_id: '',
      text: '',
      is_anonymous: false
    },
    mode: 'all',
    resolver: yupResolver(reviewSchema)
  });

  const {
    handleSubmit,
    formState: {isValid, isDirty},
    reset
  } = methods;

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (isAddedSuccessfully) {
      reset({
        mobile_user_id: '',
        rating: 4,
        team_member_id: '',
        text: '',
        is_anonymous: false
      });
      setOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAddedSuccessfully]);

  const onSubmit = (data) => {
    const fbObj = {
      ...data,
      team_member_id: lcTeamMemberId
    };

    createReview(fbObj);
  };

  if (residents.length > 0) {
    const residentsData = [...residents].map((r) => ({...r, id: r.mobile_user_id__c}));
    residentsComponent = (
      <SimpleFormSelectWithLabel name="mobile_user_id" options={[{id: 'default', name: 'Select a resident'}, ...residentsData]} />
    );
  }

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitleWithCloseIcon onClose={handleClose} sx={{pt: 3}}>
        <Stack direction="column" rowGap={1}>
          <Typography variant="h5" fontWeight={'fontWeightRegular'}>
            Add review
          </Typography>
          <Typography variant="subtitle2" color={'text.subtitle'}>
            Enter the residents review, and estimate their rating.
          </Typography>
        </Stack>
      </DialogTitleWithCloseIcon>
      <DialogContent sx={{'&.MuiDialogContent-root': {pb: 3, pr: 3}}} dividers>
        <FormProvider {...methods}>
          <form style={{width: '100%'}} onSubmit={handleSubmit(onSubmit)}>
            <Stack direction="column" rowGap={2}>
              <Box display="flex">
                <FormStarRating name="rating" />
              </Box>
              {residentsComponent}
              <Stack direction="column" rowGap={1}>
                <FormTextArea name="text" placeholder="Enter resident review" />
                <Box display="flex">
                  <SimpleFormCheckbox name="is_anonymous" label="Anonymous review" />
                </Box>
                <Box display="flex" justifyContent="flex-end">
                  <Button type="submit" disabled={!isValid || !isDirty} variant="contained" color="secondary" sx={{padding: '8px 24px'}}>
                    Submit
                  </Button>
                </Box>
              </Stack>
            </Stack>
          </form>
        </FormProvider>
      </DialogContent>
    </Dialog>
  );
};

AddReviewModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired
};

export default AddReviewModal;
