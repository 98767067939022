import React from 'react';
import {useSelector} from 'react-redux';
import * as Yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {Controller, useForm} from 'react-hook-form';

import {Box, Button, Divider, Grid, Paper, Stack, TextField, Typography} from '@mui/material';

import validateEmail from '../../../utils/emailValidation';
import {getSession} from '../../../../app/store/reducers/session';
import {useUpdateTeamMemberPersonalInfoMutation} from '../../../../features/loyaltyCloudApi/apiSlice';

const residentSchema = Yup.object().shape({
  first_name: Yup.string().required('First name is required').min(1),
  last_name: Yup.string().required('Last name is required').min(1),
  email: Yup.string()
    .required('Email is required')
    .test('is-valid-email', 'Email is invalid', (value) => validateEmail(value?.replace(/\s/g, '')))
});

const ProfileGeneral = () => {
  const session = useSelector(getSession);
  const [updateInfo] = useUpdateTeamMemberPersonalInfoMutation();

  const {
    control,
    handleSubmit,
    formState: {errors}
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      first_name: session?.firstName || '',
      last_name: session?.lastName || '',
      email: session?.email || ''
    },
    resolver: yupResolver(residentSchema)
  });

  const onSubmit = async (data) => {
    let dataForm = new FormData();
    ['first_name', 'last_name', 'email'].forEach((key) => {
      let sessionKey = 'email';
      if (key === 'first_name') sessionKey = 'firstName';
      if (key === 'last_name') sessionKey = 'lastName';

      if (data[key].toLowerCase() !== session[sessionKey]?.toLowerCase()) {
        dataForm.append(key, data[key]);
      }
    });

    updateInfo({data: dataForm, id: session?.lcTeamMemberId});
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Paper sx={{width: '100%', height: 'fit-content', p: 3}}>
        <Stack direction={'column'} sx={{width: '100%', height: '100%'}} rowGap={3} divider={<Divider />}>
          <Typography variant="h5" fontWeight={'fontWeightMedium'}>
            Personal information
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Controller
                name="first_name"
                control={control}
                render={({field, fieldState}) => (
                  <TextField {...field} fullWidth label="First name" error={!!fieldState.error} helperText={fieldState.error?.message} />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="last_name"
                control={control}
                render={({field, fieldState}) => (
                  <TextField {...field} fullWidth label="Last name" error={!!fieldState.error} helperText={fieldState.error?.message} />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="email"
                control={control}
                render={({field, fieldState}) => (
                  <TextField {...field} fullWidth label="Email" error={!!fieldState.error} helperText={fieldState.error?.message} />
                )}
              />
            </Grid>
          </Grid>
          <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              sx={{width: 'fit-content'}}
              disabled={Object.values(errors).length > 0}
            >
              Save changes
            </Button>
          </Box>
        </Stack>
      </Paper>
    </form>
  );
};

export default ProfileGeneral;
