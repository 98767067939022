import React, {useEffect} from 'react';

import {Stack} from '@mui/material';

import {useSelector} from 'react-redux';
import {getDashboardCampaign} from '../../../../app/store/reducers/dashboard';
import {useLazyGetCampaignByIdQuery} from '../../../../features/actionsApi/campaignsSlice';

import ConcessionComps from './Metrics/ConcessionComps';
import RenewalPerformance from './RenewalPerformance';

const CampaignPerformance = () => {
  const dashboardCampaign = useSelector(getDashboardCampaign);
  const [fetchCampaign, {data: campaign = {}}] = useLazyGetCampaignByIdQuery();

  useEffect(() => {
    if (dashboardCampaign?.sfid) {
      fetchCampaign(dashboardCampaign.sfid);
    }
  }, [dashboardCampaign, fetchCampaign]);

  return (
    <Stack direction="column" rowGap={2} sx={{width: '100%'}}>
      {/* {campaign?.subtype == 'Renewals' && <RenewalPerformance campaign={campaign} />} */}
      <ConcessionComps />
    </Stack>
  );
};

export default CampaignPerformance;
