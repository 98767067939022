import React, {useMemo} from 'react';
import {useSelector} from 'react-redux';

import {Button, ButtonBase, Paper, Stack, Typography, useTheme} from '@mui/material';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {fontAwesomeIcons} from '../../commons/utils/icons';
import {removeCookie} from '../../commons/utils/cookies';
import {getDashboardAccount} from '../../app/store/reducers/dashboard';
import {getIsStakeAdmin} from '../../app/store/reducers/session';
import {useNavigate} from 'react-router-dom';

const accountsWithAccessToRewards = ['0014S00000D9ddBQAR', '001Pp000009x0qAIAQ', '0014S000004bYPPQA2', '0014S000004aYHhQAM'];

const ProfilePage = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dashboardAccount = useSelector(getDashboardAccount);
  const isStakeAdmin = useSelector(getIsStakeAdmin);

  const rewards = {
    title: 'Rewards',
    description: 'MTD rewards and invoices',
    icon: <FontAwesomeIcon icon={fontAwesomeIcons.faInbox} color={theme.palette.secondary.main} size="xl" />,
    onClick: () => {
      navigate(`/account/${dashboardAccount.sfid}/settings/invoice`);
    }
  };

  const settingsList = [
    {
      title: 'General',
      description: 'Personal information',
      icon: <FontAwesomeIcon icon={fontAwesomeIcons.faGear} color={theme.palette.secondary.main} size="xl" />,
      onClick: () => {
        navigate(`/account/${dashboardAccount.sfid}/settings/general`);
      }
    },
    {
      title: 'Team',
      description: 'Add, invite, and edit ',
      icon: <FontAwesomeIcon icon={fontAwesomeIcons.faUsers} color={theme.palette.secondary.main} size="xl" />,
      onClick: () => {
        navigate(`/account/${dashboardAccount.sfid}/settings/team`);
      }
    }
  ];

  const handleLogOut = () => {
    removeCookie('session_token');
    localStorage.removeItem('account_sfid');
    window.location.href = '/login';
  };

  const buttonsComponents = useMemo(() => {
    if (isStakeAdmin || accountsWithAccessToRewards.includes(dashboardAccount?.sfid)) {
      return [...settingsList, rewards];
    } else {
      return settingsList;
    }
  }, [dashboardAccount, isStakeAdmin]);

  return (
    <Stack
      direction={'column'}
      justifyContent={'space-between'}
      alignItems={'flex-start'}
      sx={{height: 'calc(100vh - 110px - 24px - 24px)'}}
    >
      <Paper sx={{width: '100%', height: 'fit-content', p: 3}}>
        <Stack direction="row" columnGap={3} sx={{width: '100%'}}>
          {buttonsComponents &&
            buttonsComponents.map((setting) => (
              <Button
                key={setting.title}
                variant="outlined"
                onClick={setting.onClick}
                size="large"
                sx={{width: '100%', borderColor: theme.palette.grey[300]}}
              >
                <Stack direction="row" columnGap={3} alignItems="center" sx={{width: '100%'}}>
                  {setting.icon}
                  <Stack direction="column" alignItems={'flex-start'} justifyContent={'center'}>
                    <Typography variant="h4" fontWeight="fontWeightRegular">
                      {setting.title}
                    </Typography>
                    <Typography variant="body1" color={theme.palette.grey[600]}>
                      {setting.description}
                    </Typography>
                  </Stack>
                </Stack>
              </Button>
            ))}
        </Stack>
      </Paper>
      <ButtonBase disableRipple onClick={handleLogOut}>
        <Typography variant="subtitle2" fontWeight={'fontWeightRegular'} sx={{textDecoration: 'underline'}}>
          Log out
        </Typography>
      </ButtonBase>
    </Stack>
  );
};

export default ProfilePage;
