export const hasApplied = [
  'ApplicationPending',
  'ApplicationPendingIDVerification',
  'ApplicationPendingAddressVerification',
  'ApplicationPendingSSNVerification',
  'ApplicationApproved',
  'DepositAccountCreated',
  'DebitCardRequested',
  'DebitCardActivated'
];

export const statusToSentence = (status) => {
  if (status === null || status === undefined) {
    return "Applicant hasn't applied";
  }

  const statusDescriptions = {
    ApplicationApproved: 'The application has been approved',
    Eligible: 'The applicant is eligible',
    ApplicationPendingIDVerification: 'The application is pending ID verification',
    ApplicationPendingAddressVerification: 'The application is pending address verification',
    ApplicationPendingSSNVerification: 'The application is pending SSN verification',
    Ineligible: 'The applicant is not eligible',
    DebitCardActivated: 'The debit card has been activated',
    ApplicationDenied: 'The application has been denied',
    ApplicationPending: 'The application is pending review',
    DebitCardRequested: 'A debit card has been requested'
  };

  return statusDescriptions[status] || 'No data';
};
