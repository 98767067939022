import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import PropTypes from 'prop-types';

import {CircularProgress, Divider, Grid, Paper, Stack, Typography, useTheme} from '@mui/material';

import {getOpenItem} from '../../../../../app/store/reducers/menu';
import {getCampaigns, getCommunities, getResidents} from '../../../../../app/store/reducers/entities';
import {
  getDashboardAccount,
  getDashboardCampaign,
  getDashboardChildAccounts,
  getDashboardCommunity
} from '../../../../../app/store/reducers/dashboard';
import {getCampaignLoadingStatus, getCommunityLoadingStatus, getResidentLoadingStatus} from '../../../../../app/store/reducers/loading';
import {formatNumber} from '../../../../constants/stylingConstants';
import {hasApplied} from '../../../../constants/residentsInfoConstants';
import {ACTIVE_PROPERTY_STATUSES} from '../../../../constants/communityConstants';

const DataItem = ({title, value}) => {
  const theme = useTheme();
  return (
    <Stack direction="column" sx={{p: 2}}>
      <Typography variant="subtitle1" fontWeight="fontWeightRegular" color={theme.palette.text.subtitle}>
        {title}
      </Typography>
      <Typography variant="subtitle1" fontWeight="fontWeightRegular" color={theme.palette.text.primary}>
        {value}
      </Typography>
    </Stack>
  );
};

DataItem.propTypes = {
  title: PropTypes.string,
  value: PropTypes.any
};

const DataSection = ({data}) => (
  <Grid container>
    {data.map((item, index) => (
      <React.Fragment key={index}>
        <Grid item xs={item.xs}>
          <DataItem title={item.title} value={item.value} />
        </Grid>
        {index < data.length - 1 && <Divider orientation="vertical" flexItem sx={{mr: '-1px'}} />}
      </React.Fragment>
    ))}
  </Grid>
);

DataSection.propTypes = {
  data: PropTypes.array
};

const DetailsContent = () => {
  const [generalDataContent, setGeneralDataContent] = useState([]);
  const [residentsData, setResidentsData] = useState([]);

  const openItem = useSelector(getOpenItem);
  const communities = useSelector(getCommunities);
  const campaigns = useSelector(getCampaigns);
  const residents = useSelector(getResidents);
  const dashboardAccount = useSelector(getDashboardAccount);
  const dashboardCommunity = useSelector(getDashboardCommunity);
  const dashboardCampaign = useSelector(getDashboardCampaign);
  const residentsStatus = useSelector(getResidentLoadingStatus);
  const communitiesStatus = useSelector(getCommunityLoadingStatus);
  const campaignsStatus = useSelector(getCampaignLoadingStatus);
  const dashboardChildAccounts = useSelector(getDashboardChildAccounts);

  useEffect(() => {
    const findGeneralDataContent = () => {
      const loadingValue = <CircularProgress size={10} />;
      let contentData = [
        {title: 'Total units', value: loadingValue, xs: 4},
        {title: 'Total Active Stake Units', value: loadingValue, xs: 4},
        {title: 'Stake+ campaigns', value: loadingValue, xs: 4}
      ];

      if (!openItem) return contentData;

      if (openItem === 'communities' && dashboardCommunity.sfid !== 'all') {
        // Community-specific calculations
        const selectedCommunity = communities.find((community) => community.sfid === dashboardCommunity.sfid);
        const totalUnits = selectedCommunity?.total_units__c || 0;
        const isActive = selectedCommunity?.property_status__c && ACTIVE_PROPERTY_STATUSES.includes(selectedCommunity.property_status__c);
        const totalActiveUnits = isActive ? totalUnits : 0;

        const address = selectedCommunity?.full_address__street__s;
        const city = selectedCommunity?.full_address__city__s;
        const state = selectedCommunity?.full_address__statecode__s;
        const zipcode = selectedCommunity?.full_address__postalcode__s;
        const country = selectedCommunity?.full_address__countrycode__s;
        const fullAddress = `${address}, ${city}, ${state} ${zipcode}, ${country}`;
        const isOnbording = selectedCommunity?.property_status__c === 'Onboarding';

        contentData = [
          {title: 'Address', value: fullAddress, xs: 3},
          {title: 'Property status', value: selectedCommunity?.property_status__c, xs: 3},
          {
            title: 'Total units',
            value: !communitiesStatus.isLoading ? (isOnbording ? 'Processing' : formatNumber(totalUnits)) : loadingValue,
            xs: 3
          },
          {
            title: 'Total Active Stake Units',
            value: !communitiesStatus.isLoading ? (isOnbording ? 'Processing' : formatNumber(totalActiveUnits)) : loadingValue,
            xs: 3
          }
        ];
      } else if (openItem === 'portfolio') {
        // Account-specific calculations
        const totalUnits = communities.reduce((acc, community) => acc + community.total_units__c, 0);
        const activeCommunites = communities.filter(
          (community) => community?.property_status__c && ACTIVE_PROPERTY_STATUSES.includes(community.property_status__c)
        );
        const totalActiveUnits = activeCommunites.reduce((acc, community) => acc + community.total_units__c, 0);
        const isProcessing = dashboardAccount?.account_status__c && ['Demoing', 'Onboarding'].includes(dashboardAccount.account_status__c);

        contentData = [
          {title: 'Account status', value: dashboardAccount?.account_status__c, xs: 4},
          {
            title: 'Total units',
            value: !communitiesStatus.isLoading ? (isProcessing ? 'Processing' : formatNumber(totalUnits)) : loadingValue,
            xs: 4
          },
          {
            title: 'Total Active Stake Units',
            value: !communitiesStatus.isLoading ? (isProcessing ? 'Processing' : formatNumber(totalActiveUnits)) : loadingValue,
            xs: 4
          }
        ];
      }

      return contentData;
    };

    setGeneralDataContent(findGeneralDataContent());
  }, [
    openItem,
    communities,
    campaigns,
    residents,
    dashboardCampaign,
    dashboardCommunity,
    dashboardAccount,
    residentsStatus,
    communitiesStatus,
    campaignsStatus,
    dashboardChildAccounts
  ]);

  useEffect(() => {
    const findResidentsDataContent = () => {
      const loadingValue = <CircularProgress size={10} />;

      let contentData = [];

      if (!openItem) return contentData;

      if (openItem === 'communities' && dashboardCommunity.sfid !== 'all') {
        // Community-specific calculations
        const selectedCommunity = communities.find((community) => community.sfid === dashboardCommunity.sfid);
        const isOnbording = selectedCommunity?.property_status__c === 'Onboarding';
        const selectedCampaigns = campaigns.filter((campaign) => campaign.property_sfid === dashboardCommunity.sfid);
        const stakePlusCampaigns = selectedCampaigns
          .filter((campaign) => campaign.subtype && campaign.subtype !== 'Stake1')
          .map((campaign) => campaign.id);

        const totalResidents = residents.filter((resident) => resident.property__c === dashboardCommunity.sfid);
        const stakePlusResidents = totalResidents.filter((res) => res.is_member__c).length;
        const stakeCheckingResidents = totalResidents.filter((resident) => hasApplied.includes(resident.stake_debit_status__c)).length;

        const activeCampaigns = selectedCampaigns.filter(
          (campaign) => campaign.status === 'Active' && stakePlusCampaigns.includes(campaign.id)
        );

        contentData = [
          {
            title: 'Stake+ campaigns',
            value: !campaignsStatus.isLoading ? (isOnbording ? 'Processing' : formatNumber(activeCampaigns.length)) : loadingValue,
            xs: 3
          },
          {
            title: 'Total residents',
            value: !residentsStatus.isLoading ? (isOnbording ? 'Processing' : formatNumber(totalResidents.length)) : loadingValue,
            xs: 3
          },
          {
            title: 'Stake+ residents',
            value: !residentsStatus.isLoading ? (isOnbording ? 'Processing' : formatNumber(stakePlusResidents)) : loadingValue,
            xs: 3
          },
          {
            title: 'Stake Checking residents',
            value: !residentsStatus.isLoading ? (isOnbording ? 'Processing' : formatNumber(stakeCheckingResidents)) : loadingValue,
            xs: 3
          }
        ];
      }

      return contentData;
    };

    setResidentsData(findResidentsDataContent());
  }, [
    openItem,
    communities,
    campaigns,
    residents,
    dashboardCampaign,
    dashboardCommunity,
    dashboardAccount,
    residentsStatus,
    communitiesStatus,
    campaignsStatus,
    dashboardChildAccounts
  ]);

  return (
    <Paper sx={{borderRadius: 2, boxShadow: 'none'}}>
      <Grid container sx={{minWidth: 300}}>
        {[generalDataContent, residentsData].map((dataSection, sectionIndex) => (
          <React.Fragment key={sectionIndex}>
            {sectionIndex > 0 && openItem != 'portfolio' && (
              <Grid item xs={12}>
                <Divider />
              </Grid>
            )}
            <Grid item xs={12}>
              <DataSection data={dataSection} />
            </Grid>
          </React.Fragment>
        ))}
      </Grid>
    </Paper>
  );
};

export default DetailsContent;
