import React, {useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import PropTypes from 'prop-types';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Box, Button, Chip, FormControlLabel, Stack, Switch, Typography} from '@mui/material';
import {getIsStakeAdmin} from '../../../../../app/store/reducers/session';
import {getOpenItem} from '../../../../../app/store/reducers/menu';
import {getCampaigns, getCommunities} from '../../../../../app/store/reducers/entities';
import {useEditReviewMutation} from '../../../../../features/loyaltyCloudApi/reviewsSlice';
import {StyledRating} from '../../../@extended/StyledRating';
import {fontAwesomeIcons} from '../../../../utils/icons';

const ReviewRow = ({row}) => {
  const isStakeAdmin = useSelector(getIsStakeAdmin);
  const openItem = useSelector(getOpenItem);
  const communities = useSelector(getCommunities);
  const campaigns = useSelector(getCampaigns);

  const [isVisible, setVisible] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const slicedText = row.text.length > 300 ? row.text.slice(0, 300) + '...' : row.text;
  const dataForDisplay = expanded ? row.text : slicedText;
  const displayName = row.is_anonymous ? 'Anonymous' : row.name;

  const [editReview] = useEditReviewMutation();

  useEffect(() => {
    setVisible(row.visible_to_client);

    return () => {
      setVisible(null);
    };
  }, [row.visible_to_client]);

  const handleSwitch = (checked) => {
    editReview({id: row.id, data: {visible_to_client: checked}, type: openItem});
    setVisible(checked);
  };

  let visibleSwitchComponent;

  if (isStakeAdmin) {
    visibleSwitchComponent = (
      <Box display="flex">
        <FormControlLabel
          control={<Switch color="secondary" checked={isVisible} onClick={(e) => handleSwitch(e.target.checked)} />}
          label={isVisible ? 'Visible to client' : 'Not visible to client'}
        />
      </Box>
    );
  }

  const chipComponent = useMemo(() => {
    if (openItem == 'portfolio' && row.property_sfid) {
      const community = communities.find((c) => c.sfid === row.property_sfid);
      if (!community) return null;
      return (
        <Chip
          key={`community-chip-${community.sfid}`}
          size="small"
          value={community.sfid}
          label={community.name}
          color="default"
          variant="filled"
          sx={{borderRadius: 16}}
        />
      );
    } else if (openItem == 'communities' && row.campaign_sfid) {
      const campaign = campaigns.find((c) => c.id === row.campaign_sfid);
      if (!campaign) return null;
      return (
        <Chip
          key={`campaign-chip-${campaign.id}`}
          size="small"
          value={campaign.id}
          label={campaign.name}
          color="default"
          variant="filled"
          sx={{borderRadius: 16}}
        />
      );
    } else {
      return null;
    }
  }, [openItem, row, communities, campaigns]);

  return (
    <Stack direction="column" rowGap={1} sx={{width: '100%'}}>
      <Typography variant="h5_light">{displayName}</Typography>
      <Stack direction="row" columnGap={1} alignItems="center">
        <StyledRating
          name="stars-rating"
          value={row.rating}
          precision={0.1}
          icon={<FontAwesomeIcon size="sm" icon={fontAwesomeIcons['faStar']} />}
          emptyIcon={<FontAwesomeIcon size="sm" icon={fontAwesomeIcons['faStarThin']} />}
          readOnly
        />
        <Typography variant="body1">{row.date}</Typography>
      </Stack>
      {visibleSwitchComponent}
      <Stack direction="row" columnGap={1}>
        {chipComponent}
      </Stack>
      <Typography variant="body1" display="block" sx={{whiteSpace: 'pre-wrap', lineHeight: '1.5rem'}}>
        {dataForDisplay}
      </Typography>
      {row.text.length > 300 && (
        <Box display="flex">
          <Button
            type="button"
            sx={{width: 'auto', fontSize: '0.875rem', fontWeight: 400, padding: 0}}
            onClick={() => setExpanded(!expanded)}
          >
            {expanded ? 'See Less' : 'See More'}
          </Button>
        </Box>
      )}
    </Stack>
  );
};

ReviewRow.propTypes = {
  row: PropTypes.object
};

export default ReviewRow;
