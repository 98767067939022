export const formatToDollar = (amount) => {
  return amount.toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0});
};

export const actionsDeterminersObj = {
  'Renewing a lease': 'renewal',
  'Indicating renewal intent': 'intent to renew',
  'Indicating renewal intent (30-90 days out)': 'intent to renew (30-90 days out)',
  'Indicating renewal intent (30-60 days out)': 'intent to renew (30-60 days out)',
  'Indicating renewal intent (61-75 days out)': 'intent to renew (61-75 days out)',
  'Indicating renewal intent (76-90 days out)': 'intent to renew (76-90 days out)',
  'Going on a tour': 'tour',
  'Moving in': 'move-in',
  'Moving in and living for 30 days': 'move-in and live for 30 days',
  'Referring a friend to move to your Rental Community': 'referral',
  move_in_date__c: 'move-in',
  application_date__c: 'apply',
  lease_signed_date__c: 'sign a lease',
  lease_start_date__c: 'lease',
  'Paying rent': 'recurring',
  'Getting current': 'get current',
  'Custom campaign': 'sign a lease',
  'Custom legacy campaign': 'sign a lease'
};

export const qualifiersDeterminersValueObj = {
  move_in_date__c: 'Move-in',
  application_date__c: 'Apply',
  lease_signed_date__c: 'Sign a lease',
  lease_start_date__c: 'Lease',
  'Renewing a lease': 'Renew',
  'Indicating renewal intent': 'Intent to renew',
  'Indicating renewal intent (30-90 days out)': 'Intent to renew (30-90 days out)',
  'Indicating renewal intent (30-60 days out)': 'Intent to renew (30-60 days out)',
  'Indicating renewal intent (61-75 days out)': 'Intent to renew (61-75 days out)',
  'Indicating renewal intent (76-90 days out)': 'Intent to renew (76-90 days out)',
  'Going on a tour': 'Go on a tour and move-in',
  'Moving in': 'Move-in',
  'Moving in and living for 30 days': 'Move-in and live for 30 days',
  'Referring a friend to move to your Rental Community': 'Refer a friend',
  'Paying rent': 'Pay rent',
  'Get current': 'Get current',
  'Getting current': 'Get current',
  'Custom campaign': 'Get a custom campaign',
  'Custom legacy campaign': 'Get a custom legacy campaign'
};

export const activeOffersColors = [
  '#D7EEFA',
  '#FCF0DB',
  '#FEDFD6',
  '#D6F2E1',
  '#FFE9FF',
  '#FFCCCC',
  '#F2ECD6',
  '#F2DCD6',
  '#F1EEDE',
  '#DEE7FF'
];
export const upcomingOffersColors = ['#EDE3F3'];
export const pastOffersColors = ['#E3E3E3'];

export const activeColorsObj = {
  '#D7EEFA': '#149CE0',
  '#FCF0DB': '#EDA62C',
  '#FEDFD6': '#F57045',
  '#EDE3F3': '#722A9F',
  '#D6F2E1': '#00CB52',
  '#FFE9FF': '#F187F2',
  '#FFCCCC': '#F57045',
  '#F2ECD6': '#CCB35A',
  '#F2DCD6': '#B48679',
  '#F1EEDE': '#B4A762',
  '#DEE7FF': '#5C88FF',
  '#E3E3E3': '#787878'
};
