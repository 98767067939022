import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {Navigate, useLocation, useNavigate, useRoutes} from 'react-router-dom';

// project import
import {getAccountSfid, getAuthorizedStatus} from '../app/store/reducers/session';
import {getCookie} from '../commons/utils/cookies';
import MainLayout from '../layout/MainLayout/MainLayout';
import MinimalLayout from '../layout/MinimalLayout/MinimalLayout';
import MainRoutes from './MainRoutes';
import LoginRoutes from './LoginRoutes';
import ProtectedRoute from './ProtectedRoute';

// ==============================|| ROUTING RENDER ||============================== //

const ThemeRoutes = () => {
  const isAuthorized = useSelector(getAuthorizedStatus);
  const accountSfid = useSelector(getAccountSfid);
  const localAccountSfid = localStorage.getItem('account_sfid');

  const sessionToken = getCookie('session_token');
  const isAuthenticated = Boolean(isAuthorized || sessionToken);

  const userAccountSfid = localAccountSfid || accountSfid;
  const publicPaths = ['/login', '/verify_login', '/verify_sso_login'].map((path) => ({
    path: path,
    element: <Navigate to={`/account/${userAccountSfid}`} replace />
  }));

  const routesConfig = [
    {
      path: '/',
      element: isAuthenticated ? (
        <ProtectedRoute>
          <MainLayout />
        </ProtectedRoute>
      ) : (
        <MinimalLayout />
      ),
      children: isAuthenticated
        ? [{path: '/', element: <Navigate to={`/account/${userAccountSfid}`} replace />}, ...publicPaths, ...MainRoutes]
        : [{path: '/', element: <Navigate to={'/login'} replace />}, ...LoginRoutes]
    },
    {path: '*', element: <Navigate to="/" replace />}
  ];

  return useRoutes(routesConfig);
};

export default ThemeRoutes;
