export const divPadding = {
  xxl: 8,
  xl: 6,
  lg: 4,
  md: 3,
  sm: 2,
  xs: 1,
  xxs: 0.5
};

export const formatNumber = (number) => {
  return new Intl.NumberFormat('en-US').format(number);
};

export const findColorFromThePath = (path) => {
  const fillColorArr = path.split(' ').filter((ele) => ele.includes('fill'));
  const fillColor = fillColorArr[0].split('fill=')[1].replace(/"/g, '');
  return fillColor;
};
