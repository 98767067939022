import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';

import {Fade, Paper, Stack} from '@mui/material';

import {StyledMarginWidthDividerSm} from '../../@extended/Divider';
import {useGetReviewsQuery} from '../../../../features/loyaltyCloudApi/reviewsSlice';
import {getIsStakeAdmin} from '../../../../app/store/reducers/session';
import {getOpenItem} from '../../../../app/store/reducers/menu';

import ReviewsHeader from './ReviewsHeader/ReviewsHeader';
import LoadingBox from '../../@extended/LoadingBox';
import ErrorBox from '../../@extended/ErrorBox';
import ReviewsBars from './ReviewsBars/ReviewsBars';
import ReviewsTable from './ReviewsTable/ReviewsTable';

const StakeSayTab = () => {
  const params = useParams();
  const openItem = useSelector(getOpenItem);
  const isStakeAdmin = useSelector(getIsStakeAdmin);

  const reviewParam = useMemo(() => {
    if (params?.campaign_sfid) {
      return {campaign_sfid: params.campaign_sfid};
    } else if (params?.community_sfid) {
      return {property_sfid: params.community_sfid};
    } else if (params?.account_sfid) {
      return {account_sfid: params.account_sfid};
    } else {
      return null;
    }
  }, [params]);

  const {data: reviews, isLoading, isFetching, isSuccess, isError} = useGetReviewsQuery(reviewParam, {skip: !reviewParam});
  const [filterValue, setFilterValue] = useState(0);
  const [displayReviews, setDisplayReviews] = useState([]);

  const indexedReviews = useMemo(() => {
    const obj = {
      0: [],
      1: [],
      2: [],
      3: [],
      4: [],
      5: []
    };

    if (!isLoading && !isFetching && !isError && isSuccess) {
      reviews.forEach((review) => {
        if (isStakeAdmin || review.visible_to_client) {
          obj[review.rating].push(review);
          obj[0].push(review);
        }
      });
    }

    return obj;
  }, [reviews, isStakeAdmin, isLoading, isFetching, isError, isSuccess]);

  useEffect(() => {
    setDisplayReviews(indexedReviews[0]);
  }, [indexedReviews]);

  useEffect(() => {
    setFilterValue(0);
  }, [openItem]);

  const onSelect = useCallback(
    (value) => {
      setFilterValue(value);
      setDisplayReviews(indexedReviews[value]);
    },
    [indexedReviews]
  );

  const component = useMemo(() => {
    if (isLoading || isFetching) {
      return <LoadingBox />;
    } else if (isError) {
      return <ErrorBox />;
    } else if (isSuccess) {
      return (
        <Stack direction={'column'} rowGap={2} divider={<StyledMarginWidthDividerSm />}>
          <ReviewsHeader reviews={[...displayReviews]} reviewsObj={indexedReviews} filterValue={filterValue} onSelect={onSelect} />
          <Fade in={500} timeout={1000}>
            <div>
              <ReviewsBars reviewsObj={indexedReviews} filterValue={filterValue} />
            </div>
          </Fade>
          <ReviewsTable reviews={[...displayReviews]} />
        </Stack>
      );
    }
  }, [isLoading, isFetching, isError, isSuccess, filterValue, displayReviews, indexedReviews, onSelect]);

  return <Paper sx={{p: 2, borderRadius: 2, boxShadow: 'none'}}>{component}</Paper>;
};

export default StakeSayTab;
