import React, {useMemo} from 'react';
import {useSelector} from 'react-redux';

import {Divider, Stack} from '@mui/material';

import {getDashboardCampaign} from '../../../../app/store/reducers/dashboard';
import ResidentsTable from './ResidentsTable';
import ContactLists from './ContactLists';

const CrmTab = () => {
  const dashboardCampaign = useSelector(getDashboardCampaign);
  const isRenewal = useMemo(() => {
    if (dashboardCampaign && dashboardCampaign.subtype) {
      return dashboardCampaign.subtype.toLowerCase().includes('renewal');
    }
    return false;
  }, [dashboardCampaign]);

  return (
    <Stack direction={'column'} rowGap={3} sx={{width: '100%'}} divider={<Divider />}>
      {/* {isRenewal && <ContactLists />} */}
      <ResidentsTable />
    </Stack>
  );
};

export default CrmTab;
