import React from 'react';
import {useSelector} from 'react-redux';
import {getOpenItem} from '../../../../app/store/reducers/menu';
import CampaignPerformance from './CampaignPerformance';
import CommunityPerformance from './CommunityPerformance';
import AccountPerformance from './AccountPerformance';

const PerformanceTab = () => {
  const openItem = useSelector(getOpenItem);

  switch (openItem) {
    case 'campaigns':
      return <CampaignPerformance />;
    case 'communities':
      return <CommunityPerformance />;
    case 'portfolio':
      return <AccountPerformance />;
    default:
      return <div></div>;
  }
};

export default PerformanceTab;
