import React, {useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';

import {Button, FormControl, MenuItem, Select, Stack, Typography} from '@mui/material';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {getIsStakeAdmin} from '../../../../../app/store/reducers/session';
import {fontAwesomeIcons} from '../../../../utils/icons';
import {StyledRating} from '../../../@extended/StyledRating';

import AddReviewModal from '../AddReviewModal';

const reviewTypes = [
  {name: 'All Reviews', value: 0},
  {name: '1 star', value: 1},
  {name: '2 stars', value: 2},
  {name: '3 stars', value: 3},
  {name: '4 stars', value: 4},
  {name: '5 stars', value: 5}
];

const ReviewsHeader = ({reviews, reviewsObj, filterValue, onSelect}) => {
  const [isAddModalOpen, setAddModalOpen] = useState(false);
  const isStakeAdmin = useSelector(getIsStakeAdmin);

  const ratingInfo = useMemo(() => {
    if (reviews.length > 0) {
      const rating = reviews.reduce((acc, review) => acc + review.rating, 0) / reviews.length;
      return rating;
    } else {
      return 0;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reviews]);

  return (
    <>
      <Stack direction="row" justifyContent={'space-between'} alignItems={'center'}>
        <Stack direction={'column'} rowGap={1}>
          <Typography variant="h5" fontWeight="fontWeightMedium">
            Resident feedback
          </Typography>
          <Stack direction={'row'} columnGap={1} alignItems={'center'}>
            <StyledRating
              name="reviews-rating"
              value={ratingInfo}
              precision={0.1}
              icon={<FontAwesomeIcon size="sm" icon={fontAwesomeIcons['faStar']} />}
              emptyIcon={<FontAwesomeIcon size="sm" icon={fontAwesomeIcons['faStarThin']} />}
              readOnly
            />
            <Typography variant="body1">{ratingInfo.toFixed(1)} out of 5</Typography>
          </Stack>
          <Typography variant="body1" color={'text.subtitle'}>
            {reviews.length === 1 ? '1 review' : `${reviews.length} reviews`}
          </Typography>
        </Stack>
        {isStakeAdmin && (
          <Stack direction={'row'} columnGap={1} sx={{height: 44}}>
            <Button
              variant="outlined"
              sx={{height: '100%', fontSize: '0.875rem'}}
              endIcon={<FontAwesomeIcon icon={fontAwesomeIcons['faCirclePlus']} />}
              onClick={() => setAddModalOpen(true)}
            >
              Add review
            </Button>
            <FormControl sx={{height: '100%'}}>
              <Select
                value={filterValue}
                onChange={(e) => onSelect(e.target.value)}
                sx={{
                  '& .MuiSelect-select': {
                    padding: '12px 14px',
                    display: 'flex',
                    alignItems: 'center'
                  },
                  '& .MuiOutlinedInput-root': {
                    height: '100%'
                  }
                }}
              >
                {reviewTypes.map((type) => (
                  <MenuItem key={type.value} value={type.value} disabled={reviewsObj[type.value].length === 0}>
                    {type.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
        )}
      </Stack>
      <AddReviewModal open={isAddModalOpen} setOpen={setAddModalOpen} />
    </>
  );
};

ReviewsHeader.propTypes = {
  reviews: PropTypes.array,
  filterValue: PropTypes.number,
  setFilterValue: PropTypes.func,
  reviewsObj: PropTypes.object,
  onSelect: PropTypes.func
};

export default ReviewsHeader;
