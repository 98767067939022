import React from 'react';
import PropTypes from 'prop-types';

import {Paper, Stack, Typography, Tooltip, IconButton} from '@mui/material';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {fontAwesomeIcons} from '../../../utils/icons';

const FinancialUnlockBox = ({data}) => {
  const displayTooltop = data.display_tooltip ?? false;
  const title = data.title ?? '';
  let tooltipText = data.tooltip_text ?? '';
  const value = data.value ?? '';

  let tooltip = (
    <Tooltip title="" arrow>
      <IconButton size="large" sx={{height: '40px', visibility: 'hidden', position: 'absolute', top: '2px', right: '8px'}} />
    </Tooltip>
  );

  if (displayTooltop) {
    tooltipText = (
      <Stack direction="column" rowGap={1} sx={{padding: 1}}>
        {tooltipText.split('. ').map((str, idx) => (
          <Typography
            key={`tooltip-${idx}`}
            variant="subtitle2"
            sx={{textAlign: 'left', lineHeight: '1.4rem', paddingTop: 0.5, color: '#F3F3F3'}}
          >
            {str}
          </Typography>
        ))}
      </Stack>
    );

    tooltip = (
      <Tooltip title={tooltipText} arrow>
        <IconButton size="medium" disableRipple sx={{height: '40px', position: 'absolute', top: '2px', right: '14px'}}>
          <FontAwesomeIcon icon={fontAwesomeIcons['faCircleQuestionLight']} color="#2C2C2C" />
        </IconButton>
      </Tooltip>
    );
  }

  return (
    <Paper
      sx={{
        height: '140px',
        flex: '1 1 0',
        position: 'relative',
        borderRadius: '8px',
        backgroundColor: 'rgba(206, 235, 205, 0.4)',
        boxShadow: 'none'
      }}
    >
      {tooltip}
      <Stack direction="column" alignItems="center" justifyContent="center" rowGap={1} sx={{height: '100%'}}>
        <Typography variant="h2" fontWeight={'fontWeightRegular'} sx={{color: '#00CB52'}}>
          {value}
        </Typography>
        <Typography variant="h4" fontWeight={'fontWeightRegular'} sx={{lineHeight: '1.5rem'}}>
          {title}
        </Typography>
      </Stack>
    </Paper>
  );
};

FinancialUnlockBox.propTypes = {
  data: PropTypes.object
};

export default FinancialUnlockBox;
