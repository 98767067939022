import React, {useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {activeModal, getOpenModal} from '../../../app/store/reducers/menu';

import ResidentPage from '../../../pages/Resident/ResidentPage';
import TeamMemberPage from '../Profile/Team/TeamMemberPage';
import NewTeamMemberPage from '../Profile/Team/NewTeamMemberPage';

const ActiveModal = () => {
  const dispatch = useDispatch();
  const openModal = useSelector(getOpenModal);
  const isOpen = useMemo(() => openModal?.type !== null, [openModal]);

  const handleClose = () => {
    dispatch(activeModal({type: null, sfid: null}));
  };

  switch (openModal?.type) {
    case 'resident_info':
      return <ResidentPage sfid={openModal.sfid} handleClose={handleClose} isOpen={isOpen} />;
    case 'team_member_info':
      return <TeamMemberPage sfid={openModal.sfid} handleClose={handleClose} isOpen={isOpen} />;
    case 'new_team_member_info':
      return <NewTeamMemberPage handleClose={handleClose} isOpen={isOpen} />;
    default:
      return null;
  }
};

export default ActiveModal;
