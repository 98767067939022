import React, {useMemo} from 'react';
import {useSelector} from 'react-redux';
import dayjs from 'dayjs';

import {Paper} from '@mui/material';

import {getDashboardAccount, getDashboardCommunity} from '../../../../app/store/reducers/dashboard';
import {useGetClaimedRateByAccountQuery} from '../../../../features/coreApi/metricsSlice';

import ErrorBox from '../../@extended/ErrorBox';
import LoadingBox from '../../@extended/LoadingBox';
import {ClaimedRateComponent} from './ClaimedRateHeaderInfo';

const ClaimedRateInfo = () => {
  const dashboadrAccount = useSelector(getDashboardAccount);
  const dashboardCommunity = useSelector(getDashboardCommunity);
  const today = dayjs().endOf('day');
  const nDaysAgo = dayjs().subtract(30, 'day').startOf('day');
  const startDateStr = nDaysAgo.format('YYYY-MM-DD');
  const endDateStr = today.format('YYYY-MM-DD');

  const {data, isLoading, isFetching, isError, isSuccess} = useGetClaimedRateByAccountQuery(
    {
      sfid: dashboadrAccount?.sfid,
      startDate: startDateStr,
      endDate: endDateStr
    },
    {skip: !dashboadrAccount?.sfid || !startDateStr || !endDateStr}
  );

  const showingData = useMemo(() => {
    if (isSuccess && data?.is_supported) {
      if (dashboardCommunity?.sfid && dashboardCommunity.sfid != 'all') {
        const communityData = data.data.communities.filter((item) => item.sfid === dashboardCommunity.sfid);
        return communityData[0];
      } else {
        return data.data.general_info;
      }
    }
  }, [data, isSuccess, dashboadrAccount, dashboardCommunity]);

  const showingResData = useMemo(() => {
    if (isSuccess && data?.is_supported) {
      if (dashboardCommunity?.sfid && dashboardCommunity.sfid != 'all') {
        const residentsList = data.data.resident_list.filter((item) => item.community_sfid === dashboardCommunity.sfid);
        return residentsList;
      } else {
        return data.data.resident_list;
      }
    }
  }, [data, isSuccess, dashboadrAccount, dashboardCommunity]);

  const communitiesData = useMemo(() => {
    if (isSuccess && data?.is_supported) {
      return data.data.communities;
    } else {
      return [];
    }
  }, [data, isSuccess]);

  const mainComponent = useMemo(() => {
    if (isFetching || isLoading) {
      return <LoadingBox />;
    } else if (isError) {
      return <ErrorBox />;
    } else if (isSuccess) {
      return <ClaimedRateComponent data={showingData} communitiesData={communitiesData} residents={showingResData} />;
    }
  }, [isLoading, isFetching, isError, isSuccess, showingData]);

  if (isSuccess && data && !data.is_supported) {
    return null;
  }

  return <Paper sx={{p: 2, borderRadius: 2, boxShadow: 'none'}}>{mainComponent}</Paper>;
};

export default ClaimedRateInfo;
