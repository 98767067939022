import React from 'react';

import {Typography, Stack, Paper} from '@mui/material';
import CurrentInvoice from './CurrentInvoice';
import PastInvoices from './PastInvoices';

const Invoice = () => {
  return (
    <Stack direction={'column'} sx={{width: '100%', height: '100%'}} rowGap={3}>
      <Typography variant="h4" fontWeight={'fontWeightRegular'}>
        MTD rewards and invoices
      </Typography>
      <Paper
        sx={{
          width: '100%',
          height: 'fit-content',
          p: 3,
          borderRadius: '8px',
          boxShadow: 'none'
        }}
      >
        <Typography variant="body2" color={'text.subtitle'} fontWeight={'fontWeightRegular'}>
          We are currently updating MTD rewards data in Loyalty Cloud, please check back soon or with your account maanger.
        </Typography>
      </Paper>
      {/* <CurrentInvoice /> */}
      <PastInvoices />
    </Stack>
  );
};

export default Invoice;
