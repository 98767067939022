import React, {memo, useEffect, useMemo} from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import * as Yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {FormProvider, useForm, useFormContext} from 'react-hook-form';

import {Dialog, DialogContent, Stack, Typography, useTheme} from '@mui/material';

import {getCommunities} from '../../../../app/store/reducers/entities';
import {DialogTitleWithCloseIcon} from '../../@extended/CustomDialogs';
import {StyledMarginWidthDividerSm} from '../../@extended/Divider';
import {ChipFieldArray, FormInput, SimpleFormSelectWithLabel} from '../../@extended/Forms';
import validateEmail from '../../../utils/emailValidation';
import {LoadingButton} from '@mui/lab';
import {useAddTeamMemberMutation} from '../../../../features/loyaltyCloudApi/apiSlice';
import {getEmail} from '../../../../app/store/reducers/session';
import {getDashboardAccount} from '../../../../app/store/reducers/dashboard';

const teamMemberSchema = Yup.object().shape({
  first_name: Yup.string().required('First Name is required').min(1),
  last_name: Yup.string().required('Last Name is required').min(1),
  email: Yup.string()
    .required('Email is required')
    .test('is-valid-email', 'Email is invalid', (value) => validateEmail(value?.replace(/\s/g, ''))),
  role: Yup.string().required('Role is required').min(1),
  property_permissions: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.string().required(),
        name: Yup.string().required()
      })
    )
    .min(1, 'At least one property permission is required')
    .required('Property Permissions is required')
});

const roleOptions = [
  {id: 'Administrator', name: 'Administrator'},
  {id: 'Property Manager', name: 'Property Manager'},
  {id: 'Leasing Agent', name: 'Leasing Agent'}
];

const PropertyPermissionsSection = memo(({availableProperties}) => {
  const theme = useTheme();
  return (
    <Stack
      direction="column"
      sx={{border: `1px solid ${theme.palette.grey[300]}`, borderRadius: '8px', p: 2}}
      divider={<StyledMarginWidthDividerSm />}
      rowGap={2}
    >
      <Typography variant="h6" color="text.subtitle">
        Property Permissions
      </Typography>
      <ChipFieldArray name="property_permissions" availableProperties={availableProperties} />
    </Stack>
  );
});

const FormSection = () => {
  const theme = useTheme();
  return (
    <Stack direction="row" sx={{border: `1px solid ${theme.palette.grey[300]}`, borderRadius: '8px', p: 2}} columnGap={2}>
      <FormInput name="first_name" label="First Name" />
      <FormInput name="last_name" label="Last Name" />
      <FormInput name="email" label="Email" />
      <SimpleFormSelectWithLabel name="role" options={[{id: 'default', name: 'Select a role'}, ...roleOptions]} />
    </Stack>
  );
};

const SaveButton = ({isLoading}) => {
  const {
    formState: {isValid, isDirty}
  } = useFormContext();
  return (
    <Stack direction="row" justifyContent="flex-end">
      <LoadingButton loading={isLoading} type="submit" variant="contained" color="secondary" disabled={!isValid || !isDirty}>
        Save changes
      </LoadingButton>
    </Stack>
  );
};

const NewTeamMemberPage = ({handleClose, isOpen}) => {
  const communities = useSelector(getCommunities);
  const email = useSelector(getEmail);
  const dashboardAccount = useSelector(getDashboardAccount);
  const [addTeamMember, {isSuccess, isLoading, isError}] = useAddTeamMemberMutation();

  const defaultValues = useMemo(() => {
    return {
      first_name: '',
      last_name: '',
      email: '',
      role: '',
      property_permissions: []
    };
  }, []);

  const methods = useForm({
    mode: 'onBlur',
    defaultValues,
    resolver: yupResolver(teamMemberSchema)
  });

  const {handleSubmit} = methods;

  const onSubmit = (data) => {
    let dataForm = new FormData();
    const permissions = data.property_permissions.map((p) => p.id).join(',');

    dataForm.append('first_name', data.first_name);
    dataForm.append('last_name', data.last_name);
    dataForm.append('email', data.email);
    dataForm.append('role', data.role);
    dataForm.append('permissions', permissions);
    dataForm.append('company_name', dashboardAccount.name);
    dataForm.append('added_by', email);
    dataForm.append('associated_sfid', dashboardAccount.sfid);
    dataForm.append('loyalty_cloud_account_id', '');
    dataForm.append('notifications_campaign_updates_email', 'false');
    dataForm.append('notifications_campaign_updates_text', 'false');

    addTeamMember(dataForm);
  };

  useEffect(() => {
    if (isSuccess && !isLoading && !isError) {
      handleClose();
    }
  }, [isSuccess, isLoading, isError]);

  const PropertyPermissionsSectionMemo = useMemo(
    () => <PropertyPermissionsSection availableProperties={communities.map((c) => ({id: c.sfid, name: c.name}))} />,
    [communities]
  );

  const DialogHeaderMemo = useMemo(() => {
    return (
      <Stack direction="row" columnGap={3}>
        Add a New Team Member
      </Stack>
    );
  }, []);

  return (
    <Dialog open={isOpen} onClose={handleClose} fullWidth maxWidth="xl">
      <DialogTitleWithCloseIcon onClose={handleClose} sx={{pt: 3}}>
        {DialogHeaderMemo}
      </DialogTitleWithCloseIcon>
      <DialogContent dividers>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack direction="column" rowGap={2} sx={{width: '100%'}}>
              <FormSection />
              {PropertyPermissionsSectionMemo}
              <SaveButton isLoading={isLoading} />
            </Stack>
          </form>
        </FormProvider>
      </DialogContent>
    </Dialog>
  );
};

NewTeamMemberPage.propTypes = {
  sfid: PropTypes.string,
  handleClose: PropTypes.func,
  isOpen: PropTypes.bool
};

export default NewTeamMemberPage;
