import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import dayjs from 'dayjs';

import {Chip, Paper, Stack, Typography, Tooltip, ButtonBase, useTheme, Grid, Divider} from '@mui/material';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {fontAwesomeIcons} from '../../../../utils/icons';
import {getResidents} from '../../../../../app/store/reducers/entities';
import {formatNumber} from '../../../../constants/stylingConstants';
import {formatDateString} from '../../../../constants/dateConstants';
import {hasApplied} from '../../../../constants/residentsInfoConstants';

const MultilineTooltipContent = ({items}) => (
  <>
    {items.map((item, index) => (
      <Typography key={index} sx={{fontSize: '14px', lineHeight: '21px'}}>
        {item}
      </Typography>
    ))}
  </>
);

MultilineTooltipContent.propTypes = {
  items: PropTypes.array
};

const CampaignInfo = ({campaign}) => {
  const theme = useTheme();
  const residents = useSelector(getResidents);

  const pageInfo = useMemo(() => {
    if (campaign) {
      const createdComponent = <Typography variant="subtitle2_light">Created {dayjs(campaign.createddate).format('ll')}.</Typography>;
      const chipComponent = <Chip label="Active" variant="outlined" color="secondary" sx={{ml: 1}} />;
      const leaseTermMin = campaign?.target?.lease_term_months_min ? campaign.target.lease_term_months_min : 0;

      const dateObj = new Date(formatDateString(campaign.start_date));
      const dateCreated = dateObj.toLocaleDateString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      });

      let unitTypes = campaign.target?.unit_types.split(';');
      if (unitTypes.length > 1) {
        unitTypes = {'All units': unitTypes.sort((a, b) => a - b)};
      } else {
        unitTypes = {[unitTypes[0]]: []};
      }

      return {
        createdComponent,
        chipComponent,
        dateCreated,
        unitTypes,
        leaseTermMin
      };
    } else {
      return {
        createdComponent: null,
        chipComponent: null,
        dateCreated: null,
        unitTypes: {'': []},
        leaseTermMin: null
      };
    }
  }, [campaign]);

  const unitTypesComponent = useMemo(() => {
    const unitKey = Object.keys(pageInfo.unitTypes)[0];
    if (unitKey) {
      const unitTypesValues = pageInfo.unitTypes[unitKey];
      if (unitTypesValues.length > 1) {
        return (
          <Stack direction="row" columnGap={1} alignItems={'center'}>
            <Typography variant="h6">{`${unitTypesValues.length} floor` + ` ${unitTypesValues.length == 1 ? 'plan' : 'plans'}`}</Typography>
            <Tooltip
              title={<MultilineTooltipContent items={unitTypesValues} />}
              sx={{
                maxWidth: 270,
                background: theme.palette.grey[600]
              }}
              placement="bottom"
              arrow
            >
              <ButtonBase
                sx={{
                  width: 12,
                  height: 12,
                  cursor: 'default'
                }}
              >
                <FontAwesomeIcon icon={fontAwesomeIcons['faEye']} />
              </ButtonBase>
            </Tooltip>
          </Stack>
        );
      } else {
        return <Typography variant="h6">{unitKey}</Typography>;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageInfo.unitTypes]);

  const residentsInfo = useMemo(() => {
    // finding details of selected campaign
    const isStakePlus = campaign?.subtype && campaign?.subtype != 'Stake1';

    const totalResidents = residents.filter((resident) => resident.renter_campaign__c === campaign.id).length;
    const stakePlusResidents =
      campaign?.subtype && campaign?.subtype != 'Stake1'
        ? residents.filter((resident) => resident.renter_campaign__c === campaign.id).length
        : 0;
    const resObj = isStakePlus
      ? {title: 'Stake+ residents', value: formatNumber(stakePlusResidents)}
      : {title: 'Total residents', value: formatNumber(totalResidents)};

    return resObj;
  }, [campaign, residents]);

  const stakeCheckingResidents = useMemo(() => {
    return residents.filter(
      (resident) => hasApplied.includes(resident.stake_debit_status__c) && campaign.sfid == resident.renter_campaign__c
    ).length;
  }, [residents, campaign]);

  const mainComponent = useMemo(() => {
    const isDraft = campaign && campaign.status.toLowerCase() === 'draft';
    const width = isDraft ? 2 : 1.7;
    const campaign_type = campaign?.name
      ? campaign?.name.includes('StakePro')
        ? 'StakePro'
        : campaign?.name.includes('StakeFree')
          ? 'StakeFree'
          : campaign?.subtype
      : campaign?.subtype;

    return (
      <Paper sx={{p: 0, borderRadius: 2, boxShadow: 'none'}}>
        <Grid container sx={{width: '100%', padding: 2}} columnSpacing={2}>
          <Grid item xs={width}>
            <Stack direction="column">
              <Typography variant="subtitle2" fontWeight={'fontWeightRegular'} color={'text.subtitle'}>
                Type
              </Typography>
              <Typography variant="h6">{campaign_type}</Typography>
            </Stack>
          </Grid>
          <Divider orientation="vertical" flexItem sx={{m: '-16px 0', mr: '-1px'}} />
          <Grid item xs={width}>
            <Stack direction="column">
              <Typography variant="subtitle2" fontWeight={'fontWeightRegular'} color={'text.subtitle'}>
                Status
              </Typography>
              <Typography variant="h6">{campaign.status}</Typography>
            </Stack>
          </Grid>
          {!isDraft && (
            <>
              <Divider orientation="vertical" flexItem sx={{m: '-16px 0', mr: '-1px'}} />
              <Grid item xs={width}>
                <Stack direction="column">
                  <Typography variant="subtitle2" fontWeight={'fontWeightRegular'} color={'text.subtitle'}>
                    Date created
                  </Typography>
                  <Typography variant="h6">{pageInfo.dateCreated}</Typography>
                </Stack>
              </Grid>
            </>
          )}
          <Divider orientation="vertical" flexItem sx={{m: '-16px 0', mr: '-1px'}} />
          <Grid item xs={width}>
            <Stack direction="column">
              <Typography variant="subtitle2" fontWeight={'fontWeightRegular'} color={'text.subtitle'}>
                Unit type
              </Typography>
              {unitTypesComponent}
            </Stack>
          </Grid>
          <Divider orientation="vertical" flexItem sx={{m: '-16px 0', mr: '-1px'}} />
          <Grid item xs={width}>
            <Stack direction="column">
              <Typography variant="subtitle2" fontWeight={'fontWeightRegular'} color={'text.subtitle'}>
                {residentsInfo?.title}
              </Typography>
              {residentsInfo?.value}
            </Stack>
          </Grid>
          <Divider orientation="vertical" flexItem sx={{m: '-16px 0', mr: '-1px'}} />
          <Grid item xs={width}>
            <Stack direction="column">
              <Typography variant="subtitle2" fontWeight={'fontWeightRegular'} color={'text.subtitle'}>
                Lease term minimum
              </Typography>
              {pageInfo?.leaseTermMin}
            </Stack>
          </Grid>
          <Divider orientation="vertical" flexItem sx={{m: '-16px 0', mr: '-1px'}} />
          <Grid item xs={width}>
            <Stack direction="column">
              <Typography variant="subtitle2" fontWeight={'fontWeightRegular'} color={'text.subtitle'}>
                Stake Checking residents
              </Typography>
              {stakeCheckingResidents}
            </Stack>
          </Grid>
        </Grid>
      </Paper>
    );
  }, [pageInfo, campaign, unitTypesComponent]);

  return mainComponent;
};

CampaignInfo.propTypes = {
  campaign: PropTypes.object
};

export default CampaignInfo;
