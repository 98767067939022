import React, {useEffect, useMemo} from 'react';
import {useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';

import {Accordion, AccordionDetails, AccordionSummary, Button, Paper, Stack, Typography} from '@mui/material';

import {useLazyGetPastInvoicesListQuery} from '../../../../features/coreApi/apiSlice';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {StyledMarginWidthDividerSm} from '../../@extended/Divider';
import EmptyBox from '../../@extended/EmptyBox';
import LoadingBox from '../../@extended/LoadingBox';

import {getDashboardAccount, getDashboardCommunities} from '../../../../app/store/reducers/dashboard';
import {fontAwesomeIcons} from '../../../utils/icons';
import CONSTANTS from '../../../constants/envConstants';
import {getIsStakeAdmin, getTeamMemberRole} from '../../../../app/store/reducers/session';

const CommunityInvoices = ({data}) => {
  const handleClick = async (path) => {
    try {
      const encodedFilePath = encodeURIComponent(path);
      const response = await axios({
        url: CONSTANTS.BASE_API_URL + 'invoice/download',
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          dealsAPIKey: CONSTANTS.BASE_API_KEY
        },
        params: {file_path: encodedFilePath},
        responseType: 'blob'
      });

      // Get the filename from the Content-Disposition header if available
      const contentDisposition = response.headers['content-disposition'];
      let filename = path.split('/').pop();
      if (contentDisposition) {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(contentDisposition);
        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, '');
        }
      }

      // Create a blob from the response data
      const blob = new Blob([response.data], {type: response.headers['content-type']});

      // Create a temporary URL for the blob
      const url = window.URL.createObjectURL(blob);

      // Create a temporary anchor element and trigger the download
      const a = document.createElement('a');
      a.href = url;
      a.setAttribute('download', filename);
      document.body.appendChild(a);
      a.click();

      // Clean up
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.error('Error downloading Excel file:', error);
      alert('Failed to download Excel file. Please try again.');
    }
  };

  return (
    <Stack direction={'column'} rowGap={2} justifyContent={'center'} divider={<StyledMarginWidthDividerSm />}>
      {data.map((invoice, idx) => {
        return (
          <Stack key={`invoice-${idx}`} direction={'row'} justifyContent={'space-between'}>
            <Typography variant="h6" fontWeight={'fontWeightRegular'} color={'text.subtitle'}>
              {invoice.period}
            </Typography>
            <Button variant="text" color="secondary" size="small" onClick={() => handleClick(invoice.path)}>
              Download
            </Button>
          </Stack>
        );
      })}
    </Stack>
  );
};

CommunityInvoices.propTypes = {
  data: PropTypes.array
};

const PastInvoices = () => {
  const dashboardAccount = useSelector(getDashboardAccount);
  const isStakeAdmin = useSelector(getIsStakeAdmin);
  const userRole = useSelector(getTeamMemberRole);
  const dashboardCommunities = useSelector(getDashboardCommunities);

  const [fetchPastInvoices, {data: pastInvoices = {}, isError, isLoading, isFetching, isSuccess}] = useLazyGetPastInvoicesListQuery();

  useEffect(() => {
    if (dashboardAccount && dashboardAccount.sfid) {
      fetchPastInvoices(dashboardAccount.sfid);
    }
  }, [dashboardAccount]);

  const listComponent = useMemo(() => {
    if (isSuccess) {
      const filteredInvoices = {};

      for (const key in pastInvoices) {
        if (userRole != 'Administrator' && !isStakeAdmin) {
          for (const community of dashboardCommunities) {
            if (community.name == key) {
              filteredInvoices[community.name] = pastInvoices[key];
            }
          }
        } else {
          filteredInvoices[key] = pastInvoices[key];
        }
      }

      return (
        <div>
          {Object.keys(filteredInvoices)
            .sort((a, b) => a.localeCompare(b, undefined, {sensitivity: 'base'}))
            .map((communityName) => {
              return (
                <Accordion
                  key={communityName.replace(' ', '-').toLowerCase()}
                  sx={{
                    borderRadius: 0,
                    boxShadow: 'none',
                    '&.Mui-expanded': {
                      margin: 0
                    },
                    '&:before': {
                      display: 'none'
                    },
                    '&:first-of-type': {
                      borderTopLeftRadius: 0,
                      borderTopRightRadius: 0
                    },
                    '&:last-of-type': {
                      borderBottomLeftRadius: 0,
                      borderBottomRightRadius: 0
                    }
                  }}
                >
                  <AccordionSummary
                    expandIcon={<FontAwesomeIcon icon={fontAwesomeIcons.faChevronDown} />}
                    sx={{flexDirection: 'row-reverse'}}
                  >
                    <Typography variant="h6" fontWeight={'fontWeightRegular'} sx={{pl: 2}}>
                      {communityName}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{borderTop: '1px solid rgba(0, 0, 0, .125)', p: '16px'}}>
                    <CommunityInvoices data={pastInvoices[communityName]} />
                  </AccordionDetails>
                </Accordion>
              );
            })}
        </div>
      );
    }
  }, [isLoading, isFetching, isSuccess, isError, dashboardCommunities, pastInvoices, userRole, isStakeAdmin]);

  const mainComponent = useMemo(() => {
    if (isLoading || isFetching) {
      return <LoadingBox />;
    } else if (isSuccess && Object.keys(pastInvoices).length > 0) {
      return (
        <Stack direction={'column'} sx={{width: '100%', height: '100%'}}>
          <Paper
            sx={{
              width: '100%',
              height: 'fit-content',
              p: 3,
              borderRadius: '8px',
              boxShadow: 'none',
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0
            }}
          >
            <Typography variant="h5" fontWeight={'fontWeightMedium'}>
              Past invoices
            </Typography>
          </Paper>
          {listComponent}
        </Stack>
      );
    } else {
      return (
        <Paper
          sx={{
            width: '100%',
            height: 'fit-content',
            borderRadius: '8px',
            boxShadow: 'none'
          }}
        >
          <EmptyBox text={'Past invoices are not found'} height="100px" />
        </Paper>
      );
    }
  }, [isLoading, isFetching, isSuccess, isError, listComponent, pastInvoices]);

  return mainComponent;
};

export default PastInvoices;
