import React from 'react';
import PropTypes from 'prop-types';

import {Paper, Stack, Typography, Chip, Divider} from '@mui/material';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {fontAwesomeIcons} from '../../../utils/icons';
import {findColorFromThePath} from '../../../constants/stylingConstants';

const StakerInsightsBox = ({data}) => {
  const description = data.description ?? '';
  const icon = data.icon ?? null;
  const title = data.title ?? '';
  const value = data.value ?? '';
  const iconElement = data.icon_element ?? null;

  const renderIcon = (name, iconElement) => {
    const color = findColorFromThePath(iconElement);

    if (fontAwesomeIcons[name]) {
      return <FontAwesomeIcon icon={fontAwesomeIcons[name]} size="3x" color={color} />;
    }

    return null;
  };

  const renderedIcon = icon !== null ? renderIcon(icon, iconElement) : null;

  return (
    <Paper sx={{bgcolor: '#FCFCFC', flex: '1 1 0', border: '1px solid #E3E3E3', borderRadius: '8px', maxWidth: '33%'}}>
      <Stack direction="column" sx={{height: '270px'}} divider={<Divider sx={{marginLeft: '0px', marginRight: '0px'}} />}>
        <Stack direction="column" alignItems="center" rowGap={1} sx={{flexGrow: 1, padding: 3, height: '60%'}}>
          {renderedIcon}
          <Typography variant="h4" fontWeight={'fontWeightRegular'}>
            {title}
          </Typography>
          <Typography
            variant="subtitle1"
            fontWeight={'fontWeightRegular'}
            color={'text.subtitle'}
            sx={{textAlign: 'center', lineHeight: '1.4rem', maxWidth: '300px'}}
          >
            {description}
          </Typography>
        </Stack>
        <Stack direction="column" alignItems="center" justifyContent="center" sx={{height: '70px'}}>
          <Chip
            key={`chip-${title}`}
            variant="filled"
            label={value}
            sx={{backgroundColor: 'rgba(160, 217, 159, 0.5)', color: '#11A10E', borderRadius: '24px'}}
          />
        </Stack>
      </Stack>
    </Paper>
  );
};

StakerInsightsBox.propTypes = {
  data: PropTypes.object
};

export default StakerInsightsBox;
