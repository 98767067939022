import React, {useCallback, useMemo} from 'react';
import {useSelector} from 'react-redux';

import {Stack} from '@mui/material';

import AccountDashboard from './AccountDashboard';
import CommunityDashboard from './CommunityDashboard';
import CampaignDashboard from './CampaignDashboard';
import SettingsHeader from './SettingsHeader/SettingsHeader';

import {settingsIds} from '../Profile/utils';
import {getDashboardCommunity} from '../../../app/store/reducers/dashboard';
import {getOpenComponent} from '../../../app/store/reducers/menu';
import {getCommunities} from '../../../app/store/reducers/entities';
import {getAccountsPermissions, getIsStakeAdmin} from '../../../app/store/reducers/session';
import {useLocation, useNavigate} from 'react-router-dom';

const MenuDashboard = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const communities = useSelector(getCommunities);
  const isStakeAdmin = useSelector(getIsStakeAdmin);
  const accountsPermissions = useSelector(getAccountsPermissions);

  const openComponent = useSelector(getOpenComponent);
  const dashboardCommunity = useSelector(getDashboardCommunity);

  const isSettingsOpen = settingsIds.includes(openComponent);

  const createHandleMenuClick = useCallback(
    (menuItem, type) => {
      const lowerCaseType = type.toLowerCase();
      let accountSfid;
      let communitySfid;

      if (lowerCaseType !== 'portfolio') {
        accountSfid = localStorage.getItem('account_sfid');
        communitySfid = menuItem?.property_sfid || dashboardCommunity.sfid;
      }

      if (lowerCaseType === 'campaigns') {
        if (menuItem.sfid !== 'all') {
          navigate(`account/${accountSfid}/community/${communitySfid}/campaign/${menuItem.sfid}`);
        } else if (communitySfid !== 'all') {
          navigate(`account/${accountSfid}/community/${communitySfid}`);
        } else {
          navigate(`account/${accountSfid}`);
        }
      } else if (lowerCaseType === 'communities') {
        if (menuItem.sfid !== 'all') {
          navigate(`account/${accountSfid}/community/${menuItem.sfid}`);
        } else {
          navigate(`account/${accountSfid}`);
        }
      } else if (lowerCaseType === 'portfolio') {
        navigate(`account/${menuItem.sfid}`);
      }
    },
    [navigate, dashboardCommunity]
  );

  const additionalComponent = useMemo(() => {
    if ((isSettingsOpen && openComponent) || location.pathname.includes('settings')) {
      return <SettingsHeader />;
    } else {
      return (
        <>
          <CommunityDashboard createHandleMenuClick={createHandleMenuClick} />
          <CampaignDashboard createHandleMenuClick={createHandleMenuClick} />
        </>
      );
    }
  }, [openComponent, isSettingsOpen, location, createHandleMenuClick]);

  return (
    <Stack direction="row" columnGap={2} sx={{width: '100%'}}>
      {(communities.length > 1 || isStakeAdmin || accountsPermissions.length > 0) && (
        <AccountDashboard createHandleMenuClick={createHandleMenuClick} />
      )}
      {additionalComponent}
    </Stack>
  );
};

export default MenuDashboard;
