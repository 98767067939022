import React from 'react';
import PropTypes from 'prop-types';

import {Paper, Stack, Typography, Tooltip, IconButton, Grid} from '@mui/material';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {fontAwesomeIcons} from '../../../utils/icons';

const EsgMetricsBox = ({data}) => {
  const displayTooltop = data.display_tooltip ?? false;
  const title = data.title ?? '';
  let tooltipText = data.tooltip_text ?? '';
  const value = data.value ?? '';
  const size = data.size ?? '';
  const icon = data.icon ?? null;

  const renderIcon = (name) => {
    if (fontAwesomeIcons[name]) {
      return <FontAwesomeIcon icon={fontAwesomeIcons[name]} size="3x" color="#149CE0" />;
    }

    return null;
  };

  const renderedIcon = icon !== null ? renderIcon(icon) : null;

  if (tooltipText) {
    tooltipText = (
      <Stack direction="column" rowGap={1} sx={{padding: 1}}>
        {tooltipText.split('. ').map((str, idx) => (
          <Typography
            key={`tooltip-${idx}`}
            variant="subtitle2"
            sx={{textAlign: 'left', lineHeight: '1.4rem', paddingTop: 1, color: '#F3F3F3'}}
          >
            {str}
          </Typography>
        ))}
      </Stack>
    );
  }

  let tooltip = (
    <Tooltip title="" arrow>
      <IconButton size="large" sx={{height: '40px', visibility: 'hidden', position: 'absolute', top: '2px', right: '14px'}} />
    </Tooltip>
  );

  if (displayTooltop) {
    tooltip = (
      <Tooltip title={tooltipText} arrow>
        <IconButton size="small" disableRipple sx={{height: '40px', position: 'absolute', top: '2px', right: '14px'}}>
          <FontAwesomeIcon icon={fontAwesomeIcons['faCircleQuestionLight']} size="lg" color="#787878" />
        </IconButton>
      </Tooltip>
    );
  }

  const gridSize = size === 'small' ? 4 : 6;

  return (
    <Grid key={data.icon} item xs={gridSize}>
      <Paper
        sx={{
          bgcolor: '#FCFCFC',
          flexGrow: 0.5,
          border: '1px solid #E3E3E3',
          position: 'relative',
          height: '150px',
          borderRadius: '8px'
        }}
      >
        {tooltip}
        <Stack direction="column" gap={1} alignItems="center" justifyContent="center" sx={{height: '100%', paddingY: 1}}>
          {renderedIcon}
          <Typography variant="h4" fontWeight={'fontWeightRegular'} sx={{lineHeight: '1.5rem'}}>
            {value}
          </Typography>
          <Typography
            variant="subtitle1"
            fontWeight={'fontWeightRegular'}
            color={'text.subtitle'}
            sx={{textAlign: 'center', lineHeight: '1.4rem', maxWidth: '300px'}}
          >
            {title}
          </Typography>
        </Stack>
      </Paper>
    </Grid>
  );
};

EsgMetricsBox.propTypes = {
  data: PropTypes.object
};

export default EsgMetricsBox;
