export const findTickTickArray = (domain, diff = 10) => {
  let positiveTotal = [0, domain[1]].reduce((a, b) => a + b, 0);
  let difference = diff;

  if (positiveTotal > 50 && positiveTotal <= 100) {
    difference = 20;
    positiveTotal = Math.ceil(positiveTotal / 20) * 20;
  } else if (positiveTotal > 100) {
    difference = 50;
    positiveTotal = Math.ceil(positiveTotal / 50) * 50;
  } else if (positiveTotal <= 20) {
    difference = 5;
    positiveTotal = Math.ceil(positiveTotal / 5) * 5;
  }

  let i = domain[0];
  const tickArray = [];

  while (i <= positiveTotal) {
    tickArray.push(i);
    i += difference;
  }

  return tickArray;
};

export const findBarSize = (length, width) => {
  if (length <= 2) {
    return 100;
  } else if (length === 3 && width < 1600) {
    return 60;
  } else if (length === 3 && width >= 1600) {
    return 80;
  } else if (length === 6 && width >= 1600) {
    return 50;
  } else if (length === 6 && width < 1600) {
    return 30;
  } else if (length === 12 && width >= 1600) {
    return 20;
  } else {
    return 15;
  }
};

export const findBarRadius = (isStacked, idx, barColors) => {
  if (isStacked) {
    if (idx == 0) {
      return [0, 0, 3, 3];
    } else if (barColors.length - 1 == idx) {
      return [3, 3, 0, 0];
    } else {
      return [0, 0, 0, 0];
    }
  } else {
    return [3, 3, 3, 3];
  }
};
