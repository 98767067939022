import React, {forwardRef, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import {Box, Button, Chip, Paper, Stack, Typography, useForkRef, useTheme} from '@mui/material';
import {DateRangePicker, LocalizationProvider} from '@mui/x-date-pickers-pro';
import {AdapterDayjs} from '@mui/x-date-pickers-pro/AdapterDayjs';

import {utils, write} from 'xlsx';
import {saveAs} from 'file-saver';

import {getDashboardAccount, getDashboardCommunity} from '../../../../app/store/reducers/dashboard';
import {useLazyGetClaimedRateByAccountQuery} from '../../../../features/coreApi/metricsSlice';
import {StyledMarginWidthDividerSm} from '../../@extended/Divider';
import {numberFormatter} from '../../@extended/FormatDisplay';
import LoadingBox from '../../@extended/LoadingBox';
import ErrorBox from '../../@extended/ErrorBox';
import EmptyBox from '../../@extended/EmptyBox';
import {getIsStakeAdmin} from '../../../../app/store/reducers/session';

dayjs.extend(utc);

const claimedRateList = [
  {
    title: 'Total Residents Claimed',
    value: ['percent_of_claimed_residents', 'total_claimed_residents'],
    description: 'All residents who claimed their first cash reward in the time period selected.',
    format: ['percent', 'number'],
    color: 'rgba(0, 203, 82, 0.1)',
    textColor: '#11A10E'
  },
  {
    title: 'Stake+ Claimed Rate',
    value: ['percent_of_claimed_stake_plus_residents', 'total_claimed_residents_stake_plus'],
    description: 'Residents who received their first targeted leasing campaign reward plus a StakePay reward, and claimed their rewards.',
    format: ['percent', 'number'],
    color: 'rgba(0, 203, 82, 0.1)',
    textColor: '#11A10E'
  },
  {
    title: 'StakeFree Claimed Rate',
    value: ['percent_of_claimed_stake_one_residents', 'total_claimed_residents_stake_one'],
    description: 'Residents who received and claimed their first Stake funded cash reward.',
    format: ['percent', 'number'],
    color: 'rgba(0, 203, 82, 0.1)',
    textColor: '#11A10E'
  },
  {
    title: 'Stake Checking Conversion Rate',
    value: ['percent_of_claimed_residents_with_svd', 'total_claimed_residents_with_svd'],
    description: 'Percentage of residents who went on to apply for Stake checking after claiming their cash back.',
    format: ['percent', 'number'],
    color: 'rgba(0, 203, 82, 0.1)',
    textColor: '#11A10E'
  },
  {
    title: 'Average Days to Claim',
    value: ['avg_claimed_moved_ins_days'],
    description: 'Average number of days between move-in and claim.',
    format: ['days'],
    color: 'rgba(0, 203, 82, 0.1)',
    textColor: '#11A10E'
  },
  {
    title: 'Compared to Stake Network',
    value: ['percent_of_claimed_residents', 'percent_of_claimed_residents_in_stake_network'],
    description: 'Comparison of your portfolio\u0027s claimed rate to that of the entire Stake Network.',
    format: ['percent', 'percent'],
    color: 'rgba(0, 203, 82, 0.1)',
    textColor: '#11A10E'
  }
];

const generalList = [
  {
    title: 'Total residents',
    value: ['qualified_for_move_in_bonus'],
    description:
      'All residents who moved in during the selected time period and received a cash reward upon move-in, including Stake+ and StakeFree residents.',
    format: ['number'],
    color: 'rgba(0, 203, 82, 0.1)',
    textColor: '#11A10E'
  },
  {
    title: 'Total Stake+',
    value: ['total_moved_in_stake_plus'],
    description:
      'Residents who moved in during the selected time period, qualified for a targeted Stake+ campaign and received their first cash reward upon move-in.',
    format: ['number'],
    color: 'rgba(0, 203, 82, 0.1)',
    textColor: '#11A10E'
  },
  {
    title: 'Total StakeFree',
    value: ['total_moved_in_stake_one'],
    description: 'Residents who moved in during the selected time period and received their first Stake-funded cash reward upon move-in.',
    format: ['number'],
    color: 'rgba(0, 203, 82, 0.1)',
    textColor: '#11A10E'
  },
  {
    title: 'Total Stake Checking',
    value: ['total_with_svd'],
    description: 'Residents who signed up for Stake checking and/or StakePay during the time frame selected.',
    format: ['number'],
    color: 'rgba(0, 203, 82, 0.1)',
    textColor: '#11A10E'
  }
];

const ExcelExportComponent = ({data, selectedDate, rangeDate, accountName, residents}) => {
  const theme = useTheme();
  const isStakeAdmin = useSelector(getIsStakeAdmin);

  const now = dayjs();
  const endDate = rangeDate[1] ? rangeDate[1].format('YYYY-MM-DD') : dayjs().format('YYYY-MM-DD');
  let startDate = rangeDate[0] ? rangeDate[0].format('YYYY-MM-DD') : null;

  if (selectedDate == 'Current Month') {
    startDate = now.startOf('month').format('YYYY-MM-DD');
  } else if (selectedDate == 'Last 30 Days') {
    startDate = now.subtract(30, 'days').format('YYYY-MM-DD');
  } else if (selectedDate == 'Last 60 Days') {
    startDate = now.subtract(60, 'days').format('YYYY-MM-DD');
  } else if (selectedDate == 'Last 90 Days') {
    startDate = now.subtract(90, 'days').format('YYYY-MM-DD');
  }

  const exportToExcel = () => {
    const ws = utils.aoa_to_sheet([
      [accountName],
      ['Dates', startDate, endDate],
      [],
      [
        'Property name',
        'Total Residents Moved In and Received a Cash Reward on Move In',
        'Stake+ Move Ins',
        'StakeFree Move Ins',
        'Total Stake Checking Sign Ups',
        'Claimed Summary Total Residents Who Claimed',
        'Claimed Rate',
        'Stake+ Residents Claimed',
        'StakeFree Residents Claimed',
        'Stake Checking Conversion Rate',
        'Average Days to Claim'
      ],
      ...data.map((row) => [
        row.name,
        row.total_moved_in,
        row.total_moved_in_stake_plus,
        row.total_moved_in_stake_one,
        row.total_with_svd,
        row.total_claimed_residents,
        row.percent_of_claimed_residents + '%',
        row.percent_of_claimed_stake_plus_residents + '%',
        row.percent_of_claimed_stake_one_residents + '%',
        row.percent_of_claimed_residents_with_svd + '%',
        row.avg_claimed_moved_ins_days
      ])
    ]);

    const residentSheet = utils.aoa_to_sheet([
      [
        'Resident Name',
        'Community Name',
        'Move In Bonus amount',
        'Is Applied for Stake Checking',
        'Is Paid Rent with Stake',
        'Move In Date',
        'Stake Status',
        'Cash Back Claimed Date',
        'Claimed and Move In Days',
        'Move Out Date',
        'Paid This Month'
      ],
      ...residents.map((row) => [
        row.name,
        row.community_name,
        row.bonus_amount,
        row.is_applied_for_stake_checking,
        row.is_paid_rent_with_stake,
        row.move_in_date,
        row.stake_status,
        row.cash_back_claimed_date,
        row.claimed_and_move_in_days,
        row.move_out_date,
        row.this_month_payment
      ])
    ]);

    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, 'Move-In Summary');
    utils.book_append_sheet(wb, residentSheet, 'Resident Information');

    // Set column widths
    const colWidths = [
      {wch: 25}, // Property name
      {wch: 15}, // Total Residents
      {wch: 15}, // Stake+ Move Ins
      {wch: 15}, // StakeFree Move Ins
      {wch: 15}, // Total Stake Checking
      {wch: 20}, // Claimed Summary
      {wch: 15}, // Claimed Rate
      {wch: 20}, // Stake+ Residents Claimed
      {wch: 20}, // StakeFree Residents Claimed
      {wch: 25}, // Stake Checking Conversion Rate
      {wch: 20} // Average Days to Claim
    ];
    ws['!cols'] = colWidths;

    const excelBuffer = write(wb, {bookType: 'xlsx', type: 'array'});
    const dataBlob = new Blob([excelBuffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'});
    saveAs(dataBlob, 'property_management_data.xlsx');
  };

  return (
    <button
      onClick={exportToExcel}
      style={{
        padding: '10px 18px',
        backgroundColor: theme.palette.secondary.main,
        borderRadius: '8px',
        color: 'white',
        border: 'none',
        cursor: 'pointer',
        fontSize: '14px',
        height: '100%'
      }}
    >
      Export
    </button>
  );
};

ExcelExportComponent.propTypes = {
  data: PropTypes.array,
  selectedDate: PropTypes.string,
  rangeDate: PropTypes.array,
  accountName: PropTypes.string
};

const DateRangeButtonField = forwardRef((props, ref) => {
  const {setOpen, label, id, disabled, InputProps: {ref: containerRef} = {}, inputProps: {'aria-label': ariaLabel} = {}} = props;

  const handleRef = useForkRef(ref, containerRef);

  return (
    <Button
      variant="outlined"
      id={id}
      disabled={disabled}
      ref={handleRef}
      aria-label={ariaLabel}
      onClick={() => setOpen?.((prev) => !prev)}
      sx={{
        backgroundColor: label ? 'secondary.main' : 'transparent',
        color: label ? 'white' : 'secondary.main',
        border: '1px solid rgba(20, 156, 224, 0.5)'
      }}
    >
      {label ? `${label}` : 'Custom date'}
    </Button>
  );
});

DateRangeButtonField.fieldType = 'single-input';

const ButtonDateRangePicker = forwardRef((props, ref) => {
  const [open, setOpen] = useState(false);

  return (
    <DateRangePicker
      maxDate={dayjs()}
      slots={{field: DateRangeButtonField, ...props.slots}}
      slotProps={{field: {setOpen}}}
      ref={ref}
      {...props}
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
    />
  );
});

const shortcutsItems = [
  {
    label: 'Current Month',
    getValue: () => {
      const today = dayjs();
      return [today.startOf('month'), today.endOf('month')];
    }
  },
  {
    label: 'Last 30 Days',
    getValue: () => {
      const today = dayjs();
      return [today.subtract(30, 'day'), today];
    }
  },
  {
    label: 'Last 60 Days',
    getValue: () => {
      const today = dayjs();
      return [today.subtract(60, 'day'), today];
    }
  },
  {
    label: 'Last 90 Days',
    getValue: () => {
      const today = dayjs();
      return [today.subtract(90, 'day'), today];
    }
  }
];

export const ClaimedRateComponent = ({data, communitiesData, residents}) => {
  const dashboardCommunity = useSelector(getDashboardCommunity);

  const [fetchClaimedRate, {data: fetchedData, isLoading, isFetching, isError, isSuccess}] = useLazyGetClaimedRateByAccountQuery();

  let subComponent;
  const exportData = useMemo(() => {
    if (dashboardCommunity?.sfid && dashboardCommunity.sfid != 'all') {
      const communityFetchedDataList =
        isSuccess && fetchedData?.is_supported ? fetchedData.data.communities.filter((item) => item.sfid == dashboardCommunity.sfid) : null;
      const communityFetchedData = communityFetchedDataList && communityFetchedDataList.length > 0 ? communityFetchedDataList[0] : null;
      const communityData = fetchedData ? communityFetchedData : data;
      return [communityData];
    } else {
      return fetchedData ? fetchedData.data.communities : [...communitiesData];
    }
  }, [communitiesData, isLoading, isFetching, isError, isSuccess, dashboardCommunity, data]);

  const residentsData = useMemo(() => {
    if (dashboardCommunity?.sfid && dashboardCommunity.sfid != 'all') {
      const resFetchedDataList =
        isSuccess && fetchedData?.is_supported
          ? fetchedData.data.resident_list.filter((item) => item.community_sfid == dashboardCommunity.sfid)
          : [];

      const resData = fetchedData ? resFetchedDataList : [...residents];
      return resData;
    } else {
      return fetchedData ? fetchedData.data.resident_list : [...residents];
    }
  }, [residents, isLoading, isFetching, isError, isSuccess, dashboardCommunity, data]);

  if (isLoading || isFetching) {
    subComponent = <LoadingBox />;
  } else if (isError) {
    subComponent = <ErrorBox />;
  } else {
    if (!data && !fetchedData) {
      subComponent = <EmptyBox height="150px" />;
    } else if (dashboardCommunity?.sfid && dashboardCommunity.sfid != 'all') {
      const communityFetchedDataList =
        isSuccess && fetchedData?.is_supported ? fetchedData.data.communities.filter((item) => item.sfid == dashboardCommunity.sfid) : null;
      const communityFetchedData = communityFetchedDataList && communityFetchedDataList.length > 0 ? communityFetchedDataList[0] : null;
      const communityData = fetchedData ? communityFetchedData : data;

      if (!communityData) {
        subComponent = <EmptyBox height="150px" />;
      } else {
        subComponent = <AccountClaimedRateInfo data={communityData} />;
      }
    } else {
      const accountFetchedData = isSuccess && fetchedData?.is_supported ? fetchedData.data.general_info : null;
      const accountData = fetchedData ? accountFetchedData : data;

      if (!accountData) {
        subComponent = <EmptyBox height="150px" />;
      } else {
        subComponent = <AccountClaimedRateInfo data={accountData} />;
      }
    }
  }

  return (
    <Stack direction="column" rowGap={2} divider={<StyledMarginWidthDividerSm />}>
      <ClaimedRateHeader
        fetchClaimedRate={fetchClaimedRate}
        eligible={data?.eligible_properties_length}
        exportData={exportData}
        residents={residentsData}
      />
      {subComponent}
    </Stack>
  );
};

ClaimedRateComponent.propTypes = {
  data: PropTypes.object
};

const ClaimedRateTabelInfo = ({item, index, length, data}) => {
  const theme = useTheme();
  const valueList = [];

  for (let i = 0; i < item.value.length; i++) {
    const initialValue = data[item.value[i]];
    let value;
    if (item.format[i] === 'number') {
      value = initialValue ? numberFormatter.format(initialValue) : 0;
    } else if (item.format[i] === 'percent') {
      value = initialValue + '%';
    } else if (item.format[i] === 'days') {
      value = initialValue ? (initialValue == 1 ? '1 day' : initialValue + ' days') : 'no data';
    }

    valueList.push(value);
  }

  let labelValue = valueList.join(' / ');

  if (item.title == 'Compared to Stake Network') {
    labelValue = (
      <React.Fragment key={index}>
        {valueList[0]}
        <br />
        vs
        <br />
        {valueList[1]}
        <br />
        Stake Network
      </React.Fragment>
    );
  }

  const isLong = length == 6;
  const marginLong = index != 2 && index != 5 ? '8px' : 0;
  const marginShort = index < length - 1 ? '8px' : 0;

  return (
    <Paper
      style={{
        width: length == 6 ? 'calc(100% / 3 - 8px)' : 'calc(100% / 4 - 8px)',
        borderRadius: '8px',
        marginBottom: '8px',
        marginRight: isLong ? marginLong : marginShort,
        boxShadow: 'none',
        padding: '16px'
      }}
    >
      <Stack direction={'row'} justifyContent={'space-between'} columnGap={'8px'} sx={{height: '100%'}}>
        <Stack direction="column" rowGap={1} sx={{width: '65%', minWidth: '65%'}}>
          <Typography variant="subtitle1" fontWeight={'fontWeightRegular'} color={theme.palette.text.primary}>
            {item.title}
          </Typography>
          <Typography variant="subtitle1" fontWeight={'fontWeightRegular'} color={theme.palette.text.subtitle}>
            {item.description}
          </Typography>
        </Stack>
        <Stack direction="column" flexWrap="wrap">
          <Chip
            variant="contained"
            sx={{
              backgroundColor: item.color,
              color: item.textColor,
              minWidth: '50px',
              maxWidth: '100%',
              height: 'auto',
              '& .MuiChip-label': {
                whiteSpace: 'normal',
                wordBreak: 'break-word',
                lineHeight: 1.5,
                padding: '8px',
                textAlign: 'center'
              }
            }}
            label={labelValue}
          />
        </Stack>
      </Stack>
    </Paper>
  );
};

const AccountClaimedRateInfo = ({data}) => {
  return (
    <Stack direction={'column'} rowGap={2}>
      <Stack direction={'column'} rowGap={1} sx={{width: '100%'}}>
        <Stack direction={'column'}>
          <Typography variant="h5" fontWeight={'fontWeightMedium'}>
            Move-In Summary
          </Typography>
          <Typography variant="body1" color={'text.subtitle'}>
            Total number of residents who moved in during the selected time period and received a cash reward upon move-in.
          </Typography>
        </Stack>
        <Stack direction="row" flexWrap="wrap" sx={{width: '100%', maxWidth: '100%'}}>
          {generalList.map((item, index) => (
            <ClaimedRateTabelInfo key={index} item={item} index={index} length={generalList.length} data={data} />
          ))}
        </Stack>
      </Stack>
      <Stack direction={'column'} rowGap={1}>
        <Stack direction={'column'}>
          <Typography variant="h5" fontWeight={'fontWeightMedium'}>
            Claimed Summary
          </Typography>
          <Typography variant="body1" color={'text.subtitle'}>
            See how many new residents claimed their first cash back rewards - and how fast - and then went on to complete key actions. Also
            see how you stack up against the rest of Stake Network.
          </Typography>
        </Stack>
        <Stack direction="row" flexWrap="wrap" sx={{width: '100%', maxWidth: '100%'}}>
          {claimedRateList.map((item, index) => (
            <ClaimedRateTabelInfo key={index} item={item} index={index} length={claimedRateList.length} data={data} />
          ))}
        </Stack>
      </Stack>
      <Typography variant="body1" color={'text.subtitle'}>
        Total number of active leases for the selected time period is {numberFormatter.format(data.total_active_leases)}.
      </Typography>
    </Stack>
  );
};

AccountClaimedRateInfo.propTypes = {
  data: PropTypes.object
};

const ClaimedRateHeader = ({fetchClaimedRate, eligible, exportData, residents}) => {
  const dashboardAccount = useSelector(getDashboardAccount);
  const dashboardCommunity = useSelector(getDashboardCommunity);

  const [rangeDate, setRangeDate] = useState([null, null]);
  const [selectedValue, setSelectedValue] = useState('Last 30 Days');

  const isCommunityLevel = useMemo(() => {
    if (dashboardCommunity?.sfid && dashboardCommunity.sfid != 'all') {
      return true;
    }
    return false;
  }, [dashboardCommunity]);

  const handleDateRangeChange = (newValue) => {
    setRangeDate(newValue);
    setSelectedValue('custom');

    if (newValue[0] && newValue[1]) {
      const startDate = newValue[0].format('YYYY-MM-DD');
      const endDate = newValue[1].format('YYYY-MM-DD');
      fetchClaimedRate({sfid: dashboardAccount?.sfid, startDate, endDate});
    }
  };

  const handleSelectChange = (value) => {
    setRangeDate([null, null]);
    setSelectedValue(value);

    if (value !== 'custom') {
      const {getValue} = shortcutsItems.find((item) => item.label === value);
      const [startDate, endDate] = getValue();
      fetchClaimedRate({sfid: dashboardAccount?.sfid, startDate: startDate.format('YYYY-MM-DD'), endDate: endDate.format('YYYY-MM-DD')});
    }
  };

  const eligibleComponent = useMemo(() => {
    if (!isCommunityLevel) {
      return (
        <Typography variant="body1" color={'text.subtitle'} textAlign={'end'} sx={{fontStyle: 'italic'}}>
          Properties Eligible for Cash Back on Move-In: {eligible || 0}
        </Typography>
      );
    }
    return null;
  }, [eligible, isCommunityLevel]);

  return (
    <Stack direction="column" rowGap={2}>
      <Stack direction={'row'} justifyContent={'space-between'}>
        <Stack direction="column" sx={{width: '100%'}}>
          <Typography variant="h5" fontWeight={'fontWeightMedium'}>
            Cash Back on Move-In
          </Typography>
          <Stack direction="row" justifyContent={'space-between'} alignItems={'flex-end'}>
            <Typography variant="body1" color={'text.subtitle'} sx={{width: '60%'}}>
              See how many residents in your portfolio received their first cash reward when they moved in, and how many claimed their
              reward and are interested in earning more rewards with Stake Checking.
            </Typography>
            {eligibleComponent}
          </Stack>
        </Stack>
      </Stack>
      <Box sx={{maxWidth: '100%'}}>
        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
          <Stack direction="row" spacing={1}>
            {shortcutsItems.map((item) => (
              <Button
                key={item.label}
                size="small"
                variant={selectedValue == item.label ? 'contained' : 'outlined'}
                color="secondary"
                onClick={() => handleSelectChange(item.label)}
              >
                {item.label}
              </Button>
            ))}
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <ButtonDateRangePicker
                label={
                  rangeDate[0] === null && rangeDate[1] === null
                    ? null
                    : rangeDate.map((date) => (date ? date.format('MM/DD/YYYY') : 'null')).join(' - ')
                }
                value={rangeDate}
                onChange={(newValue) => handleDateRangeChange(newValue)}
              />
            </LocalizationProvider>
          </Stack>
          {exportData && exportData.length > 0 && (
            <ExcelExportComponent
              data={exportData}
              selectedDate={selectedValue}
              rangeDate={rangeDate}
              accountName={dashboardAccount?.name}
              residents={residents}
            />
          )}
        </Stack>
      </Box>
    </Stack>
  );
};

ClaimedRateHeader.propTypes = {
  fetchClaimedRate: PropTypes.func
};
