import React from 'react';
import PropTypes from 'prop-types';
import {Controller, useFormContext} from 'react-hook-form';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {LinearProgress, linearProgressClasses, Rating, styled} from '@mui/material';
import {fontAwesomeIcons} from '../../utils/icons';

export const StyledRating = styled(Rating)(({theme}) => {
  return {
    '& .MuiRating-root': {
      borderRadius: 2
    },
    '& .MuiRating-iconFilled': {
      color: theme.palette.green.main
    },
    '& .MuiRating-iconEmpty': {
      color: theme.palette.green.main
    }
  };
});

export const StyledBarRating = styled(LinearProgress)(({theme}) => ({
  borderRadius: 5,
  width: 55,
  height: 100,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.green.main
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 0,
    backgroundColor: theme.palette.grey[300]
  }
}));

export const FormStarRating = ({name}) => {
  const {control} = useFormContext();

  return (
    <Controller
      defaultValue=""
      name={name}
      control={control}
      render={({field}) => {
        return (
          <StyledRating
            {...field}
            name="stars-rating"
            precision={1}
            icon={<FontAwesomeIcon size="sm" icon={fontAwesomeIcons['faStar']} />}
            emptyIcon={<FontAwesomeIcon size="sm" icon={fontAwesomeIcons['faStarThin']} />}
            onChange={(e) => field.onChange(parseInt(e.target.value))}
          />
        );
      }}
    />
  );
};

FormStarRating.propTypes = {
  name: PropTypes.string
};
