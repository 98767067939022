import React, {memo, useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import dayjs from 'dayjs';

import {Box, Paper, Stack, Typography} from '@mui/material';

import {findLastTwelveMonthsWithYYYY} from '../../../../constants/dateConstants';
import {getDashboardAccount, getDashboardCommunity} from '../../../../../app/store/reducers/dashboard';
import {useGetSpendingDataByAccountQuery} from '../../../../../features/coreApi/metricsSlice';
import {findGraphData} from '../../../../constants/spendingConstants';

import LoadingBox from '../../../@extended/LoadingBox';
import ErrorBox from '../../../@extended/ErrorBox';
import {CommunitySpendingGraph, SpendingCategoriesTable, SpendingsHeader, SpendingVolumeTable} from './ResidentsSpendingInfo';

const ResidentsSpenging = () => {
  const MMMsYYYYs = findLastTwelveMonthsWithYYYY();

  const dashboardAccount = useSelector(getDashboardAccount);
  const dashboardCommunity = useSelector(getDashboardCommunity);

  const {data, isLoading, isSuccess, isError, isFetching} = useGetSpendingDataByAccountQuery(dashboardAccount?.sfid, {
    skip: !dashboardAccount?.sfid
  });

  const [filteredData, setFilteredData] = useState([]);
  const [spendingsFilteredByMonths, setSpendingsFilteredByMonths] = useState([]);
  const [legend, setLegend] = useState('All Categories');
  const [subLegend, setSubLegend] = useState('All Subcategories');

  const {categoryData} = useMemo(() => findGraphData(legend, filteredData, MMMsYYYYs, 'category_1'), [legend, filteredData, MMMsYYYYs]);

  useEffect(() => {
    if (isSuccess && !isFetching && !isLoading) {
      let spendingsData = data.filter((ele) => dayjs(ele.authorized_date, 'MM/DD/YYYY') >= dayjs(MMMsYYYYs[0], 'MMM YYYY'));
      if (dashboardCommunity?.sfid && dashboardCommunity?.sfid !== 'all') {
        spendingsData = data
          .filter((ele) => dayjs(ele.authorized_date, 'MM/DD/YYYY') >= dayjs(MMMsYYYYs[0], 'MMM YYYY'))
          .filter((ele) => ele.community === dashboardCommunity?.sfid);
      }

      setSpendingsFilteredByMonths(spendingsData);
      setFilteredData(spendingsData);
    }
  }, [dashboardCommunity, isSuccess, isFetching, isLoading]);

  const CommunitySpendingGraphMemo = memo(CommunitySpendingGraph);
  const SpendingCategoriesTableMemo = memo(SpendingCategoriesTable);
  const commonPaperProps = {width: '100%', sx: {padding: 2, position: 'relative', boxShadow: 'none', borderRadius: '8px'}};
  const renderLoading = () => {
    return (
      <Paper {...commonPaperProps}>
        <LoadingBox />
      </Paper>
    );
  };

  const renderError = () => {
    return (
      <Paper {...commonPaperProps}>
        <ErrorBox />
      </Paper>
    );
  };

  const renderSuccess = () => {
    if (filteredData.length > 0) {
      return (
        <Paper {...commonPaperProps}>
          <Stack direction="column" rowGap={1}>
            <SpendingsHeader
              legend={legend}
              setLegend={setLegend}
              categoryOneData={categoryData}
              subLegend={subLegend}
              setSubLegend={setSubLegend}
              spendingsFilteredByMonths={spendingsFilteredByMonths}
            />
            <CommunitySpendingGraphMemo legend={legend} subLegend={subLegend} filteredData={filteredData} />
            <SpendingCategoriesTableMemo filteredData={filteredData} />
            <SpendingVolumeTable filteredData={filteredData} />
          </Stack>
        </Paper>
      );
    } else {
      return (
        <Paper {...commonPaperProps}>
          <Box width="100%" height={200} display="flex" justifyContent="center" alignItems="center">
            <Typography variant="subtitle1" fontWeight={'fontWeightRegular'} color={'text.subtitle'}>
              No residents&#39; spendings data available
            </Typography>
          </Box>
        </Paper>
      );
    }
  };

  if (isLoading || isFetching) {
    return renderLoading();
  } else if (isError) {
    return renderError();
  } else if (isSuccess) {
    return renderSuccess();
  }
};

export default ResidentsSpenging;
