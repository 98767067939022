import React, {useMemo, useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {ResponsiveContainer, XAxis, YAxis, Bar, BarChart} from 'recharts';

import {Paper, Typography, Stack, Divider, Grid, useTheme} from '@mui/material';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {fontAwesomeIcons} from '../../../utils/icons';
import {StyledMarginWidthDividerSm} from '../../@extended/Divider';

const EngagementStakeVisaTable = ({entry}) => {
  return (
    <Stack key={entry.label} direction="column" sx={{flexGrow: 1, padding: 1}}>
      <Typography variant="h5" fontWeight={'fontWeightRegular'}>
        {entry.metric}
      </Typography>
      <Typography variant="subtitle2" fontWeight={'fontWeightRegular'} color={'text.subtitle'}>
        {entry.label}
      </Typography>
    </Stack>
  );
};

EngagementStakeVisaTable.propTypes = {
  entry: PropTypes.object
};

const EngagementSVDRowItem = ({item}) => {
  const label = item.name.charAt(0).toUpperCase() + item.name.slice(1).toLowerCase();

  return (
    <Stack direction="row" alignItems="center" key={item.name}>
      <div
        style={{
          width: '15px',
          height: '15px',
          backgroundColor: item.color,
          marginRight: '8px'
        }}
      />
      <Typography variant="h7_light">{label}</Typography>
      <div style={{flex: '1'}} />
      <Typography variant="h7_light">{item.value}</Typography>
    </Stack>
  );
};

EngagementSVDRowItem.propTypes = {
  item: PropTypes.object
};

const EngagementStakeVisaStats = ({stakeVisaDebitInfo}) => {
  const theme = useTheme();

  const [colors, setColors] = useState({});
  const [svdData, setSvdData] = useState([]);
  const [svdGraph, setSvdGraph] = useState({});
  const [svdLabels, setSvdLabels] = useState({});

  useEffect(() => {
    if (stakeVisaDebitInfo.colors) {
      setColors(stakeVisaDebitInfo.colors);
    }
    if (stakeVisaDebitInfo.items) {
      setSvdData(stakeVisaDebitInfo.items);
    }

    if (stakeVisaDebitInfo.data) {
      setSvdGraph(stakeVisaDebitInfo.data);
    }
    if (stakeVisaDebitInfo.labels) {
      setSvdLabels(stakeVisaDebitInfo.labels);
    }

    return () => {
      setColors({});
      setSvdData([]);
      setSvdGraph({});
      setSvdLabels({});
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(stakeVisaDebitInfo)]);

  const graphKeys = useMemo(() => {
    return Object.keys(svdGraph);
    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(svdGraph)]);

  let graphView;

  const isGraphEmpty = useMemo(() => {
    let isEmpty = true;

    for (const key in svdGraph) {
      if (svdGraph[key] > 0) {
        isEmpty = false;
        break;
      }
    }

    return isEmpty;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(svdGraph)]);

  if (!stakeVisaDebitInfo.data) {
    return <></>;
  } else if (!isGraphEmpty) {
    const maxValue = Math.max(...Object.values(svdGraph));
    const roundedMax = Math.ceil(maxValue / 100) * 100;
    const extendedMax = Math.ceil((roundedMax * 1) / 100) * 100;
    const tickInterval = extendedMax / (5 - 1);
    const ticks = Array.from({length: 5}, (_, index) => index * tickInterval);

    graphView = (
      <ResponsiveContainer width={'100%'} height={200}>
        <BarChart data={[svdGraph]} style={{width: '100%'}}>
          <XAxis axisLine={{stroke: theme.palette.grey[600], strokeWidth: 0.2}} tickSize={0} tickFormatter={() => ''} mirror={true} />

          <YAxis axisLine={false} opacity={0.4} tickSize={0} domain={[0, extendedMax]} ticks={ticks} mirror={true} interval="preserveEnd" />
          {graphKeys.map((name) => {
            return <Bar key={name} dataKey={name} fill={colors[name]} radius={[6, 6, 6, 6]} minPointSize={2} barSize={20} />;
          })}
        </BarChart>
      </ResponsiveContainer>
    );
  } else {
    graphView = (
      <Grid container direction="column" justifyContent="center" alignItems="center" sx={{width: '100%', height: '200px'}}>
        <Grid item>
          <FontAwesomeIcon icon={fontAwesomeIcons['faChartNetwork']} size="2x" color="#149CE0" />
        </Grid>
        <Grid item sx={{marginTop: '10px'}}>
          <Typography variant="subtitle1" fontWeight={'fontWeightRegular'} color={'text.subtitle'}>
            Data collection in process.
          </Typography>
        </Grid>
      </Grid>
    );
  }

  return (
    <Paper sx={{borderRadius: '8px', padding: '16px', width: '100%', boxShadow: 'none'}}>
      <Stack direction="column" rowGap={1.5} divider={<StyledMarginWidthDividerSm />}>
        <Typography variant="h5" fontWeight={'fontWeightMedium'}>
          {stakeVisaDebitInfo.title ?? ''}
        </Typography>
        <Stack direction="column" rowGap={3}>
          <Stack
            direction="row"
            sx={{
              border: '1px solid #E3E3E3',
              borderRadius: '8px'
            }}
            alignItems="center"
            divider={<Divider orientation="vertical" flexItem />}
          >
            {svdData.map((entry) => (
              <EngagementStakeVisaTable key={entry.label} entry={entry} />
            ))}
          </Stack>
          <Stack direction="column">
            {graphView}
            <Stack direction="column" padding={1} rowGap={1} divider={<Divider />}>
              {graphKeys.map((name) => {
                const value = svdLabels[name];
                const color = colors[name];
                return (
                  <EngagementSVDRowItem
                    key={name}
                    item={{
                      name,
                      value,
                      color
                    }}
                  />
                );
              })}
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Paper>
  );
};

EngagementStakeVisaStats.propTypes = {
  stakeVisaDebitInfo: PropTypes.object
};

export default EngagementStakeVisaStats;
