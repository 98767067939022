import React, {useMemo} from 'react';
import PropTypes from 'prop-types';

import {MenuItem, Paper, Select, Stack, Typography} from '@mui/material';

import {timeFilters} from '../../../../constants/filterConstants';
import {renderColumnCell} from '../../../@extended/TableBodyCell';
import {StyledTable, StyledTableDefaultPadding} from '../../../@extended/CustomTables';
import {ExportToolbar} from '../../../@extended/DataGridTables';
import {valueGetter} from '../../../../constants/tableColumns';

export const MetricsHeader = ({title, description, timeFilter, setTimeFilter}) => {
  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Stack direction="column" sx={{width: '55%'}}>
        <Typography variant="h5" fontWeight={'fontWeightMedium'}>
          {title}
        </Typography>
        <Typography variant="body1" color={'text.subtitle'}>
          {description}
        </Typography>
      </Stack>
      <Stack direction="row" columnGap={1} alignItems="center">
        <Select
          id="select-time-period"
          value={timeFilter}
          onChange={(e) => setTimeFilter(e.target.value)}
          sx={{
            '& .MuiSelect-select': {
              padding: '12px 14px',
              display: 'flex',
              alignItems: 'center'
            },
            '& .MuiOutlinedInput-root': {
              height: '100%'
            }
          }}
        >
          {timeFilters.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </Stack>
    </Stack>
  );
};

MetricsHeader.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  timeFilter: PropTypes.number,
  setTimeFilter: PropTypes.func
};

export const MetricsTable = ({data}) => {
  const columns = useMemo(
    () =>
      data.columns.map((col) => {
        const column = {...col};

        if (column.field == 'renewals_conversion') {
          column.headerName = 'Renewals Conversion';
        }

        return {
          ...column,
          renderCell: (params) => renderColumnCell(params),
          valueFormatter: (params) => valueGetter(params, column)
        };
      }),
    [data.columns]
  );

  const rows = useMemo(() => {
    return data.rows.map((row) => row);
  }, [data.rows]);

  return (
    <Paper sx={{borderRadius: 2, boxShadow: 'none'}}>
      <StyledTableDefaultPadding
        rows={rows}
        columns={columns}
        slots={{
          toolbar: ExportToolbar
        }}
        autoHeight
        hideFooter
        density="compact"
        disableRowSelectionOnClick
        getRowId={(row) => row.id}
      />
    </Paper>
  );
};

MetricsTable.propTypes = {
  data: PropTypes.shape({
    columns: PropTypes.array,
    rows: PropTypes.array
  }),
  name: PropTypes.string
};
