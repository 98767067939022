export const residentInfoGrey = '#787878';

export const findEmailSummaryStats = (data) => {
  let totalOpened = 0;
  let totalClicked = 0;

  data.forEach((ele) => {
    if (ele.opened) {
      totalOpened++;
    }

    if (ele.clicked) {
      totalClicked++;
    }
  });

  const openRate = Math.round((totalOpened / data.length) * 100);
  const clickRate = Math.round((totalClicked / data.length) * 100);

  return {
    openRate: openRate,
    clickRate: clickRate
  };
};

export const findTextSummaryStats = (data) => {
  let totalDelivered = 0;

  data.forEach((ele) => {
    if (ele.delivered) {
      totalDelivered++;
    }
  });

  const deliveryRate = Math.round((totalDelivered / data.length) * 100);

  return {
    deliveryRate: deliveryRate
  };
};
export const findPushSummaryStats = (data) => {
  let totalClicked = 0;
  let totalDelivered = 0;

  data.forEach((ele) => {
    if (ele.delivered) {
      totalDelivered++;
    }

    if (ele.clicked) {
      totalClicked++;
    }
  });

  const deliveryRate = Math.round((totalDelivered / data.length) * 100);
  const clickRate = Math.round((totalClicked / data.length) * 100);

  return {
    deliveryRate: deliveryRate,
    clickRate: clickRate
  };
};

export const NotStakeEscrowStatuses = new Set(['ApplicationApproved', 'DebitCardRequested', 'DebitCardActivated']);
