import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';

import {Stack} from '@mui/material';

import {useLazyGetEngagementByAccountIdQuery} from '../../../../features/coreApi/metricsSlice';
import {getDashboardAccount, getDashboardCommunities, getDashboardCommunity} from '../../../../app/store/reducers/dashboard';
import {getIsStakeAdmin, getTeamMemberRole} from '../../../../app/store/reducers/session';
import {defaultEngState, isAllEmpty} from './utils';
import {getOpenTab} from '../../../../app/store/reducers/menu';

import ErrorBox from '../../@extended/ErrorBox';
import EmptyBox from '../../@extended/EmptyBox';
import LoadingBox, {ComplexLoadingBox} from '../../@extended/LoadingBox';
import EngagementCreditBuilder from './EngagementCreditBuilder';
import EngagementStakeVisaStats from './EngagementStakeVisaStats';
import EngagementInsights from './EngagementInsights';
import EngagementFinancialUnlocks from './EngagementFinancialUnlocks';
import EngagementStakePay from './EngagementStakePay';
import ClaimedRateInfo from '../Performance/ClaimedRateInfo';
import ResidentsSpenging from '../Performance/Metrics/ResidentsSpending';

// ==============================|| ENGAGEMENT TAB ||============================== //
const EngagementTab = () => {
  const [fetchEngagement, {data: response = {}, isLoading, isSuccess, isFetching, isError}] = useLazyGetEngagementByAccountIdQuery();

  const openTab = useSelector(getOpenTab);
  const dashbboardCommunity = useSelector(getDashboardCommunity);
  const dashboardAccount = useSelector(getDashboardAccount);
  const isStakeAdmin = useSelector(getIsStakeAdmin);
  const userRole = useSelector(getTeamMemberRole);
  const dashboardCommunities = useSelector(getDashboardCommunities);

  const [engKey, setEngKey] = useState('All communities');

  useEffect(() => {
    if (dashboardAccount.sfid) {
      let communitiesAccess = 'all';
      if (userRole != 'Administrator' && !isStakeAdmin) {
        const communitiesSfids = dashboardCommunities.map((community) => community.sfid);
        communitiesAccess = communitiesSfids.join(';');
      }
      fetchEngagement({sfid: dashboardAccount.sfid, communities: communitiesAccess});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardAccount, userRole, isStakeAdmin, dashboardCommunities]);

  useEffect(() => {
    if (dashbboardCommunity.sfid && dashbboardCommunity.sfid != 'all') {
      setEngKey(dashbboardCommunity.sfid);
    } else {
      setEngKey('All communities');
    }
  }, [dashbboardCommunity, dashboardAccount]);

  const [engData, setEngData] = useState(defaultEngState);

  useEffect(() => {
    if (isSuccess && !isLoading && !isFetching && engKey) {
      const mainParts = Object.keys(defaultEngState);
      const obj = {};

      for (const part of mainParts) {
        obj[part] = part in response ? response[part][engKey]['All time'] : {};
      }
      setEngData({...defaultEngState, ...obj});
    } else {
      setEngData(defaultEngState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isSuccess, isFetching, engKey, openTab]);

  let mainView = <></>;

  if (!isLoading && !isFetching && isAllEmpty(engData)) {
    mainView = <EmptyBox />;
  } else if (isLoading || isFetching) {
    mainView = <ComplexLoadingBox isLoading={isLoading || isFetching} />;
  } else if (isSuccess) {
    const {homeCreditBuilder, homeFinancialUnlocks, homeInsights, homeSVD, homeStakePay} = engData;

    mainView = (
      <Stack direction="column" alignContent="start" spacing={3} sx={{width: '100%', height: '100%'}}>
        <ClaimedRateInfo />
        <EngagementInsights engagementInsights={homeInsights} />
        <EngagementFinancialUnlocks engagementFinancials={homeFinancialUnlocks} />
        <Stack direction={'row'} columnGap={3}>
          <Stack direction="column" rowGap={3} sx={{minWidth: 'calc(50% - 12px)'}}>
            <EngagementStakePay stakePayInfo={homeStakePay} />
            <EngagementCreditBuilder creditBuilderInfo={homeCreditBuilder} />
          </Stack>
          <EngagementStakeVisaStats stakeVisaDebitInfo={homeSVD} />
        </Stack>
        <ResidentsSpenging />
      </Stack>
    );
  } else if (isError) {
    mainView = <ErrorBox />;
  }

  return mainView;
};

export default EngagementTab;
