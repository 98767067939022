import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import minMax from 'dayjs/plugin/minMax';
dayjs.extend(minMax);

import {actionsDeterminersObj, formatToDollar, qualifiersDeterminersValueObj} from '../../../../constants/offersConstants';
import {Stack, Typography} from '@mui/material';

const formatUnits = (payAfter) => {
  if (payAfter == 0) {
    return 'immediately after move-in';
  }

  const years = Math.floor(payAfter / 365);
  const months = Math.floor(payAfter / 30);
  const weeks = Math.floor(payAfter / 7);
  const days = payAfter;

  if (years > 0 && payAfter % 365 === 0) {
    return `after ${years} ${years === 1 ? 'year' : 'years'} of move-in`;
  }

  if (months > 0 && payAfter % 30 === 0) {
    return `after ${months} ${months === 1 ? 'month' : 'months'} of move-in`;
  }

  if (weeks > 0 && payAfter % 7 === 0) {
    return `after ${weeks} ${weeks === 1 ? 'week' : 'weeks'} of move-in`;
  }

  return `after ${days} ${days === 1 ? 'day' : 'days'} of move-in`;
};

export const formatOtherOffers = (ele) => {
  const {qualifiers, reward_amount, action_name, reward_type, id} = ele;
  const isAmountType = reward_type == 'Flat dollar amount';
  const isPayingRent = action_name == 'Paying rent';
  const isCustom = action_name.includes('Custom');

  let offerRewardChipValue = null;
  if (isCustom) {
    offerRewardChipValue = isAmountType ? `${formatToDollar(parseFloat(reward_amount))} bonus` : `${reward_amount}% Cash Back`;
  } else if (isAmountType) {
    offerRewardChipValue = `${formatToDollar(reward_amount)} ${actionsDeterminersObj[action_name]} bonus`;
  } else if (isPayingRent && !isAmountType) {
    offerRewardChipValue = `${reward_amount}% Cash Back`;
  } else {
    offerRewardChipValue = `${reward_amount}% ${actionsDeterminersObj[action_name]} bonus`;
  }

  let targetleaseQualifiers = qualifiers.filter((q) => q.qualifier_type == 'integer' && q.field_name == 'lease_term_months');
  let offersTargets = [];

  targetleaseQualifiers.forEach((element) => {
    const lowerBound = element.lower_bound + ' Months';
    offersTargets.push(lowerBound);
  });

  let targetBrQualifiers = qualifiers.filter((q) => q.qualifier_type == 'integer' && q.field_name == 'number_of_bedrooms');
  let brTargets = [];

  targetBrQualifiers.forEach((element) => {
    const upperBound = element.upper_bound == 0 ? 'Studio' : element.upper_bound + ' BR';
    const lowerBound = element.lower_bound == 0 ? 'Studio' : element.lower_bound + ' BR';

    if (element.lower_bound !== element.upper_bound) {
      brTargets.push(lowerBound);
      brTargets.push(upperBound);
    } else {
      brTargets.push(lowerBound); // Only add the lowerBound if they are the same
    }
  });

  const customPaymentSchedule = ele['custom_payment_schedule'] || [];
  let customSchedulesChipValues = [];
  for (const schedule of customPaymentSchedule) {
    const {parts} = schedule;
    for (const part of parts) {
      const {amount, pay_after, pay_on, conditioned_on} = part;
      const isDate = pay_on ? true : false;

      let amountString = isAmountType ? formatToDollar(parseInt(amount)) : `${amount}% of ${reward_amount}% of rent bonus`;
      let dateString = '';
      if (isDate) {
        dateString = dayjs(pay_on).format('MMM D YYYY');
        dateString = `on ${dateString}`;
      } else {
        dateString = formatUnits(pay_after);
      }

      let fullStringValue = amountString + ' ' + dateString;
      if (conditioned_on && conditioned_on != 'No condition') {
        if (conditioned_on == '100% timely payments') {
          fullStringValue += ` if 100% paid on time`;
        } else if (conditioned_on == 'No more than 1 late payment') {
          fullStringValue += ` if no more than 1 late payment`;
        } else if (conditioned_on == 'No more than 2 late payment') {
          fullStringValue += ` if no more than 2 late payments`;
        } else if (conditioned_on == 'No more than 3 late payment') {
          fullStringValue += ` if no more than 3 late payments`;
        }
      }
      customSchedulesChipValues.push(fullStringValue);
    }
  }

  var dateQualifiers = qualifiers.filter((q) => q.qualifier_type == 'datetime');

  const offerDeterminersValues = dateQualifiers.map((qualifier) => {
    const {field_name, lower_bound, upper_bound} = qualifier;
    const upperBound = upper_bound ? dayjs(upper_bound).startOf('day') : null;
    const lowerBound = lower_bound ? dayjs(lower_bound).endOf('day') : null;
    const determiner = field_name;

    let determinerValue = `${qualifiersDeterminersValueObj[determiner]}`;
    if (lowerBound && upperBound && upperBound.isBefore(dayjs('2050-01-01'))) {
      determinerValue += ` between ${lowerBound.format('MMM D YYYY')} and ${upperBound.format('MMM D YYYY')}`;
    } else if (lowerBound) {
      determinerValue += ` from ${lowerBound.format('MMM D YYYY')} onward`;
    }

    return determinerValue;
  });

  let startDate;
  let endDate;

  if (dateQualifiers.length > 0) {
    startDate = dayjs.min(dateQualifiers.filter((q) => q.lower_bound).map((q) => dayjs(q.lower_bound).startOf('day'))).toDate();
    endDate = dayjs.max(dateQualifiers.filter((q) => q.upper_bound).map((q) => dayjs(q.upper_bound).endOf('day'))).toDate();
  }

  if (action_name.includes('Custom')) {
    offerDeterminersValues.push(`${action_name} offer`);
  }

  const offerRewardTimelineValue = isPayingRent ? offerRewardChipValue + ' on rent' : offerRewardChipValue;

  const offer = {
    id,
    rawAction: ele,
    offerRewardChipValue,
    offerRewardTimelineValue,
    offerDeterminersValues,
    startDate,
    endDate,
    customSchedulesChipValues,
    offersTargets,
    brTargets
  };

  return offer;
};

const ChipComponent = ({offer}) => {
  return (
    <Stack direction="column" sx={{width: 'fit-content'}}>
      <Stack direction="column" sx={{width: 'fit-content'}}>
        {offer.offerDeterminersValues.map((determiner, index) => (
          <Typography key={index} variant="body1">
            {determiner}
          </Typography>
        ))}
      </Stack>
      <Typography variant="body1" sx={{width: 'fit-content'}}>
        earn <b>{offer.offerRewardChipValue}</b>
        <b>{offer.targetdeterminerValue}</b>
      </Typography>
    </Stack>
  );
};

ChipComponent.propTypes = {
  offer: PropTypes.object
};

export const findActiveOffersChipValue = (actions) => {
  const offerValues = [];

  for (const action of actions) {
    for (const offer of action.offers) {
      const formattedOffer = formatOtherOffers(offer);
      const today = dayjs();
      const isCurrentOffer = today.isBetween(formattedOffer.startDate, formattedOffer.endDate);
      if (isCurrentOffer) {
        const componentValue = <ChipComponent offer={formattedOffer} />;
        offerValues.push(componentValue);
      }
    }
  }
  return offerValues;
};

export const findActiveOffersChipValueFromOffers = (offers) => {
  const offerValues = [];

  for (const offer of offers) {
    const formattedOffer = formatOtherOffers(offer);
    const componentValue = <ChipComponent offer={formattedOffer} />;
    offerValues.push(componentValue);
  }
  return offerValues;
};

export const findMaxHeight = (row) => {
  const {model} = row;
  const {all_actions} = model;
  if (all_actions.length <= 1) return 60;

  const height = all_actions.length * 40;
  return height;
};

export const multiKeySort = (keys) => {
  return (a, b) => {
    for (let key of keys) {
      if (a[key] < b[key]) return -1;
      if (a[key] > b[key]) return 1;
    }
    return 0;
  };
};
