import React, {useMemo} from 'react';
import {useSelector} from 'react-redux';

import {Stack} from '@mui/material';

import AlertsComponent from '../Alerts/AlertsComponent';
import {getCampaigns} from '../../../../../app/store/reducers/entities';

import {getDashboardAccount} from '../../../../../app/store/reducers/dashboard';
import {useGetGlanceDataQuery} from '../../../../../features/coreApi/metricsSlice';
import {getCommunityLoadingStatus} from '../../../../../app/store/reducers/loading';

import CampaignsTable from '../Campaign/CampaignsTable';
import Glance from './Glance/Glance';
import LoadingBox from '../../../@extended/LoadingBox';
import ErrorBox from '../../../@extended/ErrorBox';
import DetailsContent from './DetailsContent';

const PortfolioTab = () => {
  const campaigns = useSelector(getCampaigns);
  const dashboardAccount = useSelector(getDashboardAccount);
  const communitiesLoadingStatus = useSelector(getCommunityLoadingStatus);

  const {data, isError, isSuccess, isLoading, isFetching} = useGetGlanceDataQuery(dashboardAccount.sfid, {
    skip: !dashboardAccount?.sfid
  });

  const glanceComponent = useMemo(() => {
    if (isLoading || isFetching || communitiesLoadingStatus.isLoading) {
      return <LoadingBox />;
    } else if (isError || communitiesLoadingStatus.isError) {
      return <ErrorBox />;
    } else if (isSuccess) {
      return <Glance data={data.data} tabs={data.tabs} />;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isFetching, isError, isSuccess, communitiesLoadingStatus]);

  return (
    <Stack direction={'column'} rowGap={3} sx={{width: '100%'}}>
      <DetailsContent />
      <AlertsComponent />
      {glanceComponent}
      <CampaignsTable data={[...campaigns]} />
    </Stack>
  );
};

export default PortfolioTab;
