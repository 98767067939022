import React, {useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {Box, Fade, Tab, Tabs, useTheme} from '@mui/material';

import {activeTab, getOpenItem, getOpenTab} from '../../../app/store/reducers/menu';

import TodayTab from './Today/TodayTab';
import EsgTab from './Esg/EsgTab';
import CrmTab from './CRM/CrmTab';
import StakeSayTab from './StakeSay/StakeSayTab';
import EngagementTab from './Engagement/EngagementTab';
import PerformanceTab from './Performance/PerformanceTab';
import CustomTabPanel from '../@extended/CustomTabPanel';
import {useLocation} from 'react-router-dom';

// ==============================|| HOME TABS ||============================== //
const tabObjs = {
  today: {name: 'Today', component: <TodayTab />},
  performance: {name: 'Performance', component: <PerformanceTab />},
  engagement: {name: 'Engagement', component: <EngagementTab />},
  crm: {name: 'CRM', component: <CrmTab />},
  stakesay: {name: 'StakeSay', component: <StakeSayTab />},
  esg: {name: 'ESG', component: <EsgTab />}
};

const homeTabsInfo = ['today', 'performance', 'engagement', 'crm', 'stakesay', 'esg'].map((tab) => ({
  id: tab,
  title: tabObjs[tab].name,
  component: tabObjs[tab].component
}));

const campaignTabs = ['today', 'performance', 'crm', 'stakesay'].map((tab) => ({
  id: tab,
  title: tabObjs[tab].name,
  component: tabObjs[tab].component
}));

const HomeTabs = () => {
  const theme = useTheme();
  const location = useLocation();
  const dispatch = useDispatch();
  const openItem = useSelector(getOpenItem);
  const openTab = useSelector(getOpenTab);
  const initialTab = 'today';

  const tabsList = useMemo(() => {
    if (location.pathname.includes('campaign')) {
      return campaignTabs;
    } else {
      return homeTabsInfo;
    }
  }, [location]);

  const initialIndex = tabsList.findIndex((tab) => tab.id === initialTab);
  // Find index of initial tab in homeTabsInfo
  const [value, setValue] = useState(initialIndex);

  useEffect(() => {
    if (value || value === 0) {
      dispatch(activeTab(tabsList[value].id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    const campaignTabsIds = campaignTabs.map((tab) => tab.id);
    if (openItem && openItem == 'campaigns' && !campaignTabsIds.includes(openTab)) {
      setValue(initialIndex);
    } else if (openItem && openItem == 'campaigns' && campaignTabsIds.includes(openTab)) {
      setValue(campaignTabs.findIndex((tab) => tab.id === openTab));
    } else if (!openTab) {
      setValue(initialIndex);
    } else {
      setValue(homeTabsInfo.findIndex((tab) => tab.id === openTab));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openItem]);

  const handleChange = (_event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{width: '100%', height: '100%', bgcolor: 'transparent', borderRadius: 2}}>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="secondary"
        textColor="primary"
        variant="fullWidth"
        aria-label="full width tabs example"
        sx={{
          opacity: 1,
          cursor: 'pointer',
          '.MuiTab-root': {
            bgcolor: theme.palette.grey[300],
            fontWeight: 'fontWeightRegular',
            p: 3,
            border: '1px solid',
            borderColor: 'divider',
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            '&:hover': {
              color: 'text.secondary',
              bgcolor: 'background.paper'
            }
          },
          '.MuiTab-root.Mui-selected': {
            color: 'text.secondary',
            borderBottom: 'none',
            bgcolor: 'background.paper',
            zIndex: 1
          },
          '.MuiTab-root:not(:last-of-type)': {
            marginRight: '4px'
          },
          '.MuiTabs-indicator': {
            display: 'none'
          }
        }}
      >
        {tabsList.map((tab) => (
          <Tab
            key={tab.id}
            label={tab.title}
            disableRipple={true}
            sx={{
              bgcolor: theme.palette.grey[300],
              fontWeight: 'fontWeightRegular',
              p: 3,
              border: '1px solid',
              borderColor: 'divider',
              borderTopLeftRadius: 8,
              borderTopRightRadius: 8,
              ...(value === tab.id && {
                borderBottom: 'none',
                zIndex: 1
              })
            }}
          />
        ))}
      </Tabs>
      {tabsList.map((tab, index) => (
        <Fade in={value === index} key={tab.id} timeout={1000}>
          <div>
            <CustomTabPanel value={value} index={index} dir={theme.direction}>
              {tab.component}
            </CustomTabPanel>
          </div>
        </Fade>
      ))}
    </Box>
  );
};

export default HomeTabs;
