import React from 'react';
import PropTypes from 'prop-types';

import {FormControlLabel, Switch} from '@mui/material';
import {useUpdateFeatureVisibilityMutation} from '../../../features/coreApi/metricsSlice';

const VisibilitySwitch = ({id, accountName, setVisible, isVisible, featureType, modelType}) => {
  const [updateFeatureVisibility] = useUpdateFeatureVisibilityMutation();

  const handleSwicth = (e) => {
    const params = {
      model_type: modelType,
      feature_type: featureType,
      is_visible: e.target.checked,
      sfid: id
    };

    updateFeatureVisibility({...params});
    setVisible(e.target.checked);
  };

  return (
    <FormControlLabel
      control={<Switch color="secondary" checked={isVisible} onChange={handleSwicth} />}
      label={`Display this card to ${accountName}`}
    />
  );
};

VisibilitySwitch.propTypes = {
  id: PropTypes.string,
  accountName: PropTypes.string,
  setVisible: PropTypes.func,
  isVisible: PropTypes.bool,
  featureType: PropTypes.string,
  modelType: PropTypes.string
};

export default VisibilitySwitch;
