import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {Alert, AlertTitle, Snackbar, styled} from '@mui/material';

import {getAlertsError, getAlertsSuccess, resetAlerts} from '../../../app/store/reducers/alerts';

export const StyledSuccessAlert = styled(Alert)({
  backgroundColor: 'rgba(195, 231, 195, 1)',
  borderColor: 'rgba(17, 161, 14, 0.5)',
  color: 'black',
  fontSize: '0.8rem',
  padding: '8px 40px',
  '.MuiSvgIcon-root': {
    color: 'black'
  }
});

export const StyledErrorAlert = styled(Alert)({
  background: 'rgba(241, 66, 66, 0.9)',
  borderColor: 'rgba(241, 66, 66, 0.9)',
  color: 'white',
  fontSize: '0.8rem',
  padding: '8px 40px',
  '.MuiSvgIcon-root': {
    color: 'white'
  }
});

const AlertsDialog = () => {
  const dispatch = useDispatch();
  const success = useSelector(getAlertsSuccess);
  const error = useSelector(getAlertsError);

  const [isAlertOpen, setAlertOpen] = useState(false);
  const alertComponentRef = useRef(null);

  useEffect(() => {
    if (error || success) {
      setAlertOpen(true);
      if (success) {
        alertComponentRef.current = (
          <StyledSuccessAlert>
            <AlertTitle sx={{fontSize: '0.9rem'}}>Success</AlertTitle>
            {success}
          </StyledSuccessAlert>
        );
      } else if (error) {
        alertComponentRef.current = (
          <StyledErrorAlert>
            <AlertTitle sx={{fontSize: '0.9rem'}}>Error</AlertTitle>
            {error}
          </StyledErrorAlert>
        );
      }
    }
  }, [error, success]);

  useEffect(() => {
    if (isAlertOpen) {
      const timer = setTimeout(() => {
        dispatch(resetAlerts());
        alertComponentRef.current = null;
        setAlertOpen(false);
      }, 4000);

      // Clean up the timer when component unmounts or alert closes earlier
      return () => clearTimeout(timer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAlertOpen]);

  return (
    <Snackbar open={isAlertOpen} anchorOrigin={{horizontal: 'center', vertical: 'bottom'}}>
      {alertComponentRef.current}
    </Snackbar>
  );
};

export default AlertsDialog;
