import React from 'react';
import PropTypes from 'prop-types';

import {Stack, Typography} from '@mui/material';

import StakerInsightsBox from './StakerInsightsBox';

const StakerInsights = ({data}) => {
  const title = data.title ?? '';
  const description = data.description ?? '';
  const rows = data.rows ?? [];

  const rowBlocks = rows.map((e, index) => {
    return <StakerInsightsBox key={`${e.title}${index}`} data={e} />;
  });

  return (
    <Stack direction="column" spacing={1}>
      <Typography variant="h5" fontWeight={'fontWeightMedium'}>
        {title}
      </Typography>
      <Typography variant="subtitle1" fontWeight={'fontWeightRegular'} color={'text.subtitle'}>
        {description}
      </Typography>
      <Stack direction="row" sx={{width: '100%', paddingTop: 1}} alignItems="center" spacing={1}>
        {rowBlocks}
      </Stack>
    </Stack>
  );
};

StakerInsights.propTypes = {
  data: PropTypes.object
};

export default StakerInsights;
