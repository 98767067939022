import React, {memo, useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import * as Yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {FormProvider, useForm, useFormContext} from 'react-hook-form';

import {Button, Chip, Dialog, DialogContent, Stack, Typography, useTheme} from '@mui/material';
import {LoadingButton} from '@mui/lab';

import {getTeamMembers} from '../../../../app/store/reducers/entities';
import {getIsStakeAdmin, getTeamMemberRole} from '../../../../app/store/reducers/session';
import {DialogTitleWithCloseIcon} from '../../@extended/CustomDialogs';
import {StyledMarginWidthDividerSm} from '../../@extended/Divider';
import {ChipFieldArray, FormInput, SimpleFormSelectWithLabel} from '../../@extended/Forms';
import {useDeleteTeamMemberByIdMutation, useUpdateTeamMemberInfoMutation} from '../../../../features/loyaltyCloudApi/apiSlice';
import validateEmail from '../../../utils/emailValidation';
import {useLazyGetCommunitiesByAccountSfidQuery} from '../../../../features/coreApi/communitiesSlice';
import {getDashboardAccount} from '../../../../app/store/reducers/dashboard';

const teamMemberSchema = Yup.object().shape({
  first_name: Yup.string().required('First Name is required').min(1),
  last_name: Yup.string().required('Last Name is required').min(1),
  email: Yup.string()
    .required('Email is required')
    .test('is-valid-email', 'Email is invalid', (value) => validateEmail(value?.replace(/\s/g, ''))),
  role: Yup.string().required('Role is required').min(1),
  property_permissions: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.string().required(),
        name: Yup.string().required()
      })
    )
    .min(1, 'At least one property permission is required')
    .required('Property Permissions is required')
});

const roleOptions = [
  {id: 'Administrator', name: 'Administrator'},
  {id: 'Property Manager', name: 'Property Manager'},
  {id: 'Leasing Agent', name: 'Leasing Agent'}
];

// Helper components
const InfoSection = ({items}) => {
  const theme = useTheme();
  return (
    <Stack direction="row" sx={{border: `1px solid ${theme.palette.grey[300]}`, borderRadius: '8px', p: 2}}>
      {items.map(({label, value}) => (
        <Stack key={label} direction="column" divider={<StyledMarginWidthDividerSm />} sx={{width: '25%'}} rowGap={2}>
          <Typography variant="h6" color="text.subtitle">
            {label}
          </Typography>
          <Typography variant="h6">{value}</Typography>
        </Stack>
      ))}
    </Stack>
  );
};

const PropertyPermissionsSection = memo(({permissions, editable = false, availableProperties}) => {
  const theme = useTheme();
  return (
    <Stack
      direction="column"
      sx={{border: `1px solid ${theme.palette.grey[300]}`, borderRadius: '8px', p: 2}}
      divider={<StyledMarginWidthDividerSm />}
      rowGap={2}
    >
      <Typography variant="h6" color="text.subtitle">
        Property Permissions
      </Typography>
      {editable ? (
        <ChipFieldArray name="property_permissions" availableProperties={availableProperties} />
      ) : (
        <Stack direction="row" sx={{flexWrap: 'wrap'}} rowGap={1} columnGap={1}>
          {permissions.map((c) => (
            <Chip color="primary" variant="outlined" key={c.name.split(' ').join('-')} label={c.name} />
          ))}
        </Stack>
      )}
    </Stack>
  );
});

const FormSection = () => {
  const theme = useTheme();
  return (
    <Stack direction="row" sx={{border: `1px solid ${theme.palette.grey[300]}`, borderRadius: '8px', p: 2}} columnGap={2}>
      <FormInput name="first_name" label="First Name" />
      <FormInput name="last_name" label="Last Name" />
      <FormInput name="email" label="Email" />
      <SimpleFormSelectWithLabel name="role" options={[{id: 'default', name: 'Select a role'}, ...roleOptions]} />
    </Stack>
  );
};

const SaveButton = ({isLoading}) => {
  const {
    formState: {isValid, isDirty}
  } = useFormContext();
  return (
    <Stack direction="row" justifyContent="flex-end">
      <LoadingButton loading={isLoading} type="submit" variant="contained" color="secondary" disabled={!isValid || !isDirty}>
        Save changes
      </LoadingButton>
    </Stack>
  );
};

const DeleteButton = ({setDelete}) => (
  <Button variant="contained" color="secondary" size="small" onClick={() => setDelete(true)} sx={{p: '0px 12px'}}>
    Delete
  </Button>
);

const TeamMemberPage = ({sfid, handleClose, isOpen}) => {
  const teamMembers = useSelector(getTeamMembers);
  const isStakeAdmin = useSelector(getIsStakeAdmin);
  const userRole = useSelector(getTeamMemberRole);
  const dashboardAccount = useSelector(getDashboardAccount);

  const [updateTeamMember, {isSuccess: isUpSuccess, isLoading: isUpLoading, isError: isUpError}] = useUpdateTeamMemberInfoMutation();
  const [deleteTeamMember, {data: deletedInfo, isSuccess: isDelSuccess, isLoading: isDelLoading, isError: isDelError}] =
    useDeleteTeamMemberByIdMutation();

  const [fetchCommunities, {data: communities, isSuccess: isComSuccess, isLoading: isComLoading, isFetching: isComFetching}] =
    useLazyGetCommunitiesByAccountSfidQuery();

  const [isEdit, setEdit] = useState(false);
  const [isDelete, setDelete] = useState(false);

  useEffect(() => {
    if (dashboardAccount.sfid) {
      fetchCommunities(dashboardAccount.sfid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardAccount]);

  const teamMember = useMemo(() => {
    if (teamMembers && teamMembers.length > 0) {
      return teamMembers.find((r) => r.id === sfid);
    }
  }, [teamMembers, sfid]);

  const isAdministrator = useMemo(() => {
    return (
      teamMember?.permissions === 'Administrator' ||
      teamMember?.permissions.includes('all') ||
      teamMember?.permissions.includes('blank') ||
      teamMember?.permissions.length === 0
    );
  }, [teamMember]);

  const propertyPermissions = useMemo(() => {
    if (teamMember && isComSuccess && communities.length > 0) {
      const permissions = teamMember.permissions.filter((p) => p !== 'all' && p !== 'blank') || [];
      if (!isAdministrator && permissions.length > 0) {
        return communities.filter((c) => permissions.includes(c.sfid)).map((c) => ({id: c.sfid, name: c.name}));
      } else {
        return communities.map((c) => ({id: c.sfid, name: c.name}));
      }
    }
    return [];
  }, [isAdministrator, teamMember, communities, isComSuccess, isComFetching, isComLoading]);

  const defaultValues = useMemo(() => {
    return {
      first_name: '',
      last_name: '',
      email: '',
      role: '',
      property_permissions: []
    };
  }, []);

  const methods = useForm({
    mode: 'onBlur',
    defaultValues,
    resolver: yupResolver(teamMemberSchema)
  });

  const {handleSubmit, reset} = methods;

  useEffect(() => {
    if (teamMember && propertyPermissions) {
      reset({
        first_name: teamMember.first_name || '',
        last_name: teamMember.last_name || '',
        email: teamMember.email || '',
        role: teamMember.role || '',
        property_permissions: propertyPermissions
      });
    }
  }, [teamMember, propertyPermissions, reset]);

  const onSubmit = (data) => {
    let dataForm = new FormData();
    const permissions = data.property_permissions.map((p) => p.id).join(',');

    dataForm.append('first_name', data.first_name);
    dataForm.append('last_name', data.last_name);
    dataForm.append('email', data.email);
    dataForm.append('role', data.role);
    dataForm.append('property_permissions', permissions);

    updateTeamMember({id: teamMember.id, data: dataForm});
  };

  useEffect(() => {
    if (isUpSuccess && !isUpError && !isUpLoading) {
      handleClose();
    }
  }, [isUpSuccess, isUpError, isUpLoading, handleClose]);

  useEffect(() => {
    if (isDelSuccess && !isDelError && !isDelLoading) {
      handleClose();
    }
  }, [isDelSuccess, isDelError, isDelLoading]);

  const InfoSectionMemo = useMemo(
    () => (
      <InfoSection
        items={[
          {label: 'First Name', value: teamMember?.first_name},
          {label: 'Last Name', value: teamMember?.last_name},
          {label: 'Email', value: teamMember?.email},
          {label: 'Role', value: teamMember?.role}
        ]}
      />
    ),
    [teamMember]
  );

  const PropertyPermissionsSectionMemo = useMemo(() => {
    if (isComSuccess && propertyPermissions.length > 0) {
      return (
        <PropertyPermissionsSection
          permissions={propertyPermissions}
          editable={userRole == 'Administrator' || isStakeAdmin}
          availableProperties={communities.map((c) => ({id: c.sfid, name: c.name}))}
        />
      );
    }
  }, [propertyPermissions, userRole, isStakeAdmin, isComFetching, isComLoading, isComSuccess]);

  const DialogHeaderMemo = useMemo(() => {
    const name = teamMember ? `${teamMember.first_name} ${teamMember.last_name}` : '';
    return (
      <Stack direction="row" columnGap={3}>
        {name}
        {(userRole == 'Administrator' || isStakeAdmin) && !isDelete && <DeleteButton setDelete={setDelete} />}
      </Stack>
    );
  }, [teamMember, userRole, isStakeAdmin, isDelete]);

  if (!teamMember) return null;

  return (
    <Dialog open={isOpen} onClose={handleClose} fullWidth maxWidth="xl">
      <DialogTitleWithCloseIcon onClose={handleClose} sx={{pt: 3}}>
        {DialogHeaderMemo}
      </DialogTitleWithCloseIcon>
      <DialogContent dividers>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack direction="column" rowGap={2} sx={{width: '100%'}}>
              {userRole == 'Administrator' || isStakeAdmin ? (
                isDelete ? (
                  <Stack direction="column" justifyContent={'space-between'} sx={{height: '80px'}}>
                    <Typography variant="h6" color="text.subtitle">
                      Are you sure you want to delete this team member?
                    </Typography>
                    <Stack direction="row" columnGap={1}>
                      <Button variant="outlined" color="error" onClick={() => setDelete(false)}>
                        Cancel
                      </Button>
                      <LoadingButton
                        loading={isDelLoading}
                        type="submit"
                        variant="contained"
                        color="secondary"
                        onClick={() => deleteTeamMember(teamMember.id)}
                      >
                        Delete
                      </LoadingButton>
                    </Stack>
                  </Stack>
                ) : (
                  <>
                    <FormSection />
                    {PropertyPermissionsSectionMemo}
                    <SaveButton isLoading={isUpLoading} />
                  </>
                )
              ) : (
                <>
                  {InfoSectionMemo}
                  {PropertyPermissionsSectionMemo}
                </>
              )}
            </Stack>
          </form>
        </FormProvider>
      </DialogContent>
    </Dialog>
  );
};

TeamMemberPage.propTypes = {
  sfid: PropTypes.string,
  handleClose: PropTypes.func,
  isOpen: PropTypes.bool
};

export default TeamMemberPage;
