import React from 'react';
import {Paper} from '@mui/material';
import TeamMembersTable from './TeamMembersTable';

const TeamMembers = () => {
  return (
    <Paper sx={{width: '100%', height: 'fit-content', p: 3}}>
      <TeamMembersTable />
    </Paper>
  );
};

export default TeamMembers;
